import * as React from 'react';
import './mike-icon.css';

interface IProps {
  type?: string;
  name: string;
  stroked?: boolean;
}

/**
 * @name MikeToolbarIcon
 * @summary Icons to be used specifically in a toolbar.
 */
export class MikeToolbarIcon extends React.Component<IProps> {
  public static defaultProps = {
    type: 'default',
    stroked: false,
  };

  public render() {
    const { name } = this.props;

    return (
      <svg
        width={50}
        height={60}
        viewBox={'0 0 50 60'}
        fill="none"
        className={
          'icon icon--' + this.props.type + ' icon--' + (this.props.stroked ? 'stroke' : 'fill')
        }
        xmlns="http://www.w3.org/2000/svg"
      >
        {getPath(name)}
      </svg>
    );
  }
}

const getPath = (name: string) => {
  switch (name) {
    case 'search':
      return (
        <path d="M28.5 30H27.71L27.43 29.73C28.41 28.59 29 27.11 29 25.5C29 21.91 26.09 19 22.5 19C18.91 19 16 21.91 16 25.5C16 29.09 18.91 32 22.5 32C24.11 32 25.59 31.41 26.73 30.43L27 30.71V31.5L32 36.49L33.49 35L28.5 30ZM22.5 30C20.01 30 18 27.99 18 25.5C18 23.01 20.01 21 22.5 21C24.99 21 27 23.01 27 25.5C27 27.99 24.99 30 22.5 30Z" />
      );
    case 'files':
      return (
        <path d="M22.17 22L24.17 24H33V34H17V22H22.17ZM23 20H17C15.9 20 15.01 20.9 15.01 22L15 34C15 35.1 15.9 36 17 36H33C34.1 36 35 35.1 35 34V24C35 22.9 34.1 22 33 22H25L23 20Z" />
      );
    case 'notification':
      return (
        <path d="M25 38C26.1 38 27 37.1 27 36H23C23 37.1 23.9 38 25 38ZM31 32V27C31 23.93 29.37 21.36 26.5 20.68V20C26.5 19.17 25.83 18.5 25 18.5C24.17 18.5 23.5 19.17 23.5 20V20.68C20.64 21.36 19 23.92 19 27V32L17 34V35H33V34L31 32ZM29 33H21V27C21 24.52 22.51 22.5 25 22.5C27.49 22.5 29 24.52 29 27V33Z" />
      );
    case 'cart':
      return (
        <g>
          <path d="M28.55 31C29.3 31 29.96 30.59 30.3 29.97L33.88 23.48C34.25 22.82 33.77 22 33.01 22H18.21L17.27 20H14V22H16L19.6 29.59L18.25 32.03C17.52 33.37 18.48 35 20 35H32V33H20L21.1 31H28.55ZM19.16 24H31.31L28.55 29H21.53L19.16 24Z" />
          <path d="M20 36C18.9 36 18.01 36.9 18.01 38C18.01 39.1 18.9 40 20 40C21.1 40 22 39.1 22 38C22 36.9 21.1 36 20 36Z" />
          <path d="M30 36C28.9 36 28.01 36.9 28.01 38C28.01 39.1 28.9 40 30 40C31.1 40 32 39.1 32 38C32 36.9 31.1 36 30 36Z" />
        </g>
      );
    default:
      return <path />;
  }
};

export default MikeToolbarIcon;
