import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Theme,
  WithStyles,
  FormControl,
  withStyles,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import { Formik, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Warning } from '@material-ui/icons';
import MikeButtonSpinner from '../mike-button-spinner/MikeButtonSpinner';
import { IPostProject } from './model';

const styles = (theme: Theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
    height: 20,
  },
});

interface IProps extends WithStyles<typeof styles> {
  onAdd: (project: IPostProject) => void;
}

interface IState {
  name: string;
  description: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

class ProjectCreateForm extends React.Component<IProps, IState> {
  constructor(props: Readonly<IProps>) {
    super(props);
    this.state = { name: '', description: '' };
  }

  protected handleCreate = (values: any) => {
    const { onAdd } = this.props;
    const project = {} as IPostProject;
    project.name = values.name;
    project.description = values.description;
    onAdd(project);
  };

  public render() {
    const { classes } = this.props;
    const values = {
      name: this.state.name || '',
      description: this.state.description || '',
    };

    return (
      <Formik
        initialValues={values}
        onSubmit={this.handleCreate}
        validationSchema={validationSchema}
        render={(props) => this.renderForm(props, classes)}
      />
    );
  }

  protected renderForm = (props: FormikProps<IState>, classes: any) => {
    const {
      values: { name, description },
      touched,
      errors,
      isSubmitting,
      handleSubmit,
      handleChange,
      setFieldTouched,
    } = props;
    const change = (param: 'name' | 'description', e: React.ChangeEvent<{}>) => {
      e.persist();
      this.setState(({
        [param]: (e.target as HTMLInputElement).value,
      } as unknown) as IState);
      handleChange(e);
      setFieldTouched(param, true, false);
    };

    return (
      <FormControl fullWidth>
        <TextField
          id="name"
          name="name"
          label="Name"
          value={name}
          onChange={change.bind(null, 'name')}
          margin="normal"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {touched.name && Boolean(errors.name) ? <Warning color="error" /> : <i />}
              </InputAdornment>
            ),
          }}
        />
        <ErrorMessage component="div" name="name" className={classes.error} />
        <TextField
          id="description"
          name="description"
          label="Description"
          value={description}
          onChange={change.bind(null, 'description')}
          margin="normal"
          fullWidth
        />
        <Grid className={classes.button} container direction="column" alignItems="flex-end">
          <MikeButtonSpinner type="default" active={isSubmitting} onClicked={handleSubmit}>
            Create
          </MikeButtonSpinner>
        </Grid>
      </FormControl>
    );
  };
}

export default withStyles(styles)(ProjectCreateForm);
