import React from 'react';
import Tab, { TabProps as MuiTabProps } from '@material-ui/core/Tab';
import { makeStyles, createStyles } from '@material-ui/core';

const useTabStyles = makeStyles((_theme) =>
  createStyles({
    labelIcon: {
      minHeight: 56,
      minWidth: 90,
      paddingTop: 0,
      paddingBottom: 4,
      lineHeight: '1rem',
    },
    wrapper: {
      '&&& > *:first-child': {
        minHeight: 40, // needed to handle both mui icons and MikeSvgIcons
        fill: 'currentColor', // Need to set fill in order to style MikeSvgIcons. todo hevo some icons work with stroke insted. Need to find a way to handle that
        margin: 0,
      },
    },
  })
);

/**
 * @name MikeTab
 * @summary A convenience wrapper for the MuiTab to allow styling according to the DHI Guideline. Needed for tabs with icons
 * @param props
 */
export const MikeTab = (props: MuiTabProps) => {
  const classes = useTabStyles(props);

  return <Tab classes={classes} {...props} />;
};

MikeTab.muiName = 'MuiTab';
export default MikeTab;
