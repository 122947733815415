import React from 'react';
import { TextField, InputAdornment, makeStyles } from '@material-ui/core';

import { ReactComponent as Calendar } from 'icons/Calendar';

interface IProps {
  onClick?: (e: any) => void;
  id?: string;
  value?: string;
  placeholder?: string;
  onChange?: () => void;
  hideIcon?: boolean;
  innerRef?: any;
}

const useStyles = makeStyles({
  textField: {
    width: '100%',
  },
});

const MikeDatePickerInput: React.FC<IProps> = ({
  onChange,
  placeholder,
  value,
  id,
  onClick,
  hideIcon,
  innerRef,
}) => {
  const classes = useStyles();

  return (
    <TextField
      ref={innerRef}
      className={classes.textField}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      id={id}
      onClick={onClick}
      InputProps={
        !hideIcon
          ? {
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <Calendar />
                </InputAdornment>
              ),
            }
          : {}
      }
    />
  );
};

export default MikeDatePickerInput;
