import React from 'react';

import './mike-button-spinner.css';
import MikeButton from '../mike-button/MikeButton';

interface IProps {
  type: 'default' | 'ultimate';
  disabled?: boolean;
  active?: boolean;

  // FIXME(mapu): should be onClick to respect the MUI/React conventions
  onClicked: () => void;
}

/**
 * @name MikeButtonSpinner
 * @summary A button that can have a spinner state.
 * @deprecated in favour of @see MikeButton
 */
export class MikeButtonSpinner extends React.Component<IProps> {
  public render() {
    const { active, disabled, type, onClicked, children } = this.props;

    return (
      <MikeButton
        onClick={onClicked}
        buttontype={type === 'default' ? 'primary' : 'ultimate'}
        disabled={disabled}
        active={active}
      >
        {children}
      </MikeButton>
    );
  }
}

export default MikeButtonSpinner;
