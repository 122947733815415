export { default, SPINNER_TIMEOUT, PROGRESS_ITEM_TIMEOUT } from './MikeProgressSpinner';

export { IProgressItem } from './IProgressItem';

export const getCombinedProgress = (statusProgress: number, browserStep = 33) => {
  // "Not browser progress":
  const NBP = 100 - browserStep;
  // Relative "not browser progress":
  const rNIP = NBP / 100;
  // Resulting full progress:
  const result = browserStep + statusProgress * rNIP;
  return result;
};
