import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { getYear, getDaysInMonth } from 'date-fns';
import { range } from 'lodash';
import { ReactComponent as DownTriangle } from 'icons/DownTriangle';
import { selectStyle } from './MikeDatePickerStyles';
import { mikePalette } from '../mike-shared-styles/mikeSharedTheme';

interface IProps {
  onChangeYear: (e) => void;
  onDateChange: (date: Date) => void;
  date: any;
  startYear: number;
  endYear: number;
}

const useStyles = makeStyles({
  menuList: {
    maxHeight: '280px',
    background: mikePalette.mediumGrey.light,
    marginTop: '5px',
    overflowY: 'auto',
    width: '80px',
  },
});

const MikeDatePickerSelectYear: React.FC<IProps> = ({
  onChangeYear,
  onDateChange,
  date,
  startYear,
  endYear,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const years = range(startYear, endYear);
  const index = years.indexOf(date.getFullYear());
  const [selectedIndex, setSelectedIndex] = React.useState(index);

  const handleToggle = () => {
    setOpen((wasOpen) => !wasOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleSelect = (e, currentindex: number) => {
    setSelectedIndex(currentindex);
    setOpen(!setOpen);
    onChangeYear(e);
    checkDayOfMonth(years[currentindex]);
  };

  const checkDayOfMonth = (year: number) => {
    let day = date.getDate();
    date.setDate(1);
    date.setYear(year);
    const daysInMonth = getDaysInMonth(date);
    if (day > daysInMonth) {
      day = daysInMonth;
    }
    date.setDate(day);
    onDateChange(date);
  };

  return (
    <span style={{ float: 'right', marginRight: '10px' }}>
      <Button
        className={selectStyle}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{
          justifyContent: 'start',
          width: '85px',
        }}
        endIcon={<DownTriangle style={{ position: 'absolute', top: '-4px', right: '-2px' }} />}
      >
        {getYear(date)}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  className={classes.menuList}
                >
                  {years.map((option, yearindex) => (
                    <MenuItem
                      key={option}
                      value={option}
                      selected={yearindex === selectedIndex}
                      onClick={(event) => handleSelect(event, yearindex)}
                    >
                      <span style={{ width: '100%', height: '100%', textAlign: 'center' }}>
                        {option}
                      </span>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </span>
  );
};

export default MikeDatePickerSelectYear;
