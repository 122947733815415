import React from 'react';

import './MikeNavWarnBanner.css';

/**
 * @name MikeNavWarnBanner
 * @summary Warns users if their browser is not supported.
 */
export class MikeNavWarnBanner extends React.Component<{ showAlways: boolean }, {}> {
  public state = { visible: true };

  private handleBannerClick = () => {
    localStorage.setItem('userClosedBroswerBanner', 'true');
    this.setState({ visible: false });
  };

  public render() {
    const showAlways = this.props.showAlways;
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isOpera = navigator.userAgent.toLowerCase().indexOf('op') > -1;
    const hasBeenClosed = localStorage.getItem('userClosedBroswerBanner');
    const showBanner = showAlways || (isChrome !== true || isOpera);

    return showBanner && (hasBeenClosed === 'false' || hasBeenClosed === null) ? (
      <div className="c-BroswerBanner" onClick={this.handleBannerClick}>
        We cannot guarantee the user experience with your current browser. Please consider using
        Chrome.
      </div>
    ) : null;
  }
}

export default MikeNavWarnBanner;
