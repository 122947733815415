export const accessLevelMap = { shared: 'Shared', private: 'Private' };

export const columns = [
  {
    id: 'name',
    label: 'Project name',
    sortable: true,
    render: {
      keepValue: true,
      icons: [{ value: 'all', icon: 'Project' }],
    },
    filtrable: true,
  },
  { id: 'description', label: 'Description', sortable: true, filtrable: true },
  { id: 'canRead', label: 'Can Read' },
  { id: 'canEdit', label: 'Can Write' },
  {
    id: 'createdAt',
    label: 'Created',
    sortable: true,
    type: 'date',
    showTime: true,
  },
  {
    id: 'updatedAt',
    label: 'Edited',
    sortable: true,
    type: 'date',
    showTime: 'true',
  },
];

export const columnsSmall = [{ id: 'name', label: 'Name', sortable: false }];

export const config = {
  filtered: true,
  pagination: true,
  searchPlaceholder: 'Search',
  addButton: true,
  defaultSort: { field: 'updatedAt', type: 'date', order: 'desc' },
};
