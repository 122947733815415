import React from 'react';

import {
  withStyles,
  WithStyles,
  Grid,
  IconButton,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Theme,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import './mike-sliding-panel.css';

// TODO(mapu): rewrite using a MUI Drawer

interface IProps extends WithStyles<typeof styles> {
  titleArea: any;
  contentArea: any;
  actionsArea: any;
  isOpen: boolean;
  position: 'left' | 'right';
  noGrayOverlay: boolean;
  onClose: () => void;
}

const styles = (theme: Theme) => ({
  panelTitle: {
    padding: theme.spacing(1),
  },
  panelActions: {
    padding: theme.spacing(2),
  },
  titleBar: {
    display: 'flex',
  },
  titleHeadings: {
    padding: theme.spacing(2, 2, 0, 2),
    flex: '1',
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

/**
 * @name MikeSlidingPanel
 * @summary A modal, meant to be temporary shown during creation of data (of any kind). Similar to the MikeSidePanel. However, this component should not be permanently shown.
 */
export class MikeSlidingPanel extends React.Component<IProps> {
  public render() {
    const { classes, titleArea, contentArea, actionsArea } = this.props;
    const postfix = this.GetPositionPostfix(this.props.position);
    const overlay = this.props.noGrayOverlay ? ' sliding-panel--noOverlay' : '';

    return (
      <div className={'fader fade--' + (this.props.isOpen ? 'in' : 'out')}>
        <div className={'sliding-panel  sliding-panel--' + postfix + overlay}>
          <div className={'panel-content  panel-content--' + postfix}>
            {this.props.isOpen && (
              <MuiDialogTitle className={classes.panelTitle}>
                <Grid
                  container
                  key="titlebar"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  className={classes.titleBar}
                >
                  <Grid key="headings" className={classes.titleHeadings}>
                    {titleArea}
                  </Grid>
                  <Grid key="button" className={classes.closeButton}>
                    <IconButton aria-label="Close" onClick={this.props.onClose}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </MuiDialogTitle>
            )}
            {this.props.isOpen && <MuiDialogContent>{contentArea}</MuiDialogContent>}
            {this.props.isOpen && (
              <MuiDialogActions className={classes.panelActions}>{actionsArea}</MuiDialogActions>
            )}
          </div>
        </div>
      </div>
    );
  }

  private GetPositionPostfix(pos: string) {
    const safePos = pos.toLowerCase();
    switch (safePos) {
      case 'left':
        return safePos;
      default:
        return 'right';
    }
  }
}

export default withStyles(styles)(MikeSlidingPanel);
