import { format } from 'date-fns';

/*  Use these constants elsewhere to acheive 
    same date format across all MIKE applicaitons: 
*/
export const MIKE_DATE_FORMAT = 'dd MMM yyyy';
export const MIKE_DATETIME_FORMAT = 'dd MMM yyyy h:mm:ss a';
export const MIKE_TIME_FORMAT = 'h:mm:ss a';

/*  Use this method to acheive 
    same date format across all MIKE applicaitons: 
*/
export function formatDate(
  inputDate: string | Date | number | null | undefined,
  showTime?: boolean,
  showOnlyTime?: boolean
): string {
  if (!inputDate) {
    return '';
  }
  try {
    const d = new Date(inputDate);
    const form = showOnlyTime
      ? MIKE_TIME_FORMAT
      : showTime
      ? MIKE_DATETIME_FORMAT
      : MIKE_DATE_FORMAT;
    const result = format(d, form);
    return result;
  } catch {
    return String(inputDate);
  }
}
