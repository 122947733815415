import * as React from 'react';

import { withStyles, Snackbar, Theme, WithStyles } from '@material-ui/core';

import Message, { MessaType } from './Message';

const styles = (theme: Theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
});

export interface IMessage {
  message: string;
  type: MessaType;
  show: boolean;
}

interface IProps extends WithStyles<typeof styles> {
  lastMessage: IMessage;
  onHandleCloseCallBack: () => void;
}

// TODO(mapu): consider using notistack component https://github.com/iamhosseindhv/notistack

/**
 * @name MikeMessageBar
 * @summary Show temporary messages of different types to users.
 *
 * @deprecated uses MikeSnackbar instead.
 */
export class MikeMessageBar extends React.Component<IProps> {
  public static defaultProps: Partial<IProps> = {
    onHandleCloseCallBack: () => null,
  };

  public render(): JSX.Element {
    const { lastMessage, classes, onHandleCloseCallBack } = this.props;
    const open = this.props.lastMessage ? this.props.lastMessage.show : false;

    return (
      <div>
        {
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={open}
            autoHideDuration={5000}
            onClose={onHandleCloseCallBack}
          >
            <Message
              onClose={onHandleCloseCallBack}
              variant={lastMessage.type}
              className={classes.margin}
              message={lastMessage.message}
            />
          </Snackbar>
        }
      </div>
    );
  }
}

export default withStyles(styles)(MikeMessageBar);
