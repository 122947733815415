import React from 'react';

import { Grid, Grow, Typography, makeStyles, createStyles } from '@material-ui/core';

import MikeLogin, { IMikeLoginApi } from '../mike-login';
import IConfig from '../mike-login/IConfig';

import './mike-landing-page.css';

import { ReactComponent as Logo } from 'icons/LandingPage';
import { ReactComponent as DefaultAppLogo } from 'icons/DhiLogoFilled';

const splash = require('../media/splashscreen.jpg');

interface IProps {
  config: IConfig;
  onLoginClick: (loggedIn: boolean, data: any) => void;
  onLoginError?: (errors: string | Array<string>) => boolean;
  header: string;
  buttonText: string;
  linkText: string;
  linkUrl: string;
  linkLabel: string;
  loginBackendUrl: string;
  appLogo?: any;
  loginApiRef?: React.MutableRefObject<IMikeLoginApi | undefined>;
}

const useStyles = makeStyles(() => {
  return createStyles({
    wrapper: {
      padding: '50px 40px 25px 40px',
    },
    header: {
      display: 'inline-block',
      verticalAlign: 'top',
      margin: '0 0 35px 15px',
    },
    notes: {
      marginTop: 20,
      display: 'inline-block',
    },
  });
});

/**
 * @name MikeLandingPage
 * @summary A simple landing page template.
 */
export const MikeLandingPage = (props: IProps) => {
  const background = {
    backgroundImage: `url(${splash})`,
  };

  const classes = useStyles();

  return (
    <div className="LandingPage" style={background}>
      <div className="LandingPage-logo">
        <Logo />
      </div>

      <div className="LandingPage-content">
        <Grow in={true}>
          <div className={classes.wrapper}>
            <Grid container justifyContent="center" alignItems="center" spacing={0}>
              <Grid item={true} xs={12}>
                {props.appLogo ? (
                  <img src={props.appLogo} height="56" alt="logo" />
                ) : (
                  <DefaultAppLogo height="56" />
                )}
                <Typography
                  color="primary"
                  gutterBottom={true}
                  variant="h1"
                  className={classes.header}
                >
                  {props.header}
                </Typography>
              </Grid>

              <Grid item container justifyContent="center" xs={12}>
                <MikeLogin
                  apiRef={props.loginApiRef}
                  onLoggedInChanged={props.onLoginClick}
                  // tslint:disable-next-line:jsx-no-lambda
                  onLoginError={props.onLoginError ? props.onLoginError : () => true}
                  config={props.config}
                  type="form"
                  logInLabel={props.buttonText}
                  loginBackendUrl={props.loginBackendUrl}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="caption" className={classes.notes}>
                  <label>{props.linkLabel}</label>
                  <a href={props.linkUrl}>{props.linkText}</a>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grow>
      </div>
    </div>
  );
};

export default MikeLandingPage;
