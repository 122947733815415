import React, { useCallback } from 'react';
import MikeSlidingPanel from '../mike-sliding-panel/MikeSlidingPanel';

import ProjectCreateForm from './ProjectCreateForm';
import { IGetProject, IProjectAction, IPostProject } from './model';
import MikeTable from '../mike-table/MikeTable';
import { Link } from '@material-ui/core';
import { ReactComponent as Projects } from 'icons/Projects';

import { config } from './config';

interface IProps {
  projects: Array<IGetProject>;
  loading: boolean;
  onSelect: (project: IGetProject) => void;
  onAdd: (project: IPostProject) => void;
  projectAction: IProjectAction;
  dialogCallback: (action: IProjectAction) => void;
  tableTtile?: string;
  addProjectDialogTitle?: string;
  searchPlaceHolder?: string;
  tooltipAddButton?: string;
  maxTableHeight?: string;
  addButtonText?: string;
  actions: any;
}

const columns = [
  {
    id: 'name',
    type: 'link',
    label: 'Project name',
    sortable: true,
    // eslint-disable-next-line react/display-name
    render: (_row, value) => {
      return (
        <>
          <Projects />
          <Link rel="noopener" style={{ cursor: 'pointer', marginTop: '12px' }}>
            {value}
          </Link>
        </>
      );
    },
    filtrable: true,
  },
  { id: 'description', label: 'Description', sortable: true, filtrable: true },
  { id: 'accessRights', label: 'Access rights' },
  {
    id: 'createdAt',
    label: 'Created',
    sortable: true,
    type: 'date',
    showTime: true,
  },
  {
    id: 'updatedAt',
    label: 'Edited',
    sortable: true,
    type: 'date',
    showTime: 'true',
  },
];

/**
 * @name MikeProjectSelector
 * @summary A selector for Mike projects, meant to be used across all applications.
 */
export const MikeProjectSelector: React.FC<IProps> = (props) => {
  const {
    loading,
    projects,
    onAdd,
    onSelect,
    dialogCallback,
    addProjectDialogTitle,
    actions,
    projectAction,
  } = props;

  const handleOnClose = useCallback(() => {
    dialogCallback({ active: false, type: '' });
  }, []);

  const handleOnAdd = useCallback(() => {
    dialogCallback({ active: true, type: 'create' });
  }, []);

  return (
    <>
      <div style={{ height: '40px', width: '100%' }} />
      <MikeSlidingPanel
        isOpen={projectAction && projectAction.active}
        onClose={handleOnClose}
        titleArea={addProjectDialogTitle ? addProjectDialogTitle : 'Create a new project'}
        position="right"
        contentArea={<ProjectCreateForm onAdd={onAdd} />}
        actionsArea={null}
        noGrayOverlay={false}
      />
      <div>
        <MikeTable
          actions={actions}
          loading={loading}
          onSelect={onSelect}
          rows={projects}
          config={config}
          columns={columns}
          onAdd={handleOnAdd}
          title={'All projects'}
          addButtonText={'New project'}
        />
      </div>
    </>
  );
};

export default MikeProjectSelector;
