import React from 'react';
import { ReactComponent as Email } from 'icons/Email';
import { IMailConfig } from './IMailConfig';
import { ILinkConfig } from './ILinkConfig';
import { MikeSupportMenuLinkItem } from './MikeSupportMenuLinkItem';

interface IProps {
  /**
   * Configuration.
   */
  config: IMailConfig;
  /**
   * Support item icon.
   */
  icon?: React.ReactNode;
  /**
   * Support item label.
   */
  label?: string;
}

/**
 * @name MikeSupportMenuMailItem
 * @summary A generic mail link that users can press to send a message to a human. Meant to be placed in the mike support menu container.
 */
export const MikeSupportMenuMailItem = (props: IProps) => {
  const { config, icon, label } = props;

  const createMailto: () => string = () => {
    let mailto: string =
      config.cc || config.subject || config.body ? `mailto:${config.to}?` : `mailto:${config.to}`;
    mailto += config.cc ? `cc=${config.cc}&` : '';
    mailto += config.subject ? `subject=${encodeURIComponent(config.subject)}&` : '';
    mailto += config.body ? `body=${encodeURIComponent(config.body)}&` : '';
    return mailto;
  };

  const linkConfig: ILinkConfig = {
    href: createMailto(),
    icon: icon ? icon : <Email />,
    label: label ? label : 'Contact support',
  };

  return <MikeSupportMenuLinkItem config={linkConfig} />;
};

export default MikeSupportMenuMailItem;
