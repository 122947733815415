import React, { useState, useCallback } from 'react';
import Fab from '@material-ui/core/Fab';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { ReactComponent as Search } from 'icons/Search';
import { ReactComponent as Close } from 'icons/Close';
import _MikeTable from './_MikeTable';
import { MIKE_COLORS } from '../mike-shared-styles/mike-colors';
import { Grid, Typography } from '@material-ui/core';
import { orderBy } from 'lodash';

interface IProps {
  rows: Array<any>; // TODO: Add real type iso any
  loading: boolean;
  onSelect?: (row: any) => void; // TODO: Add real type iso any
  onAdd?: (project: any) => void; // TODO: Add real type iso any
  title?: string;
  searchPlaceHolder?: string;
  tooltipAddButton?: string;
  addButtonText?: string;
  config: any; // TODO: Add real type iso any
  columns: any; // TODO: Add real type iso any
  onUpdate?: (row: any) => void; // TODO: Add real type iso any
  onDelete?: (row: any) => void; // TODO: Add real type iso any
  actions?: any; // TODO: Add real type iso any
  maxHeight?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: MIKE_COLORS.XLIGHTGREY,
      verticalAlign: 'center',
      marginTop: 32,
      boxSizing: 'content-box',
    },
    containerNoMargins: {
      background: MIKE_COLORS.XLIGHTGREY,
      verticalAlign: 'center',
      boxSizing: 'content-box',
    },
    tableContainer: {
      marginTop: '40px',
    },
    tableContainerNoMargins: {
      padding: 0,
    },
    textField: {
      width: 320,
      height: 40,
      background: '#FFFFFF',
      borderRadius: '25px',
      border: '1px solid #DBE4E9 !important',
      maring: 0,
      marginLeft: 32,
      marginTop: 6,
    },
    title: {
      margin: 0,
      color: MIKE_COLORS.BRANDBLUE_DEFAULT,
      marginLeft: 32,
    },
    fab: {
      float: 'right',
      textTransform: 'none',
      margin: 0,
      marginRight: 32,
    },
    head: {
      padding: 0,
      marginBottom: '24px',
    },
  })
);

/**
 * @name MikeTable
 * @summary A generic data table component, built on top of Material UI tables.
 */
export const MikeTable: React.FC<IProps> = ({
  columns,
  config,
  rows,
  title,
  searchPlaceHolder,
  onSelect,
  loading,
  addButtonText,
  onAdd,
  actions,
  maxHeight,
}) => {
  const [filter, setFilter] = useState('');
  const [filteredRows, setFilteredRows] = React.useState(rows);
  const [isSorting, setIsSorting] = React.useState(false);
  const [orderByField, setOrderByField] = React.useState({
    field: config.defaultSort.field,
    dir: 'desc',
    type: config.defaultSort.type,
  });

  const classes = useStyles();

  React.useEffect(() => {
    let dates: Array<any> = [];
    if (filter === '') {
      let orderedRows: Array<any> = [];
      if (orderByField.type === 'date') {
        orderedRows = rows.sort((a: any, b: any) => {
          const aDate = new Date(a[orderByField.field]);
          const bDate = new Date(b[orderByField.field]);
          return aDate.getTime() - bDate.getTime();
        });
        dates = orderByField.dir === 'desc' ? orderedRows.reverse() : orderedRows;
      } else {
        // Ignore case when ordering by string:
        const sortHandler = (row) => {
          return row[orderByField.field].toLowerCase
            ? row[orderByField.field].toLowerCase()
            : row[orderByField.field];
        };
        dates = orderBy(rows, sortHandler, [orderByField.dir as any]);
      }
      setFilteredRows(dates);
      setIsSorting(false);
      return;
    }
    const filterRows: Array<any> = [];
    for (const row of rows) {
      let contains = false;
      for (const col of columns) {
        if (contains || !col.filtrable) {
          break;
        }
        contains = false;
        const objValues: Array<string> = Object.values(row);
        for (const value of objValues) {
          if (
            value &&
            value
              .toString()
              .toLowerCase()
              .includes(filter.toLowerCase())
          ) {
            filterRows.push(row);
            contains = true;
            break;
          }
        }
      }
    }
    let orderedFilteredRows: Array<any> = [];
    if (orderByField.type === 'date') {
      orderedFilteredRows = filterRows.sort((a: any, b: any) => {
        const aDate = new Date(a[orderByField.field]);
        const bDate = new Date(b[orderByField.field]);
        return aDate.getTime() - bDate.getTime();
      });
      orderedFilteredRows =
        orderByField.dir === 'desc' ? orderedFilteredRows.reverse() : orderedFilteredRows;
    } else {
      orderedFilteredRows = orderBy(filterRows, orderByField.field, [orderByField.dir as any]);
    }
    setFilteredRows(orderedFilteredRows);
    setIsSorting(false);
  }, [filter, orderByField, rows]);

  const handleClearFilter = useCallback(() => {
    setFilter('');
  }, []);

  const handleSetFilter = useCallback((e: any) => {
    setFilter(e.target.value);
  }, []);

  // TODO: Use real type iso any:
  const handleSetSorting = useCallback((param: any) => {
    setIsSorting(true);
    setOrderByField(param);
  }, []);

  return (
    <>
      <div
        className={
          config.addButton || config.filtered ? classes.container : classes.containerNoMargins
        }
      >
        <div>
          <Grid container className={classes.head}>
            <Grid item xs={6}>
              {title && (
                <Typography variant="h1" className={classes.title}>
                  {title ? title : null}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              {config.addButton && (
                <Fab color="secondary" className={classes.fab} onClick={onAdd} variant="extended">
                  <AddIcon />
                  <span style={{ padding: 5 }}>{addButtonText ? addButtonText : null}</span>
                </Fab>
              )}
            </Grid>
            <Grid item xs={6}>
              {config.filtered && (
                <Input
                  autoFocus
                  className={classes.textField}
                  onChange={handleSetFilter}
                  disableUnderline={true}
                  placeholder={searchPlaceHolder ? searchPlaceHolder : 'What are you looking for?'}
                  value={filter}
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                  endAdornment={
                    filter !== '' ? (
                      <InputAdornment position="end" onClick={handleClearFilter}>
                        <Close />
                      </InputAdornment>
                    ) : null
                  }
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <div />
            </Grid>
          </Grid>
        </div>
        <div
          className={
            config.addButton || config.filtered
              ? classes.tableContainer
              : classes.tableContainerNoMargins
          }
        >
          <_MikeTable
            rows={filteredRows}
            filter={filter}
            loading={loading}
            isSorting={isSorting}
            columns={columns}
            config={config}
            actions={actions}
            onSelect={onSelect}
            maxHeight={maxHeight}
            onSort={handleSetSorting}
            sortByField={orderByField}
          />
        </div>
      </div>
    </>
  );
};

export default MikeTable;
