import React from 'react';
import { getMonth, getYear } from 'date-fns';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { ReactComponent as Left } from 'icons/Left';
import { ReactComponent as Right } from 'icons/Right';

import MikeDatePickerSelectMonth from './MikeDatePickerSelectMonth';
import MikeDatePickerSelectYear from './MikeDatePickerSelectYear';
import { mikePalette } from '../mike-shared-styles/mikeSharedTheme';

interface IProps {
  date: Date;
  changeYear: (param) => void;
  changeMonth: (param) => void;
  decreaseMonth?: () => void;
  increaseMonth?: () => void;
  dateChange: (date: Date) => void;
  prevMonthButtonDisabled?: boolean;
  nextMonthButtonDisabled?: boolean;
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    marginTop: '15px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  selects: {
    marginTop: '24px',
    height: '35px',
  },
  arrowIcon: {
    color: mikePalette.primary.main,
  },
  leftIconButton: {
    padding: '0px',
    float: 'left',
    marginTop: '-10px',
    borderRadius: '25px',
    '&:hover': {
      backgroundColor: mikePalette.mediumGrey.light,
    },
  },
  rightIconButton: {
    padding: '0px',
    float: 'right',
    marginTop: '-10px',
    borderRadius: '25px',
    '&:hover': {
      backgroundColor: mikePalette.mediumGrey.light,
    },
  },
});

const MikeDatePickerHeader: React.FC<IProps> = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  dateChange,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const classes = useStyles();

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const TopBar = () => {
    return (
      <div className={classes.container}>
        <IconButton
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          className={classes.leftIconButton}
        >
          <Left className={classes.arrowIcon} />
        </IconButton>

        <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
          {months[getMonth(date)] + '   '}
          {getYear(date)}
        </span>
        <IconButton
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          className={classes.rightIconButton}
        >
          <Right className={classes.arrowIcon} />
        </IconButton>
      </div>
    );
  };

  const onChangeMonth = (e) => {
    changeMonth(months.indexOf(e.target.innerText as any));
  };

  const onChangeYear = (e) => {
    changeYear(e.target.innerText);
  };

  const onDateChange = (newdate: Date) => {
    dateChange(newdate);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <TopBar />
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginTop: '8px' }}>
            <MikeDatePickerSelectMonth
              onChangeMonth={onChangeMonth}
              onDateChange={onDateChange}
              date={date}
            />
            <MikeDatePickerSelectYear
              onChangeYear={onChangeYear}
              onDateChange={onDateChange}
              date={date}
              startYear={2000}
              endYear={2031}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MikeDatePickerHeader;
