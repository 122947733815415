import React from 'react';
import Cookies from 'js-cookie';
import * as qs from 'query-string';
import uuid from 'uuid/v4';

import IUserProfile from './IUserProfile';
import localStore from './localStore';
import {
  TOKEN_PREFIX,
  TOKEN_RESOURCE_PATH,
  TOKEN_STATE_PATH,
  DOMAIN_COOKIE,
  DOMAIN_NAME,
} from './constants';
import IConfig from './IConfig';
import './mike-login.css';

import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { ButtonProps } from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';

import MikeLoginProxy from './MikeLoginProxy';
import IParsedParams from './IParsedParams';
import { Typography } from '@material-ui/core';
import MikeButton from '../mike-button/MikeButton';

export interface IMikeLoginApi {
  checkToken: () => void;
  getToken: () => string | undefined;
  logout: (e?: any) => void;
}

interface IProps {
  config: IConfig;
  apiRef?: React.MutableRefObject<IMikeLoginApi | undefined>;
  onLoggedInChanged: (arg0: boolean, arg1: object) => void;
  onLoginError: (errors: string | Array<string>) => boolean;
  loginBackendUrl: string;
  logInLabel?: string;
  logOutLabel?: string;
  type?: string;
  minWidth?: number;
  flat?: boolean;
  loginButtonProps?: ButtonProps;
}

interface IState {
  _isLoggedIn: boolean;
  _user: IUserProfile;
  _userDetailsShown: boolean;
  _isLoggingIn: boolean;
  _anchorEl: null | HTMLElement;
}

/**
 * @name MikeLogin
 * @summary Login component for use within CloudBridge applications.
 */
export default class MikeLogin extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    logInLabel: 'Log in',
    logOutLabel: 'Log out',
    onLoggedInChanged: () => null,
    onLoginError: () => false,
    type: '',
    flat: true,
  };

  private userData: IUserProfile;
  private _proxy: MikeLoginProxy;
  private _isMounted = false;

  /**
   *
   */
  constructor(props: IProps) {
    super(props);
    this._onLoggedInChanged = this._onLoggedInChanged.bind(this);
    this._toggleUserDetails = this._toggleUserDetails.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.clearState = this.clearState.bind(this);  

    this.state = {
      _isLoggedIn: false,
      _user: {} as IUserProfile,
      _userDetailsShown: false,
      _isLoggingIn: false,
      _anchorEl: null
    };
  }

  public async componentDidMount() {
    this._isMounted = true;
    return await this.checkForLogin(this.props);
  }

  public async componentWillUnmount() {
    this._isMounted = false;
    return;
  }

  public async componentDidUpdate(_prevProps: IProps, _prevState: IState) {
    if (this._isMounted) {
      if (this.state._user.email === undefined || this.props.config === undefined) {
        return await this.checkForLogin(this.props);
      }
    }
    return;
  }

  // returns the cookie with the given name,
  // or null if not found
  private async getCookie(name: string) {
    return Cookies.get(name);
  }

  private async checkForLogin(props: IProps) {
    const loginStatus = this.state._isLoggedIn;

    if (!(props.config && props.config.clientId && props.loginBackendUrl)) {
      return;
    }
    if (!this.checkConfiguration(props)) {
      return false;
    }
    if (props.config.resource) {
      const setupSuccess = await this.setupResource(
        props.config,
        window.location.href,
        props.loginBackendUrl
      );

      if (!setupSuccess && !this.state._isLoggingIn && this._isMounted) {
        const sid = props.config.sessionId
          ? props.config.sessionId
          : await this.getCookie(DOMAIN_COOKIE);
        if (sid) {
          this.setState({ _isLoggingIn: true });
          const link = this.buildAdLink(
            this.props.config.tenantId,
            this.props.config.clientId,
            this.props.config.redirectUri,
            this.props.config.resource,
            'code',
            sid
          );

          window.location.href = link;
          return;
        }
      }

      if (setupSuccess !== loginStatus) {
        this._onLoggedInChanged(setupSuccess);
      }
    } else {
      // tslint:disable-next-line:no-console
      return console.error(`
            No 'resource' provided to the login component.
            Did you remember to pass in a configuration?
          `);
    }
    return;
  }

  /**
   * Clears component state: tokens, user & other.
   */
  public clearState() {
    localStore.allItems().forEach((key) => {
      if (key.indexOf(TOKEN_PREFIX) !== -1) {
        localStore.remove(key);
      }
    });

    if (this._isMounted) {
      this.setState({ _isLoggedIn: false, _user: {} as IUserProfile, _isLoggingIn: false });
    }
  }

  /**
   * Redirects to the AD login page for the currently configured resource.
   */
  public login(e: any) {
    if (e) {
      e.preventDefault();
    }

    if (!this.checkConfiguration(this.props)) {
      return false;
    }

    if (!this.state._isLoggingIn && this._isMounted) {
      this.setState({ _isLoggingIn: true });
      const link = this.buildAdLink(
        this.props.config.tenantId,
        this.props.config.clientId,
        this.props.config.redirectUri,
        this.props.config.resource
      );

      window.location.href = link;
      return true;
    }
    return false;
  }

  protected checkConfiguration(props: IProps) {
    if (!props.config) {
      // tslint:disable-next-line:no-console
      console.error(`
        No 'config' provided to the login component.
        Please provide configuration for it to work.
        The configuration object should contain: \n

        interface IConfig {
          clientId: string;
          tenantId: string;
          redirectUri?: string;
          resource?: string;
        }

        <MikeLogin config="{...}"></MikeLogin>
      `);

      return false;
    }

    return true;
  }

  /**
   * Checks if there is a authorization code for the main resource.
   */
  protected async setupResource(
    config: IConfig,
    url: string,
    loginBackendUrl: string
  ): Promise<boolean> {
    if (!this._proxy) {
      this._proxy = new MikeLoginProxy(loginBackendUrl, config);
      if (this.props.apiRef) {
        this.props.apiRef.current = {
          checkToken: this._proxy.checkToken.bind(this._proxy),
          getToken: this._proxy.getToken.bind(this._proxy),
          logout: this.logout,
        };
      }
    }

    if (this.hasCachedToken(config)) {
      return true;
    }

    // Removes URL parameters (?) content, maintaining the current URL.
    window.history.replaceState({}, document.title, window.location.pathname);
    // Step 2.1 Parse the query parameters
    let authorizationCode;
    const parameters = this.parseParameters(url);

    if (parameters.error && this._isMounted) {
      await this.deleteCookie();
      this.setState({ _isLoggingIn: false });

      return this.props.onLoginError(
        parameters.error_description
          ? parameters.error_description
          : 'No error description provided'
      );
    } else if (
      parameters.code &&
      parameters.code !== undefined &&
      parameters.code !== 'undefined' &&
      this._isMounted
    ) {
      authorizationCode = parameters.code;

      if (parameters.stateId) {
        // State id can been saved before calling the oauth2/authorize endpoint
        const stateUUid = localStore.get(
          `${TOKEN_PREFIX}.${TOKEN_RESOURCE_PATH}` +
            this.props.config.resource +
            `.${TOKEN_STATE_PATH}`
        );
        // We check state id to prevent Cross-Site Request Forgery (CSRF) attacks
        // https://docs.microsoft.com/en-us/azure/active-directory/azuread-dev/v1-protocols-oauth-code#successful-response
        if (parameters.stateId === stateUUid) {
          this.setState({ _isLoggingIn: true });
          const accessKeyReturned = await this.handleAccessKey(
            config,
            loginBackendUrl,
            authorizationCode
          );
          // stateOriginatingUrl includes the initial url before app has been redirected to authorize url
          window.location.href = parameters.stateOriginatingUrl;
          // We store session id in domain wide cookie so that other MIKE Cloud apps can use it for silent login
          if (
            parameters.session_state &&
            parameters.session_state !== undefined &&
            parameters.session_state !== 'undefined'
          ) {
            const sessionState: string = parameters.session_state.endsWith('#')
              ? parameters.session_state.slice(0, -1)
              : parameters.session_state;
            const currentDomain: string = localStore.get(`${TOKEN_PREFIX}.${DOMAIN_NAME}`);
            if (currentDomain) {
              Cookies.set(DOMAIN_COOKIE, sessionState, {
                path: '/',
                domain: currentDomain,
                secure: true,
                sameSite: 'strict',
              });
            }
          }
          return accessKeyReturned;
        }
      }
    }

    return false;
  }

  private async handleAccessKey(
    config: IConfig,
    loginBackendUrl: string,
    authorizationCode: string | Array<string>
  ): Promise<boolean> {
    try {
      await this._proxy.handleAccessKey(authorizationCode, config, loginBackendUrl);
      this.setUser(this._proxy.getUser());
    } catch (error) {
      this.props.onLoginError(error);
      if (this._isMounted) {
        this.setState({ _isLoggingIn: false });
      }
      return false;
    }

    return true;
  }

  private hasCachedToken(config: IConfig): boolean {
    const cachedToken = this._proxy.getCachedToken(config.resource);

    if (cachedToken) {
      const cachedTokenValid = this._proxy.isTokenValid(cachedToken, config.resource);

      if (cachedTokenValid) {
        const user = this._proxy.getUser();
        this.setUser(user);
        if (this._isMounted) {
          this.setState({ _isLoggingIn: false });
        }
        return true;
      }
    }

    return false;
  }

  protected parseParameters(parameters: string): IParsedParams {
    const queryResponse = parameters.split('?')[1];
    if (queryResponse) {
      const initialLoginResponse = qs.parse(queryResponse) || {};
      const initialLoginStateResponse =
        initialLoginResponse.state && typeof initialLoginResponse.state === 'string'
          ? qs.parse(initialLoginResponse.state)
          : {};
      const parsedParams: IParsedParams = {
        error: initialLoginResponse.error !== undefined,
        error_description: '' + initialLoginResponse.error_description,
        code: '' + initialLoginResponse.code,
        stateId: '' + initialLoginStateResponse.id,
        stateOriginatingUrl: '' + initialLoginStateResponse.originatingUrl,
        session_state: '' + initialLoginResponse.session_state,
      };
      return parsedParams;
    }
    return {} as IParsedParams;
  }

  /**
   * Sets user to this component's props.
   */
  protected setUser(user: IUserProfile) {
    if (this._isMounted) {
      this.setState({ _user: user });
    }
    this.userData = user;
  }

  /**
   * Builds an AD link. Adds a state uuid and writes to local storage for later integrity checks.
   */
  protected buildAdLink(
    tenantId: string,
    clientId: string,
    redirectUri: string = window.location.origin,
    resource: string,
    responseType: string = 'code',
    sid?: string
  ) {
    const stateUUid = uuid();

    localStore.set(
      `${TOKEN_PREFIX}.${TOKEN_RESOURCE_PATH}${resource}.${TOKEN_STATE_PATH}`,
      stateUUid
    );

    // We need to extract the domain name to store session id in a domain wide cookie
    const originatingUrl = window.location.href;
    const url = new URL(originatingUrl);
    const domainAndSubdomain = url.hostname.replace('www.', '');
    const domainParts = domainAndSubdomain.split('.');
    const domainPartsCount = domainParts.length;
    if (domainPartsCount >= 2) {
      const domain = domainParts[domainPartsCount - 2] + '.' + domainParts[domainPartsCount - 1];
      localStore.set(`${TOKEN_PREFIX}.${DOMAIN_NAME}`, domain);
    }

    // We use state to return to originating url after requesting access token
    const config = {
      response_type: responseType,
      client_id: clientId,
      redirect_uri: redirectUri,
      response_mode: 'query',
      resource,
      state: qs.stringify({
        id: stateUUid,
        originatingUrl: window.location.href,
      }),
      sid: sid ? sid : '',
    };

    return `https://login.microsoftonline.com/${tenantId}/oauth2/authorize?${qs.stringify(config)}`;
  }

  /**
   * Builds AD logout link.
   */
  protected buildAdLogoutLink(tenantId: string, redirectUri: string = window.location.origin) {
    return `https://login.windows.net/${tenantId}/oauth2/logout?post_logout_redirect_uri=${redirectUri}`;
  }

  private async deleteCookie() {
    const currentDomain: string = localStore.get(`${TOKEN_PREFIX}.${DOMAIN_NAME}`);
    if (currentDomain) {
      Cookies.remove(DOMAIN_COOKIE, { path: '/', domain: currentDomain });
    }
  }

  /**
   * Clears local resource local strorage & redirect to MS.
   */
  protected async logout(e?: any) {
    if (e) {
      e.preventDefault();
    }

    await this.deleteCookie();

    this.clearState();
    this._onLoggedInChanged(false);

    window.location.href = this.buildAdLogoutLink(this.props.config.tenantId);
  }

  /**
   * Changes this component's props to reflect the login state and calls the optional provided callback to notify external components.
   * The callback defaults as a noop if not provided.
   */
  private _onLoggedInChanged(val: boolean) {
    if (this._isMounted) {
      this.setState({ _isLoggedIn: val, _isLoggingIn: false });
    }
    this.props.onLoggedInChanged(val, {
      ...this.userData,
      token: this._proxy.getToken(),
    });
  }

  /**
   * Toggles between showing and hiding user details.
   */
  private _toggleUserDetails(e: any) {
    if (e) {
      e.preventDefault();
    }

    if (this._isMounted) {
      if (this.state._userDetailsShown){
        this.setState({         
          _userDetailsShown: false          
        });
      }
      else
      {
        this.setState({
          _anchorEl: e.currentTarget,
          _userDetailsShown: true
        });
      }
    }
  }

  private handleClickAway = () => {
    if (this._isMounted) {
      this.setState({ _userDetailsShown: false, _anchorEl: null });
    }
  };

  public render() {
    const { _user, _isLoggedIn, _userDetailsShown, _isLoggingIn } = this.state;
    const { logInLabel, logOutLabel, type, minWidth, loginButtonProps } = this.props;

    if (_isLoggedIn) {
      return this.renderLoggedIn(_userDetailsShown, _user, logOutLabel!);
    } else {
      return this.renderLogin(type!, minWidth!, logInLabel!, _isLoggingIn, loginButtonProps);
    }
  }

  private renderLogin(
    type: string,
    minWidth: number,
    logInLabel: string,
    isLoggingIn: boolean,
    loginButtonProps?: ButtonProps
  ): React.ReactElement {
    const style = {
      minWidth: minWidth ? minWidth : 0,
      boxShadow: this.props.flat ? 'none' : undefined,
    };

    if (type === 'form') {
      return (
        <MikeButton
          buttontype="primary"
          fullWidth
          style={style}
          onClick={this.login}
          active={isLoggingIn}
          {...loginButtonProps}
        >
          {logInLabel}
        </MikeButton>
      );
    } else {
      return (
        <MikeButton
          onClick={this.login}
          buttontype="text"
          color="primary"
          active={isLoggingIn}
          {...loginButtonProps}
        >
          {logInLabel}
        </MikeButton>
      );
    }
  }

  private renderLoggedIn(
    _userDetailsShown: boolean,
    user: IUserProfile,
    logOutLabel: string
  ): React.ReactElement {
    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <div>
          <Fab
            onClick={this._toggleUserDetails}
            color="primary"
            size="small"           
            style={{
              fontWeight: 300,
              fontSize: 12,
              minHeight: 0,
              width: 32,
              height: 32,
              boxShadow: this.props.flat ? 'none' : undefined,
            }}
          >
            {user.initials}
          </Fab>
          <Popper
            anchorEl={this.state._anchorEl}
            placement="bottom-end"
            open={_userDetailsShown}
            disablePortal={false}
            onClick={this._toggleUserDetails}
            modifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement: 'window',
              },
            }}
            className="UserDetailsPopper"
          >
            <Paper elevation={2} style={{ padding: 16 }}>
              <Grid
                container
                direction="column"
                alignItems="flex-end"
                justifyContent="space-evenly"
                spacing={4}
              >
                <Grid item key="name">
                  <Typography>{user.name}</Typography>
                </Grid>
                <Grid item key="logout">
                  <MikeButton buttontype="primary" size="small" onClick={this.logout}>
                    {logOutLabel}
                  </MikeButton>
                </Grid>
              </Grid>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    );
  }
}
