import { AppBar, createStyles, makeStyles, Toolbar, Typography } from '@material-ui/core';
import { AppBarProps as MuiAppBarProps } from '@material-ui/core/AppBar';
import React, { useCallback } from 'react';
import { IMikeTheme } from '../mike-shared-styles/mikeSharedTheme';

interface IProps extends MuiAppBarProps {
  appIcon: any;
  appTitle: string;
  breadcrump?: any;
  homeButton: any;
  alertButton?: any;
  supportButton: any;
  loginButton: any;
  actionsArea?: any;
  alertArea?: any;
  appTitleCallback?: () => void;
  appsDropdown?: React.ReactNode;
}

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    mikeTopbarAppIcon: {
      marginLeft: '12px',
      marginRight: '20px',
    },
    mikeTopbarAppTitle: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 5px',
      paddingRight: '35px',
      minHeight: 60,
      color: theme.palette.text.primary,
      marginTop: '-4px',
    },
    mikeTopbarAppTitleFont: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 'bold',
    },
    mikeTopbarSupportButton: {
      display: 'flex',
    },
    mikeTopbarLoginButton: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 15px',
      minHeight: 60,
      '&:hover': {
        backgroundColor: theme.palette.text.disabled,
      },
      marginTop: '-4px',
    },
    mikeBreadCrump: {
      marginBottom: '2px',
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      fontSize: '14px',
    },
    mikeactionsArea: {},
    mikeappNameButton: {
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.text.disabled,
      },
    },
  });
});

/**
 * @name MikeTopbar
 * @summary Default topbar for all MIKE applications. Holds application name, breadcrumbs, profile information and more.
 */
export const MikeTopbar = (props: IProps) => {
  const {
    appIcon,
    appTitle,
    breadcrump,
    homeButton,
    alertButton,
    supportButton,
    loginButton,
    actionsArea,
    alertArea,
    appTitleCallback,
    appsDropdown,
    ...rest
  } = props;
  const classes = useStyles(props);
  const hasCallback = appTitleCallback !== undefined;

  const handleAppTitleCallback = useCallback(() => {
    if (appTitleCallback) {
      appTitleCallback();
    }
  }, []);

  return (
    <AppBar {...rest}>
      <Toolbar disableGutters={true}>
        {homeButton && <div>{homeButton}</div>}
        {appsDropdown ? (
          appsDropdown
        ) : (
          <div
            className={
              classes.mikeTopbarAppTitle + (hasCallback ? ' ' + classes.mikeappNameButton : '')
            }
            onClick={handleAppTitleCallback}
          >
            <div className={classes.mikeTopbarAppIcon}>{appIcon}</div>
            <Typography className={classes.mikeTopbarAppTitleFont}>{appTitle}</Typography>
          </div>
        )}

        <div className={classes.mikeBreadCrump}>{breadcrump}</div>

        <div className={classes.mikeactionsArea}>{actionsArea}</div>

        {alertButton ? <div>{alertButton}</div> : alertArea ? <div>{alertArea}</div> : ''}
        {supportButton && <div className={classes.mikeTopbarSupportButton}>{supportButton}</div>}
        {loginButton && <div className={classes.mikeTopbarLoginButton}>{loginButton}</div>}
      </Toolbar>
    </AppBar>
  );
};

export default MikeTopbar;
