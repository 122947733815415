import React from 'react';
import { IProgressItem } from '../IProgressItem';
import { makeStyles, createStyles, Typography } from '@material-ui/core';
import { MikeProgressBar } from './MikeProgressBar';
import { IMikeTheme } from '../../mike-shared-styles';
import { css } from 'emotion';
import MikeTooltip from '../../mike-tooltip';

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    progressSection: {
      margin: '10px 0 16px 0',
      '&:first-of-type': {
        marginTop: '0',
      },
      '&:last-of-type': {
        marginBottom: '0',
      },
    },
    progressErrorText: {
      color: theme.palette.error.main,
    },
    progressBar: {
      margin: '7px 0 0 0',
      width: '100%',
    },
  });
});

const ellip = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
`;

interface IProps extends React.HTMLAttributes<HTMLElement> {
  progressItem: IProgressItem;
}

/**
 * @name ProgressSection
 * @summary Renders a section of the MikeTopbarDropdown that should be removed after
 * a delay once it's done or has failed.
 * Not intended to be used without MikeProgressBar.
 */
function ProgressSection(props: IProps) {
  const { progressItem, className } = props;
  const classes = useStyles();
  const labelClass = progressItem.error ? classes.progressErrorText : '';

  return (
    <div className={`${classes.progressSection} ${className || ''}`}>
      <Typography className={labelClass} variant="body2">
        <MikeTooltip title={progressItem.title}>
          <span className={ellip}>{progressItem.title}</span>
        </MikeTooltip>
      </Typography>
      <MikeProgressBar
        variant="determinate"
        color="secondary"
        value={progressItem.progressValue}
        style={{ marginTop: '7px' }}
      />
    </div>
  );
}

export default ProgressSection;
