import React, { useEffect } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MikeButton, { IMikeButtonProps } from '../mike-button/MikeButton';
import { createStyles } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MIKE_COLORS from '../mike-shared-styles/mike-colors';
import MikeExpandableButtonEntries from './MikeExpandableButtonEntries';

export type OptionProps = {
  label: string;
  callBack?: () => void;
  disabled?: boolean;
};

const useStyles = makeStyles((_theme: any) => {
  return createStyles({
    MuiButtonGroup: (props: IMikeButtonProps) =>
      props.size !== 'large'
        ? {
            // TODO - masm: take the with from the theme like:
            // minWidth: theme && theme.props && theme.props.MuiButton && theme.props.sizeLarge && theme.props.sizeLarge.minWidth,
            minWidth: 156,
            maxWidth: 156,
          }
        : {
            minWidth: 328,
            maxWidth: 328,
          },
  });
});

export interface IExpandableButtonProps extends IMikeButtonProps {
  options: Array<OptionProps>;
}

export const MikeInnerExpandableButton = (props: IExpandableButtonProps) => {
  const { options, color, variant, active, children, ...rest } = props;

  const [menuWidth, setMenuWidth] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const classes = useStyles(props);

  useEffect(() => {
    const buttonGroup = anchorRef.current;
    if (buttonGroup) {
      const rect = buttonGroup.getBoundingClientRect();
      setMenuWidth(rect.width);
    }
  }, []);

  const sendCallBack = (currentOptionIndex: number) => {
    const option = options[currentOptionIndex];
    const { callBack } = option;
    if (callBack) {
      callBack();
    }
  };

  const handleClick = (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    sendCallBack(selectedIndex);
  };

  const handleMenuItemClick = (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    sendCallBack(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        color={color ? color : 'secondary'}
        variant={variant ? variant : 'contained'}
        ref={anchorRef}
        aria-label="split button"
        className={classes.MuiButtonGroup}
        fullWidth
      >
        <MikeButton
          {...rest}
          onClick={handleClick}
          disabled={options[selectedIndex] && options[selectedIndex].disabled}
        >
          {children ? (
            children
          ) : (
            <div
              style={{
                color:
                  color === 'secondary' || !color
                    ? MIKE_COLORS.WHITE
                    : MIKE_COLORS.BRANDBLUE_DEFAULT,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {options[selectedIndex] && options[selectedIndex].label}
            </div>
          )}
        </MikeButton>
        <MikeButton
          {...rest}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleToggle}
          style={{ width: '2.5rem' }}
        >
          <ArrowDropDownIcon />
        </MikeButton>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" style={{ width: menuWidth }}>
                  <MikeExpandableButtonEntries
                    options={options}
                    selectedIndex={selectedIndex}
                    handleClick={handleMenuItemClick}
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default MikeInnerExpandableButton;
