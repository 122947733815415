import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { IMikeTheme } from '../../mike-shared-styles';

export const MikeProgressBar = withStyles((theme: IMikeTheme) => ({
  root: {
    height: 8,
    backgroundColor: theme.palette.secondary.light, // '#97DBF9' Action blue - Light
    borderRadius: 4,
  },
  bar: {
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.main, // '#00A4EC' Action blue
  },
}))(LinearProgress);
