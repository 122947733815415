// No-operation, can eg be used for `condition ? go() : noop();`
// without having issues with eslint rules:
export const noop = () => {
  return void 0;
};

// Create a unique id, e.g. for an html element:
let id = 0;
export const getId = () => {
  id += 1;
  return `mike-shared-frontend-${id}`;
};
