import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, createStyles, Typography } from '@material-ui/core';
import { IMikeTheme } from '../mike-shared-styles';
import { IMikeSnackbarProps } from './IMikeMessage';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

const DEFAULT_AUTOHIDE_DURATION = 6000;

const getPColor = (props: IMikeSnackbarProps, theme: IMikeTheme): PaletteColor => {
  switch (props.mikeSnackbarType) {
    case 'regular':
      return theme.palette.primary;
    case 'success':
      return theme.palette.ultimate ? theme.palette.ultimate as PaletteColor : theme.palette.primary;
    case 'error':
      return theme.palette.error;
    default:
      throw new Error(`Invalid "props.mikeSnackbarType": ${props.mikeSnackbarType}`);
  }
};
const useStyles = makeStyles((theme: IMikeTheme) =>
  createStyles({
    root: (props: IMikeSnackbarProps) => {
      const pColor = getPColor(props, theme);
      return {
        background: pColor.main,
        color: pColor.contrastText,
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        width: theme.spacing(46), // '368px',
        borderRadius: theme.spacing(0.5), // '4px',
        minHeight: theme.spacing(3), // results in '56px' due to padding
        boxShadow: '0px 6px 6px 0px rgba(0,0,0,0.16)',
        bottom: theme.spacing(4),
        boxSizing: 'content-box',
      };
    },
  })
);

const useMikeStyles = makeStyles((theme: IMikeTheme) =>
  createStyles({
    mikeContent: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      overflow: 'hidden',
      alignItems: 'center',
      '& *': {
        color: theme.palette.primary.contrastText,
      },
    },
    mikeMessage: {},
    mikeAction: {},
    mikeActionBtn: {
      cursor: 'pointer',
      padding: `${theme.spacing(0)}px 0 ${theme.spacing(0)}px ${theme.spacing(3)}px`,
      '& *': {
        fontWeight: 500,
      },
    },
  })
);

/**
 * @name MikeSnackbar
 * @summary Snackbars provide brief messages about app processes at the bottom of the screen.
 */
export const MikeSnackbar = (props: IMikeSnackbarProps) => {
  const {
    children,
    message,
    action,
    mikeSnackbarType,
    mikeOnAction,
    autoHideDuration = DEFAULT_AUTOHIDE_DURATION,
    ...rest
  } = props;
  const classes = useStyles(props);
  const mikeClasses = useMikeStyles(props);
  const theMessage: React.ReactNode = message ? message : children;
  const theAction: React.ReactNode =
    typeof action === 'string' ? <ActionButton text={action} /> : action;

  return (
    <Snackbar {...rest} autoHideDuration={autoHideDuration} classes={classes}>
      <div className={mikeClasses.mikeContent}>
        <div className={mikeClasses.mikeMessage}>
          {typeof theMessage === 'string' ? (
            <Typography variant="body1">{theMessage}</Typography>
          ) : (
            theMessage
          )}
        </div>
        <div onClick={mikeOnAction} className={mikeClasses.mikeAction}>
          {theAction}
        </div>
      </div>
    </Snackbar>
  );
};

const ActionButton = ({ text }: { text: string }) => {
  const mikeClasses = useMikeStyles();
  return (
    <div className={mikeClasses.mikeActionBtn}>
      <Typography variant="body1">{text}</Typography>
    </div>
  );
};

export default MikeSnackbar;
