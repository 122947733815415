import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { IMikeTheme } from '../mike-shared-styles/mikeSharedTheme';
import { css } from 'emotion';
import MIKE_COLORS from '../mike-shared-styles/mike-colors';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  dropDownMaxHeight?: number;
  dropDownWidth?: number;
}
const DROPDOWN_MAX_HEIGHT = 590;
const DROPDOWN_WIDTH = 352;

const useStyles = makeStyles((theme: IMikeTheme) => {
  const mediumGrey = MIKE_COLORS.MEDIUMGREY_DEFAULT
  const darkGrey = MIKE_COLORS.DARKGREY_DEFAULT
  return createStyles({
    dropdownWrapper: {
      position: 'relative',
      width: '352px',
      top: '0',
      right: '294px',
      overflowY: 'visible',
    },
    triangleUp: {
      position: 'absolute',
      right: 18,
      top: 0,
      width: 0,
      height: 0,
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderBottom: '12px solid ' + mediumGrey,
    },
    dropdownContent: (props: IProps) => ({
      backgroundColor: mediumGrey,
      borderRadius: '4px 4px',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
      position: 'absolute',
      top: 12,
      right: 0,
      width: typeof props.dropDownWidth !== 'undefined' ? props.dropDownWidth : DROPDOWN_WIDTH,
      maxHeight:
        typeof props.dropDownMaxHeight !== 'undefined'
          ? props.dropDownMaxHeight
          : DROPDOWN_MAX_HEIGHT,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    }),
    notificationPerDayOuterContainer: (props: IProps) => ({
      backgroundColor: mediumGrey,
      borderRadius: '4px 4px',
      maxHeight:
        typeof props.dropDownMaxHeight !== 'undefined'
          ? props.dropDownMaxHeight - 48
          : DROPDOWN_MAX_HEIGHT - 48,
      paddingLeft: theme.spacing(2),
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: `${darkGrey} ${mediumGrey}`,
      '&::-webkit-scrollbar': {
        width: 24,
        borderRadius: '4px 4px',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '4px 4px',
        backgroundColor: mediumGrey,
        borderRight: '8px solid ' + mediumGrey,
        borderLeft: '12px solid ' + mediumGrey,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px 4px',
        backgroundColor: darkGrey,
        borderRight: '8px solid ' + mediumGrey,
        borderLeft: '12px solid ' + mediumGrey,
        '&:hover': {
          borderRight: '8px solid ' + mediumGrey,
          borderLeft: '12px solid ' + mediumGrey,
        },
      },
    }),
    notificationsTitle: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      fontWeight: 'bold',
    },
  });
});

/**
 * @name MikeTopbarDropdown
 * @summary A dropdown meant to be used in the MikeTopbar.
 */
export const MikeTopbarDropdown = (props: IProps) => {
  const { children, className } = props;
  const classes = useStyles(props);
  const maxHeight = props.dropDownMaxHeight ? props.dropDownMaxHeight : DROPDOWN_MAX_HEIGHT;
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [containerRightPadding, setContainerRightPadding] = React.useState(0);

  React.useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const height = containerRef.current.getBoundingClientRect().height;
    setContainerRightPadding(height < maxHeight ? 24 : 0);
  });

  return (
    <div {...props} className={`${classes.dropdownWrapper} ${className || ''}`}>
      <div className={`${classes.triangleUp}`} />
      <div
        className={`${classes.dropdownContent}`}
        /* Function required by TS lint: */
        ref={(e) => (containerRef.current = e)}
      >
        <div
          className={classes.notificationPerDayOuterContainer}
          style={{ paddingRight: `${containerRightPadding}` + 'px' }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MikeTopbarDropdown;

// Wrap a div with this style around your button AND your dropdown
// to align them dynamically:
export const wrapperStyle = css`
  position: relative;
  height: 55px;
  width: 60px;
  margin-top: -10px;
`;
