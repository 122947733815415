import IConfig from './IConfig';
import axios, { AxiosInstance } from 'axios';
import JwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import IDecodedJWT from './IDecodedJWT';
import IUserProfile from './IUserProfile';
import localStore from './localStore';
import {
  TOKEN_PREFIX,
  TOKEN_RESOURCE_PATH,
  TOKEN_USER_PATH,
  TOKEN_STATE_PATH,
  TOKEN_REFRESH_PATH,
  TOKEN_EXP_PATH,
  TOKEN_ADDITIONAL_RESOURCE_PATH,
  DOMAIN_COOKIE,
  DOMAIN_NAME,
} from './constants';
import { IAdditionalResource, IAdditionalResourceCache } from './IConfig';

export default class MikeLoginProxy {
  private _loginBackendUrl: string;
  private _jwtToken: string;
  private _jwtExp: number;
  private _config: IConfig;
  private _refreshToken: string;
  private _user: IUserProfile;
  private _additionalJwtTokens: Array<IAdditionalResourceCache>;

  constructor(loginBackendUrl: string, loginConfig: IConfig) {
    // Interceptor logic:
    axios.interceptors.request.use(
      async (config) => {
        if (
          config.url &&
          this._config.additionalResources &&
          this._config.additionalResources.length > 0
        ) {
          // If If config includes additional resources we need to find out
          // to which resource the url we are currently intercepting belongs
          const requestUrl = config.url;
          const additionalResourceFromConfig = this._config.additionalResources.find(
            (additionalResource: IAdditionalResource) =>
              requestUrl.startsWith(additionalResource.apiRootUrl)
          );
          if (additionalResourceFromConfig) {
            // Additional backend resources require an access tokens
            // different from main resource's access token
            await this.checkAdditionalToken(additionalResourceFromConfig.resource);
            const cachedAdditionalToken:
              | IAdditionalResourceCache
              | undefined = this._additionalJwtTokens.find(
              (token: IAdditionalResourceCache) =>
                token.resource === additionalResourceFromConfig.resource
            );
            if (cachedAdditionalToken && cachedAdditionalToken.jwtToken && config.headers) {
              config.headers.authorization = 'Bearer ' + cachedAdditionalToken.jwtToken;
              if (!config.headers['api-version']) {
                config.headers['api-version'] = 1;
              }

              return Promise.resolve(config);
            }
          }
        }

        if (this._jwtToken) {
          await this.checkToken();
          if (config.headers){
            config.headers.authorization = 'Bearer ' + this._jwtToken;
            if (!config.headers['api-version']) {
              config.headers['api-version'] = 1;
            }
          }          

          return Promise.resolve(config);
        }
        return config;
      },
      (err) => {
        return Promise.reject(err);
      }
    );

    this._loginBackendUrl = loginBackendUrl;
    this._config = loginConfig;
    this._jwtToken = this.getCachedToken(loginConfig.resource);
    this._jwtExp = this.getTokenExpiry(loginConfig.resource);
    this._refreshToken = this.getRefreshToken(loginConfig.resource);
    this._user = this.getCachedUser();
    const cachedAditionalTokens: Array<IAdditionalResourceCache> = this.getCachedAdditionalTokens();
    this._additionalJwtTokens = cachedAditionalTokens
      ? cachedAditionalTokens
      : Array<IAdditionalResourceCache>();
  }

  public async checkToken() {
    if (this._jwtExp) {
      const tokenIsExpired = this._jwtExp * 1000 < Date.now().valueOf();
      if (tokenIsExpired) {
        await this.renewToken(this._config, this._loginBackendUrl);
      }
    }
  }

  public async checkAdditionalToken(additionalResource: string) {
    let needsRenewal = true;
    const cachedAdditionalToken:
      | IAdditionalResourceCache
      | undefined = this._additionalJwtTokens.find(
      (token: IAdditionalResourceCache) => token.resource === additionalResource
    );
    if (cachedAdditionalToken) {
      needsRenewal =
        !cachedAdditionalToken.jwtTokenExp ||
        cachedAdditionalToken.jwtTokenExp * 1000 < Date.now().valueOf();
    }
    if (needsRenewal) {
      await this.renewAdditionalToken(this._config, this._loginBackendUrl, additionalResource);
    }
  }

  public getUser() {
    return this._user;
  }

  public getToken() {
    return this._jwtToken;
  }

  public async handleAccessKey(
    authCode: string | Array<string>,
    config: IConfig,
    loginBackendUrl: string
  ) {
    const response = await this.getAccessKey(
      config.tenantId,
      'authorization_code',
      config.clientId,
      authCode,
      config.redirectUri,
      config.resource,
      loginBackendUrl
    );

    this._jwtToken = response.data.access_token;
    this._refreshToken = response.data.refresh_token;
    const decodedJwt = this.decodeJwtToken(this._jwtToken);
    this._jwtExp = decodedJwt.exp;
    this.cacheToken(decodedJwt, this._jwtToken, config.resource, this._refreshToken);
  }

  public renewToken = async (config: IConfig, loginBackendUrl: string) => {
    const refreshToken = this.getRefreshToken(config.resource);

    if (refreshToken) {
      const response = await this.renewAccesskey(
        config.tenantId,
        config.clientId,
        'refresh_token',
        refreshToken,
        config.resource,
        loginBackendUrl
      );

      // Step 5.1: Handle successful response from mike-login backend
      this._jwtToken = response.data.access_token;
      this._refreshToken = response.data.refresh_token;

      const decodedJwt = this.decodeJwtToken(this._jwtToken);
      this._jwtExp = decodedJwt.exp;

      this.cacheToken(decodedJwt, this._jwtToken, config.resource, this._refreshToken);
    }
  };

  public renewAdditionalToken = async (
    config: IConfig,
    loginBackendUrl: string,
    additionalResource: string
  ) => {
    const refreshToken = this.getRefreshToken(config.resource);

    if (refreshToken) {
      const response = await this.renewAccesskey(
        config.tenantId,
        config.clientId,
        'refresh_token',
        refreshToken,
        additionalResource,
        loginBackendUrl
      );

      // Step 5.1: Handle successful response from mike-login backend
      this._refreshToken = response.data.refresh_token && response.data.refresh_token;
      const jwt = response.data.access_token;
      if (jwt) {
        const decodedJwt = this.decodeJwtToken(jwt);
        this.cacheAdditionalToken(
          config.resource,
          {
            resource: additionalResource,
            jwtToken: jwt,
            jwtTokenExp: decodedJwt && decodedJwt.exp,
          },
          this._refreshToken
        );
      }
    }
  };

  /**
   * Get the access_key.
   *
   * @param { GetAccessKey }
   * @function
   */
  protected getAccessKey = async (
    tenantId: string,
    grantType: string = 'authorization_code',
    clientId: string,
    returnedCode: string | Array<string>,
    redirectUri: string = window.location.origin,
    backendResource: string,
    loginBackendUrl: string
  ) => {
    const requestBody = {
      tenant_id: tenantId,
      grant_type: grantType,
      client_id: clientId,
      code: returnedCode,
      redirect_uri: redirectUri,
      resource: backendResource,
    };
    const headerConfig = {
      headers: {
        'Content-Type': 'application/json',
        'api-version': 1,
      },
    };

    const instance = this.getAxiosInstance();
    // Due to CORS Microsoft's /oauth2/token endpoint is blocked - so the request is done in mike-login backend
    // ToDO: Change the Url to mike-login backend instance `https://dhi-graphapi-dev.azurewebsites.net/api/RequestAccessToken`,
    return await instance.post(
      loginBackendUrl + `/api/RequestAccessToken`,
      requestBody,
      headerConfig
    );
  };

  protected renewAccesskey = async (
    tenantId: string,
    clientId: string,
    grantType: string = 'refresh_token',
    refreshToken: string,
    backendResource: string,
    loginBackendUrl: string
  ) => {
    const requestBody = {
      tenant_id: tenantId,
      client_id: clientId,
      refresh_token: refreshToken,
      grant_type: grantType,
      resource: backendResource,
    };
    const headerConfig = {
      headers: {
        'Content-Type': 'application/json',
        'api-version': 1,
      },
    };

    const instance = this.getAxiosInstance();
    // Due to CORS Microsoft's /oauth2/token endpoint is blocked - so the request is done in mike-login backend
    // ToDO: Change the Url to mike-login backend instance  `https://dhi-graphapi-dev.azurewebsites.net/api/RefreshAccessToken`,
    return await instance.post(
      loginBackendUrl + `/api/RefreshAccessToken`,
      requestBody,
      headerConfig
    );
  };

  private getAxiosInstance(): AxiosInstance {
    return axios.create();
  }

  /**
   * Stores user details (IUserProfile) to local storage & this component's props, as well as the encoded token.
   */
  protected cacheToken(
    decodedToken: IDecodedJWT,
    encodedToken: string,
    resource: string,
    refreshToken: string
  ) {
    this._user = this.extractUser(decodedToken);
    this._jwtToken = encodedToken;

    localStore.set(`${TOKEN_PREFIX}.${TOKEN_RESOURCE_PATH}${resource}`, encodedToken);
    localStore.set(
      `${TOKEN_PREFIX}.${TOKEN_RESOURCE_PATH}${resource}.${TOKEN_EXP_PATH}`,
      decodedToken.exp
    );

    localStore.set(
      `${TOKEN_PREFIX}.${TOKEN_RESOURCE_PATH}${resource}.${TOKEN_REFRESH_PATH}`,
      refreshToken
    );
    localStore.set(`${TOKEN_PREFIX}.${TOKEN_USER_PATH}`, this._user);
  }

  /**
   * Stores additional encoded token and new refresh token to local storage & this component's props.
   */
  protected cacheAdditionalToken(
    resource: string,
    additionalResource: IAdditionalResourceCache,
    refreshToken: string
  ) {
    const others = this._additionalJwtTokens.filter(
      (token: IAdditionalResourceCache) => token.resource !== additionalResource.resource
    );
    const newArray = [...others, additionalResource];
    this._additionalJwtTokens = newArray;

    localStore.set(`${TOKEN_PREFIX}.${TOKEN_ADDITIONAL_RESOURCE_PATH}`, this._additionalJwtTokens);

    localStore.set(
      `${TOKEN_PREFIX}.${TOKEN_RESOURCE_PATH}${resource}.${TOKEN_REFRESH_PATH}`,
      refreshToken
    );
  }

  /**
   * Saves user details to this component's props.
   */
  protected extractUser(decodedToken: IDecodedJWT): IUserProfile {
    const user: IUserProfile = {
      id: decodedToken.oid,
      tenantId: decodedToken.tid,
      name: decodedToken.name,
      initials: decodedToken.name
        .split(' ')
        .map((n) => n[0])
        .join(''),
      email: decodedToken.email || decodedToken.upn,
      customerId: decodedToken.CustomerId ? decodedToken.CustomerId : undefined,
      customerName: decodedToken.CustomerName ? decodedToken.CustomerName : undefined,
      customerDisplayName: decodedToken.CustomerDisplayName
        ? decodedToken.CustomerDisplayName
        : undefined,
      mikeCloudApplications: decodedToken.MikeCloudApplications
        ? decodedToken.MikeCloudApplications
        : undefined,
      isCustomerAdministrator: decodedToken.IsCustomerAdministrator
        ? decodedToken.IsCustomerAdministrator
        : undefined,
    };

    if (decodedToken.sid) {
      // In case the backend app registration has been configured to return sid as option token claim we can refresh the cookie
      // https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#configuring-optional-claims
      const currentDomain: string = localStore.get(`${TOKEN_PREFIX}.${DOMAIN_NAME}`);
      if (currentDomain) {
        Cookies.set(DOMAIN_COOKIE, decodedToken.sid, {
          path: '/',
          domain: currentDomain,
          secure: true,
          sameSite: 'strict',
        });
      }
    }

    return user;
  }

  /**
   * Retrieves an array of tokens for configured resources from local storage.
   */
  public getCachedAdditionalTokens(): Array<IAdditionalResourceCache> {
    return localStore.get(`${TOKEN_PREFIX}.${TOKEN_ADDITIONAL_RESOURCE_PATH}`);
  }

  /**
   * Retrieves a token for a given resource from local storage.
   */
  public getCachedToken(resource: string): string {
    return localStore.get(`${TOKEN_PREFIX}.${TOKEN_RESOURCE_PATH}${resource}`);
  }

  /**
   * Retrieves a expiry date for a given resource from local storage.
   */
  protected getTokenExpiry(resource: string): number {
    return localStore.get(`${TOKEN_PREFIX}.${TOKEN_RESOURCE_PATH}${resource}.${TOKEN_EXP_PATH}`);
  }

  protected getRefreshToken(resource: string): string {
    return localStore.get(
      `${TOKEN_PREFIX}.${TOKEN_RESOURCE_PATH}${resource}.${TOKEN_REFRESH_PATH}`
    );
  }

  protected getCachedUser(): IUserProfile {
    return localStore.get(`${TOKEN_PREFIX}.${TOKEN_USER_PATH}`);
  }

  /**
   * Decode an encoded JWT token.
   */
  protected decodeJwtToken(encodedToken: string): IDecodedJWT {
    const decodedToken = JwtDecode(encodedToken);
    return decodedToken as IDecodedJWT;
  }

  /**
   * Checks if a token is valid for a given resource.
   *
   * The performed checks are:
   * - is the token expired
   * - does the URL state match the cached state (NB: state is only available directly after a reply, it can't be checked for cached tokens).
   *
   * NB: state is expected to be present in local storage for each resource. See `buildAdLink()`.
   */
  public isTokenValid(
    encodedToken: string,
    resource: string,
    urlState?: string | undefined | Array<string>
  ): boolean {
    const cachedState = localStore.get(
      `${TOKEN_PREFIX}.${TOKEN_RESOURCE_PATH}${resource}.${TOKEN_STATE_PATH}`
    );

    if (encodedToken) {
      const decodedToken = this.decodeJwtToken(encodedToken);

      // NB: Need to use valueOf to get UTC time.
      return (
        decodedToken &&
        (urlState ? urlState === cachedState : true) &&
        decodedToken.exp > Date.now().valueOf() / 1000
      );
    }
    return false;
  }
}
