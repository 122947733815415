import * as React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import { IMikeTheme } from '../mike-shared-styles';
import { ReactComponent as Support } from 'icons/Support';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface IProps {
  /**
   * Click callback.
   */
  onClickCallBack?: () => void;
}

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    button: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 10px',
      position: 'relative',
      minHeight: 60,
      '&:hover': {
        backgroundColor: theme.palette.text.disabled,
      },
      cursor: 'pointer',
    },
  });
});

/**
 * @name MikeSupportMenuButton
 * @summary The support bottom, opening the support menu.
 */
export const MikeSupportMenuButton = (props: IProps) => {
  const classes = useStyles();
  const { onClickCallBack } = props;

  return (
    <ButtonBase className={classes.button} onClick={onClickCallBack}>
      <Support />
    </ButtonBase>
  );
};

export default MikeSupportMenuButton;
