import React from 'react';
import './mike-spinner.css';

export type Size = 'xs' | 'small' | 'medium' | 'large' | 'embeded';

interface IProps {
  size?: Size;
  parentDisabled?: boolean;
}

/**
 * @name MikeSpinner
 * @summary Spinners indicate work is ongoing in that part of the application.
 */
export class MikeSpinner extends React.Component<IProps> {
  public static defaultProps = {
    size: 'small',
    parentDisabled: false,
  };

  public render() {
    const { size, parentDisabled } = this.props;
    const spinnerSize = size ? `${size}` : '';
    const disabled = parentDisabled ? 'parentDisabled' : '';

    return <div id="mike-spinner" className={`${spinnerSize} ${disabled}`} />;
  }
}

export default MikeSpinner;
