import React from 'react';

import { Typography, withStyles, createStyles } from '@material-ui/core';

import MikeTooltip from '../mike-tooltip/MikeTooltip';
import MikeIcon, { IconName } from '../mike-icon/MikeSvgIcon';

import './mike-content-switch.css';

// TODO(mapu): we should implement it with a style material MUI Tabs

interface IResource {
  name: string;
  icon?: any;

  // FIXME(mapu): it's not needed to have 2 different tooltip props because the MikeTooltip's title prop accept a ReactNode
  tooltipText?: string;
  tooltipJSX?: JSX.Element;

  // FIXME(mapu): looks like that it's not used
  route?: string;

  dhiIcon?: IconName;
}

interface IProps {
  resources: Array<IResource>;
  callBack: (arg0: string) => void;
  currentResource: string;
  classes: any;
}

const style = () =>
  createStyles({
    typography: {
      fontSize: 14,
      textAlign: 'left',
    },
  });

/**
 * @name MikeContentSwitch
 * @summary Similar to tabs, allows changing between content sections within the same page.
 * @deprecated Should use tabs instead
 */
export class MikeContentSwitch extends React.Component<IProps, {}> {
  private getResourceByName: any = (resources: any, value: string) => {
    const resource = resources.filter((res: any) => {
      return res.name === value ? res : null;
    });
    return resource && resource[0];
  };

  private handleClick = (e: any) => {
    const { callBack } = this.props;
    const resource = this.getResourceByName(this.props.resources, e.target.innerText);
    callBack(resource);
  };

  private renderIcon(resource: IResource) {
    return resource.dhiIcon ? (
      <MikeIcon name={resource.dhiIcon} />
    ) : (
      React.createElement(resource.icon)
    );
  }

  public render() {
    const { currentResource, resources, classes } = this.props;
    return (
      <div className="c-Content-Switch">
        <ul>
          {resources.map((resource: IResource) => {
            const title = resource.tooltipText ? resource.tooltipText : resource.tooltipJSX ? resource.tooltipJSX : "";

            return (
              <MikeTooltip title={title !== undefined ? title : resource.name} key={resource.name}>
                <li
                  key={resource.name}
                  onClick={this.handleClick}
                  className={
                    currentResource === resource.name
                      ? 'c-Current-Content-selected'
                      : 'c-Current-Content'
                  }
                >
                  <div className="c-Content-Switch-Icon">
                    {(resource.icon || resource.dhiIcon) && this.renderIcon(resource)}
                  </div>
                  <span className="c-ContentSwitch-Label">
                    <Typography color="primary" variant="h2" className={classes.typography}>
                      {resource.name}
                    </Typography>
                  </span>
                </li>
              </MikeTooltip>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default withStyles(style)(MikeContentSwitch);
