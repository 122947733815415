import React from 'react';
import ProgressSection from './ProgressSection';
import { IProgressItem } from '../IProgressItem';
import MikeTopbarDropdown from '../../mike-topbar-dropdown';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  progressItems: Array<IProgressItem>;
}

function SpinnersDropdown(props: IProps) {
  const { progressItems } = props;
  return (
    <MikeTopbarDropdown className={props.className}>
      {progressItems.map((item, i) => (
        <ProgressSection key={i} title={item.title} progressItem={item} />
      ))}
    </MikeTopbarDropdown>
  );
}

export default SpinnersDropdown;
