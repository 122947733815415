import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import './MikeResourcePoolV2.css';

export interface IResourceV2 {
  label?: string;
  total: number;
  used: number;
  title?: string;
  renewalDate?: string;
}

interface IProps {
  resources: Array<IResourceV2>;
}

/**
 * @name MikeResourcePoolV2
 * @summary Shows resource pool usage. Should replace MikeResourcePool.
 */
export class MikeResourcePoolV2 extends React.Component<IProps, {}> {
  private renderBar(resource: IResourceV2) {
    const usedPercentage = (resource.used / resource.total) * 100;
    const usedPercentageStyle = usedPercentage + '%';
    const unUsedPercentageStyle = 100 - usedPercentage + '%';
    const resourceExceeded = resource.used > resource.total;
    if (!resourceExceeded) {
      return (
        <div className="c-Resource-Pool-Bar-v2">
          <div
            className={
              'c-Resource-Pool-Bar-Used-v2 ' +
              (usedPercentage === 100 ? 'c-Resource-Pool-Bar-both' : 'c-Resource-Pool-Bar-left')
            }
            style={{ width: usedPercentageStyle }}
          />
          <div
            className={
              'c-Resource-Pool-Bar-Total-v2 ' +
              (usedPercentage === 0 ? 'c-Resource-Pool-Bar-both' : 'c-Resource-Pool-Bar-right')
            }
            style={{ width: unUsedPercentageStyle }}
          />
        </div>
      );
    } else {
      return (
        <div className="c-Resource-Pool-Bar-v2">
          <div
            className="c-Resource-Pool-Bar-Used-v2--exceeded c-Resource-Pool-Bar-both"
            style={{ width: '100%' }}
          />
        </div>
      );
    }
  }

  private renderResources() {
    const { resources } = this.props;
    return (
      resources &&
      resources.map((resource: IResourceV2, index: number) => {
        return (
          <div key={index} style={{ marginTop: 25 }}>
            {this.renderTitle(resource)}
            {this.renderBar(resource)}
            {this.renderTotal(resource)}
          </div>
        );
      })
    );
  }

  private renderTitle(resource: IResourceV2) {
    return (
      <span className="c-Resource-Pool-Title-v2">
        <span className="c-Resource-Pool-Title-Label-v2">
          <b>{resource.label}</b>
        </span>
        <span className="c-Resource-Pool-Title-Date-v2">
          Renewal date: {resource && resource.renewalDate}
        </span>
      </span>
    );
  }

  private renderTotal(resource: IResourceV2) {
    return (
      <div>
        <span className="c-Resource-Pool-Total-v2">
          <span className="c-Resource-Pool-warning">
            {resource.used > resource.total && <WarningIcon color="error" />}
          </span>
          {resource.used}/{resource.total}
        </span>
      </div>
    );
  }

  public render() {
    return <div className="c-Resource-Pool-v2">{this.renderResources()}</div>;
  }
}

export default MikeResourcePoolV2;
