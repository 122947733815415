import React, { ReactNode } from 'react';
import { css } from 'emotion';

const PanelStyle = css`
  width: 100%;

  .panel__body {
    background-color: white;
  }
`;

const PanelBodyStyle = css`
  background-color: white;
`;

export interface IProps {
  /**
   * Optional content of the panel
   */
  children?: ReactNode;
}

/**
 * @name MikeStickyPanel
 * @summary A generic container that should be placed inside a sidepanel.
 *
 * @param props
 */
const MikeStickyPanel: React.FC<IProps> = ({ children }) => {
  return (
    <div className={PanelStyle}>
      {children && <section className={PanelBodyStyle}>{children}</section>}
    </div>
  );
};

export default MikeStickyPanel;
