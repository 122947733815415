import React from 'react';

import { withStyles, WithStyles } from '@material-ui/core/styles';

import {
  Grid,
  CardContent,
  Card,
  ButtonBase,
  Dialog,
  Theme,
  Typography,
  IconButton,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';

import clsx from 'clsx';

import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';

import MikeAppIcon from '../mike-icon/MikeAppIcon';

interface IStatus {
  appName: string;
  creationDate: string;
  description: string;
  expectedSolved: string;
  impact: string;
  solution: string;
  status: string;
}

const styles = (theme: Theme) => ({
  card: {
    minWidth: 150,
    maxWidth: 300,
    marginTop: 20,
    minHeight: 166,
  },
  image: {
    width: 56,
    height: 56,
  },
  status: {
    marginRight: '10px',
    marginTop: '-20px',
  },
  disabled: {
    backgroundColor: '#DBE4E9',
  },
  fullWidth: {
    width: '100%',
    height: '100%',
    transition: 'transform 200ms ease-in-out',
    borderRadius: '10px',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  dialogTitle: {
    padding: theme.spacing(1),
  },
  titleBar: {
    display: 'flex',
  },
  titleHeadings: {
    padding: theme.spacing(2, 2, 0, 2),
    flex: '1',
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

interface IProps extends WithStyles<typeof styles> {
  application: any;
  notInSubscription: boolean;
  status: IStatus;
}

interface IInternalState {
  showDialog: boolean;
}

/**
 * @name MikeApplicationCard
 * @summary Display a card for a given MIKE application.
 */
export class MikeApplicationCard extends React.Component<IProps, IInternalState> {
  public state = { showDialog: false };

  private handleDialogClose = () => {
    this.setState({ showDialog: false });
  };

  private handleDialogOpen = () => {
    this.setState({ showDialog: true });
  };

  private renderCardContent(): JSX.Element {
    const { application, classes } = this.props;
    return (
      <ButtonBase
        className={classes.image + ' ' + classes.fullWidth}
        target="_blank"
        href={application.appUrl && application.appUrl}
      >
        <Grid container direction={`column`} justifyContent={`center`} alignItems={`center`} spacing={2}>
          <Grid item>
            {application.iconName && <MikeAppIcon name={application.iconName} />}
            {!application.iconName && <MikeAppIcon name={'meshGenerator'} />}
          </Grid>

          <Grid item>
            <Grid
              container
              direction={`row`}
              justifyContent={`flex-start`}
              alignItems={`baseline`}
              spacing={5}
            >
              <Grid item>
                <Typography color="primary" variant="h3" gutterBottom>
                  {application && application.appName ? application.appName : ''}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ButtonBase>
    );
  }

  private renderDialog() {
    const { classes } = this.props;

    return (
      <Dialog open={this.state.showDialog} onClose={this.handleDialogClose} fullWidth={true}>
        <MuiDialogTitle disableTypography className={classes.dialogTitle}>
          {this.renderDialogTitle()}
        </MuiDialogTitle>
        <MuiDialogContent>{this.renderDialogContent()}</MuiDialogContent>
      </Dialog>
    );
  }

  private renderDialogContent() {
    const { status } = this.props;
    let creationDate = 'N/A';

    if (status && status.creationDate) {
      creationDate = status.creationDate.split('T')[0];
    }

    return (
      <div>
        <p>{creationDate}</p>
        <p>
          <b>Summary of impact</b>
          <br />
          {status.impact}
        </p>
        <p>
          <b>Description</b>
          <br />
          {status.description}
        </p>
        <p>
          <b>Expected resolution time</b>
          <br />
          {status.expectedSolved}
        </p>
      </div>
    );
  }

  private renderDialogTitle() {
    const { application, classes } = this.props;
    return (
      <Grid container key="titlebar" direction="row" alignItems="flex-start">
        <Grid key="headings" className={classes.titleHeadings}>
          <b>{application && application.appName ? application.appName : ''}</b>
        </Grid>
        <Grid key="button" className={classes.closeButton}>
          <IconButton aria-label="Close" onClick={this.handleDialogClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  private renderStatus(): any {
    const { status, notInSubscription, classes } = this.props;
    const statusMessage: string = status && status.status ? status.status : 'Error';
    return (
      !notInSubscription &&
      statusMessage === 'Error' && (
        <Grid container direction={`row`} justifyContent={`flex-end`}>
          <Grid item>
            <ButtonBase className={classes.status} onClick={this.handleDialogOpen}>
              <WarningIcon color="error" />
            </ButtonBase>
          </Grid>
        </Grid>
      )
    );
  }

  public render(): JSX.Element {
    const { status, classes, notInSubscription } = this.props;
    const disabled = notInSubscription === true ? classes.disabled : '';
    return (
      <div>
        <Card className={clsx(classes.card, disabled)}>
          <CardContent>{this.renderCardContent()}</CardContent>
          {status && this.renderStatus()}
        </Card>
        {status && this.renderDialog()}
      </div>
    );
  }
}

export default withStyles(styles)(MikeApplicationCard);
