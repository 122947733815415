import * as React from 'react';
import './mike-icon.css';

export type IconName =
  | 'menu'
  | 'expand'
  | 'minimize'
  | 'up'
  | 'down'
  | 'left'
  | 'right'
  | 'puzzle'
  | 'duplicate'
  | 'water'
  | 'learning'
  | 'uploadcloud'
  | 'closedfolder'
  | 'openfolder'
  | 'zoomin'
  | 'zoomout'
  | 'share'
  | 'download'
  | 'rename'
  | 'delete'
  | 'document'
  | 'data'
  | 'settings'
  | 'language'
  | 'center'
  | 'move'
  | 'minusZoomout'
  | 'addZoomin'
  | 'magnifyingGlass'
  | 'checkmark'
  | 'reorder'
  | 'play'
  | 'pause'
  | 'visible'
  | 'hidden'
  | 'close'
  | 'select'
  | 'move1'
  | 'ditches'
  | 'dikes'
  | 'deleteLastPoint'
  | 'draw'
  | 'polygon'
  | 'info'
  | 'save'
  | 'special'
  | 'rotateLeft'
  | 'rotateRight'
  | 'tableView'
  | 'mapView'
  | 'opacity'
  | 'undo'
  | 'redo'
  | 'sortByAscending'
  | 'sortByDecending'
  | 'history'
  | 'rotateDevice'
  | 'dropDownArrow'
  | 'closeMini'
  | 'upload'
  | 'lock'
  | 'block'
  | 'subscription'
  | 'analyze'
  | 'reports'
  | 'unlock'
  | 'send'
  | 'support'
  | 'dashboard'
  | 'home'
  | 'invoice'
  | 'calendar'
  | 'listView'
  | 'tileView'
  | 'featureList'
  | 'merge'
  | 'group'
  | 'ungroup'
  | 'grid'
  | 'adjust'
  | 'sun'
  | 'selectMultiple'
  | 'nestedTools'
  | 'isolationMode'
  | 'user'
  | 'admin'
  | 'addUser'
  | 'users'
  | 'emoticon'
  | 'comment'
  | 'mapPin'
  | 'pointInfo'
  | 'areaInfo'
  | 'zoomToArea'
  | 'profile'
  | 'north'
  | 'more'
  | 'link'
  | 'back'
  | 'dhi'
  | 'applications'
  | 'plans'
  | 'cloudUsers'
  | 'layer'
  | 'alert'
  | 'deleteInput'
  | 'magnifyingGlassSmall'
  | 'rightCompress'
  | 'warning'
  | 'ProcessedData'
  | 'CalendarSetting'
  | 'Projects'
  | 'MyProjects'
  | 'measureM'
  | 'measureFt';


export type Size = 'small' | 'medium' | 'large' | 'big';

interface IProps {
  name: IconName;
  type?: string;
  width?: number;
  height?: number;
  viewboxX?: number;
  viewboxY?: number;
  stroked?: boolean;
  inverted?: boolean;
  size?: Size;
  disabled?: boolean;
}

/**
 * @name MikeSvgIcon
 * @summary Icons as inline svg.
 *
 * @deprecated use icons from `Icons/Examples` instead.
 */
export class MikeSvgIcon extends React.Component<IProps> {
  public static defaultProps = {
    type: 'default',
    width: 40,
    height: 40,
    viewboxX: 0,
    viewboxY: 0,
    stroked: false,
    inverted: false,
    size: 'medium',
    disabled: false,
  };

  public render() {
    const {
      name,
      type,
      size,
      inverted,
      stroked,
      width,
      height,
      viewboxX,
      viewboxY,
      disabled,
    } = this.props;

    const iconStroke = `icon--${stroked ? 'stroke' : 'fill'}`;
    const iconType = `icon--${type}`;
    const iconInverted = inverted ? 'icon--inverted' : '';
    const iconSize = size ? `icon--${size}` : '';
    const iconDisabled = disabled ? 'icon--disabled' : '';

    return (
      <svg
        width={width}
        height={height}
        viewBox={`${viewboxX} ${viewboxY} ${width} ${height}`}
        fill="none"
        className={`icon ${iconType} ${iconStroke} ${iconInverted} ${iconSize} ${iconDisabled}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {getPath(name)}
      </svg>
    );
  }
}

const getPath = (name: string) => {
  switch (name) {
    case 'deleteInput':
      return (
        <path
          preserveAspectRatio="true"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99988 1C4.14988 1 0.999878 4.15 0.999878 8C0.999878 11.85 4.14988 15 7.99988 15C11.8499 15 14.9999 11.85 14.9999 8C14.9999 4.15 11.8499 1 7.99988 1ZM11.4999 10.52L10.5199 11.5L7.99992 8.98001L5.47992 11.5L4.49992 10.52L7.01992 8.00001L4.49992 5.48001L5.47992 4.50001L7.99992 7.02001L10.5199 4.50001L11.4999 5.48001L8.97992 8.00001L11.4999 10.52Z"
          fill="#86A2B3"
        />
      );
    case 'magnifyingGlassSmall':
      return (
        <path d="M12.7828 11.2834C12.6018 11.102 12.3559 11 12.0996 11H11.8265C11.7517 11 11.6799 10.971 11.6262 10.9192C11.5161 10.813 11.5085 10.6397 11.6033 10.5197C12.4791 9.4107 13 8.01273 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.01273 13 9.4107 12.4791 10.5197 11.6033C10.6397 11.5085 10.813 11.5161 10.9192 11.6262C10.971 11.6799 11 11.7517 11 11.8265V12.0996C11 12.3559 11.102 12.6018 11.2834 12.7828L15.2543 16.7457C15.6662 17.1569 16.3334 17.1566 16.745 16.745C17.1566 16.3334 17.1569 15.6662 16.7457 15.2543L12.7828 11.2834ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z" />
      );
    case 'alert':
      return (
        <path d="M19.8431 29.5C20.9216 29.5 21.8039 28.6 21.8039 27.5H17.8824C17.8824 28.6 18.7647 29.5 19.8431 29.5ZM25.7255 23.5V18.5C25.7255 15.43 24.1275 12.86 21.3137 12.18V11.5C21.3137 10.67 20.6569 10 19.8431 10C19.0294 10 18.3725 10.67 18.3725 11.5V12.18C15.5686 12.86 13.9608 15.42 13.9608 18.5V23.5L12 25.5V26.5H27.6863V25.5L25.7255 23.5ZM23.7647 24.5H15.9216V18.5C15.9216 16.02 17.402 14 19.8431 14C22.2843 14 23.7647 16.02 23.7647 18.5V24.5Z" />
      );
        case "measureM":
      return (
        <g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32 13.8182L26.1818 8L8 26.1818L13.8182 32L32 13.8182ZM26.9059 16.0032L24.7273 18.1818L23.066 16.5206L22.3388 17.2479L24 18.9091L21.815 21.0941L20.3604 19.6396L19.6332 20.3668L21.0877 21.8214L18.9091 24L17.2479 22.3388L16.5206 23.066L18.1818 24.7273L15.9968 26.9123L14.5423 25.4577L13.815 26.185L15.2695 27.6396L13.8182 29.0909L10.9091 26.1818L26.1818 10.9091L29.0909 13.8182L27.6332 15.2759L26.1786 13.8214L25.4513 14.5487L26.9059 16.0032Z" fill="#0B4566"/>
      <path d="M23.0078 24.6035L23.042 25.4238C23.5843 24.7858 24.3158 24.4668 25.2363 24.4668C26.2708 24.4668 26.9749 24.8633 27.3486 25.6562C27.5947 25.3008 27.9137 25.0137 28.3057 24.7949C28.7021 24.5762 29.1693 24.4668 29.707 24.4668C31.3294 24.4668 32.1543 25.3258 32.1816 27.0439V32H30.917V27.1191C30.917 26.5905 30.7962 26.1963 30.5547 25.9365C30.3132 25.6722 29.9076 25.54 29.3379 25.54C28.8685 25.54 28.4788 25.6813 28.1689 25.9639C27.859 26.2419 27.679 26.6178 27.6289 27.0918V32H26.3574V27.1533C26.3574 26.0778 25.8311 25.54 24.7783 25.54C23.9489 25.54 23.3815 25.8932 23.0762 26.5996V32H21.8115V24.6035H23.0078Z" fill="#0B4566"/>
        </g>);
      case "measureFt":
      return (
      <g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M32 13.8182L26.1818 8L8 26.1818L13.8182 32L32 13.8182ZM26.9059 16.0032L24.7273 18.1818L23.066 16.5206L22.3388 17.2479L24 18.9091L21.815 21.0941L20.3604 19.6396L19.6332 20.3668L21.0877 21.8214L18.9091 24L17.2479 22.3388L16.5206 23.066L18.1818 24.7273L15.9968 26.9123L14.5423 25.4577L13.815 26.185L15.2695 27.6396L13.8182 29.0909L10.9091 26.1818L26.1818 10.9091L29.0909 13.8182L27.6332 15.2759L26.1786 13.8214L25.4513 14.5487L26.9059 16.0032Z" fill="#0B4566"/>
        <path d="M23.8555 32V25.5811H22.6865V24.6035H23.8555V23.8447C23.8555 23.0518 24.0674 22.4388 24.4912 22.0059C24.915 21.5729 25.5143 21.3564 26.2891 21.3564C26.5807 21.3564 26.8701 21.3952 27.1572 21.4727L27.0889 22.498C26.8747 22.457 26.6468 22.4365 26.4053 22.4365C25.9951 22.4365 25.6784 22.5573 25.4551 22.7988C25.2318 23.0358 25.1201 23.3776 25.1201 23.8242V24.6035H26.6992V25.5811H25.1201V32H23.8555Z" fill="#0B4566"/>
        <path d="M29.8164 22.8125V24.6035H31.1973V25.5811H29.8164V30.168C29.8164 30.4642 29.8779 30.6875 30.001 30.8379C30.124 30.9837 30.3337 31.0566 30.6299 31.0566C30.7757 31.0566 30.9762 31.0293 31.2314 30.9746V32C30.8988 32.0911 30.5752 32.1367 30.2607 32.1367C29.6956 32.1367 29.2695 31.9658 28.9824 31.624C28.6953 31.2822 28.5518 30.7969 28.5518 30.168V25.5811H27.2051V24.6035H28.5518V22.8125H29.8164Z" fill="#0B4566"/>
      </g>
        );
    case 'menu':
      return (
        <g>
          <path d="M11 26H29V24H11V26ZM11 21H29V19H11V21ZM11 14V16H29V14H11Z" />
        </g>
      );
    case 'expand':
      return (
        <g>
          <path d="M15 18H13V13H18V15H15V18Z" />
          <path d="M13 22H15V25H18V27H13V22Z" />
          <path d="M25 25H22V27H27V22H25V25Z" />
          <path d="M22 15V13H27V18H25V15H22Z" />
        </g>
      );
    case 'minimize':
      return (
        <path d="M13 24H16V27H18V22H13V24ZM16 16H13V18H18V13H16V16ZM22 27H24V24H27V22H22V27ZM24 16V13H22V18H27V16H24Z" />
      );
    case 'up':
      return <path d="M24.59 23L20 18.42L15.41 23L14 21.59L20 15.59L26 21.59L24.59 23Z" />;
    case 'down':
      return <path d="M15.41 16L20 20.58L24.59 16L26 17.41L20 23.41L14 17.41L15.41 16Z" />;
    case 'left':
      return <path d="M23 15.41L18.42 20L23 24.59L21.59 26L15.59 20L21.59 14L23 15.41Z" />;
    case 'right':
      return <path d="M17 24.59L21.58 20L17 15.41L18.41 14L24.41 20L18.41 26L17 24.59Z" />;
    case 'puzzle':
      return (
        <path d="M18.5 12C18.78 12 19 12.22 19 12.5V14.5H25V20.5H27C27.28 20.5 27.5 20.72 27.5 21C27.5 21.28 27.28 21.5 27 21.5H25V27.5H22.88C22.2 25.75 20.49 24.5 18.5 24.5C16.51 24.5 14.8 25.75 14.12 27.5H12V25.38C13.75 24.7 15 22.99 15 21C15 19.01 13.76 17.3 12.01 16.62L12 14.5H18V12.5C18 12.22 18.22 12 18.5 12ZM18.5 10C17.12 10 16 11.12 16 12.5H12C10.9 12.5 10.01 13.4 10.01 14.5V18.3H10.3C11.79 18.3 13 19.51 13 21C13 22.49 11.79 23.7 10.3 23.7H10V27.5C10 28.6 10.9 29.5 12 29.5H15.8V29.2C15.8 27.71 17.01 26.5 18.5 26.5C19.99 26.5 21.2 27.71 21.2 29.2V29.5H25C26.1 29.5 27 28.6 27 27.5V23.5C28.38 23.5 29.5 22.38 29.5 21C29.5 19.62 28.38 18.5 27 18.5V14.5C27 13.4 26.1 12.5 25 12.5H21C21 11.12 19.88 10 18.5 10Z" />
      );
    case 'duplicate':
      return (
        <g>
          <path d="M12 9H24V11H12V25H10V11C10 9.89999 10.9 9 12 9Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 13H23L29 19V29C29 30.1 28.1 31 27 31H15.99C14.89 31 14 30.1 14 29L14.01 15C14.01 13.9 14.9 13 16 13ZM16 15V29H27V20H22V15H16Z"
          />
        </g>
      );
    case 'water':
      return (
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.6037 20.3273L15.8638 21L15.1238 20.3273L15.8638 19.5134L16.6037 20.3273ZM15.8638 22.5583C15.6534 22.825 15.4182 23.1363 15.1839 23.4722C14.8264 23.9846 14.4844 24.5351 14.2354 25.0581C13.9773 25.6001 13.8638 26.021 13.8638 26.3C13.8638 27.4262 14.7943 28.3 15.8638 28.3C16.9115 28.3 17.8638 27.3477 17.8638 26.3C17.8638 26.021 17.7503 25.6001 17.4922 25.0581C17.2431 24.5351 16.9012 23.9846 16.5436 23.4722C16.3093 23.1363 16.0741 22.825 15.8638 22.5583ZM15.8638 21C15.1238 20.3273 15.1238 20.3273 15.1238 20.3273L15.1201 20.3315L15.112 20.3404L15.0835 20.3723C15.0592 20.3995 15.0245 20.4387 14.9809 20.4888C14.8938 20.5887 14.7708 20.7322 14.6239 20.9102C14.3309 21.265 13.9384 21.7621 13.5436 22.3278C13.1512 22.8904 12.7431 23.5399 12.4297 24.1982C12.1253 24.8374 11.8638 25.579 11.8638 26.3C11.8638 28.5738 13.7333 30.3 15.8638 30.3C18.0161 30.3 19.8638 28.4523 19.8638 26.3C19.8638 25.579 19.6023 24.8374 19.2979 24.1982C18.9844 23.5399 18.5764 22.8904 18.1839 22.3278C17.7892 21.7621 17.3966 21.265 17.1036 20.9102C16.9567 20.7322 16.8337 20.5887 16.7466 20.4888C16.703 20.4387 16.6683 20.3995 16.6441 20.3723L16.6156 20.3404L16.6075 20.3315L16.6037 20.3273C16.6037 20.3273 16.6037 20.3273 15.8638 21Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.0071 10.331L24.2638 11L23.5205 10.331L24.2638 9.50516L25.0071 10.331ZM24.2638 12.5482C23.8627 13.0419 23.3484 13.7041 22.836 14.4442C22.2907 15.2319 21.7608 16.0889 21.3707 16.9125C20.9714 17.7554 20.7638 18.4704 20.7638 19C20.7638 20.9477 22.3161 22.5 24.2638 22.5C26.2115 22.5 27.7638 20.9477 27.7638 19C27.7638 18.4704 27.5562 17.7554 27.1569 16.9125C26.7668 16.0889 26.2369 15.2319 25.6916 14.4442C25.1792 13.7041 24.6649 13.0419 24.2638 12.5482ZM28.9644 16.0563C29.4089 16.9946 29.7638 18.0296 29.7638 19C29.7638 22.0523 27.3161 24.5 24.2638 24.5C21.2115 24.5 18.7638 22.0523 18.7638 19C18.7638 18.0296 19.1187 16.9946 19.5632 16.0563C20.0168 15.0986 20.6119 14.1431 21.1916 13.3058C21.7735 12.4653 22.3533 11.7246 22.7867 11.1949C23.0039 10.9295 23.1853 10.7157 23.3134 10.5674C23.3774 10.4931 23.4282 10.4352 23.4634 10.3953L23.5044 10.349L23.5157 10.3364L23.5205 10.331C23.5205 10.331 23.5205 10.331 24.2638 11C25.0071 10.331 25.0071 10.331 25.0071 10.331L25.0119 10.3364L25.0232 10.349L25.0642 10.3953C25.0994 10.4352 25.1502 10.4931 25.2142 10.5674C25.3423 10.7157 25.5237 10.9295 25.7409 11.1949C26.1743 11.7246 26.7541 12.4653 27.336 13.3058C27.9157 14.1431 28.5108 15.0986 28.9644 16.0563Z"
          />
        </g>
      );
    case 'learning':
      return (
        <path d="M20 12L9 18L13 20.18V26.18L20 30L27 26.18V20.18L29 19.09V26H31V18L20 12ZM26.82 18L20 21.72L13.18 18L20 14.28L26.82 18ZM25 24.99L20 27.72L15 24.99V21.27L20 24L25 21.27V24.99Z" />
      );
    case 'uploadcloud':
      return (
        <g>
          <path d="M27.35 18.04C26.67 14.59 23.64 12 20 12C17.11 12 14.6 13.64 13.35 16.04C10.34 16.36 8 18.91 8 22C8 25.31 10.69 28 14 28H27C29.76 28 32 25.76 32 23C32 20.36 29.95 18.22 27.35 18.04ZM27 26H14C11.79 26 10 24.21 10 22C10 19.95 11.53 18.24 13.56 18.03L14.63 17.92L15.13 16.97C16.08 15.14 17.94 14 20 14C22.62 14 24.88 15.86 25.39 18.43L25.69 19.93L27.22 20.04C28.78 20.14 30 21.45 30 23C30 24.65 28.65 26 27 26Z" />
          <path d="M16 21H18.55V24H21.45V21H24L20 17L16 21Z" />
        </g>
      );
    case 'closedfolder':
      return (
        <path d="M17.17 14L19.17 16H28V26H12V14H17.17ZM18 12H12C10.9 12 10.01 12.9 10.01 14L10 26C10 27.1 10.9 28 12 28H28C29.1 28 30 27.1 30 26V16C30 14.9 29.1 14 28 14H20L18 12Z" />
      );
    case 'openfolder':
      return (
        <path d="M28 14H20L18 12H12C10.9 12 10.01 12.9 10.01 14L10 26C10 27.1 10.9 28 12 28H28C29.1 28 30 27.1 30 26V16C30 14.9 29.1 14 28 14ZM28 26H12V16H28V26Z" />
      );
    case 'zoomin':
      return (
        <g>
          <path d="M17 17V15H18V17H20V18H18V20H17V18H15V17H17Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.71 22H23.5L28.49 27L27 28.49L22 23.5V22.71L21.73 22.43C20.59 23.41 19.11 24 17.5 24C13.91 24 11 21.09 11 17.5C11 13.91 13.91 11 17.5 11C21.09 11 24 13.91 24 17.5C24 19.11 23.41 20.59 22.43 21.73L22.71 22ZM13 17.5C13 19.99 15.01 22 17.5 22C19.99 22 22 19.99 22 17.5C22 15.01 19.99 13 17.5 13C15.01 13 13 15.01 13 17.5Z"
          />
        </g>
      );
    case 'zoomout':
      return (
        <g>
          <path d="M23.5 22H22.71L22.43 21.73C23.41 20.59 24 19.11 24 17.5C24 13.91 21.09 11 17.5 11C13.91 11 11 13.91 11 17.5C11 21.09 13.91 24 17.5 24C19.11 24 20.59 23.41 21.73 22.43L22 22.71V23.5L27 28.49L28.49 27L23.5 22ZM17.5 22C15.01 22 13 19.99 13 17.5C13 15.01 15.01 13 17.5 13C19.99 13 22 15.01 22 17.5C22 19.99 19.99 22 17.5 22Z" />
          <path d="M20 17H15V18H20V17Z" />
        </g>
      );
    case 'share':
      return (
        <g>
          <path d="M26 24.08C25.24 24.08 24.56 24.38 24.04 24.85L16.91 20.7C16.96 20.47 17 20.24 17 20C17 19.76 16.96 19.53 16.91 19.3L23.96 15.19C24.5 15.69 25.21 16 26 16C27.66 16 29 14.66 29 13C29 11.34 27.66 10 26 10C24.34 10 23 11.34 23 13C23 13.24 23.04 13.47 23.09 13.7L16.04 17.81C15.5 17.31 14.79 17 14 17C12.34 17 11 18.34 11 20C11 21.66 12.34 23 14 23C14.79 23 15.5 22.69 16.04 22.19L23.16 26.35C23.11 26.56 23.08 26.78 23.08 27C23.08 28.61 24.39 29.92 26 29.92C27.61 29.92 28.92 28.61 28.92 27C28.92 25.39 27.61 24.08 26 24.08ZM26 12C26.55 12 27 12.45 27 13C27 13.55 26.55 14 26 14C25.45 14 25 13.55 25 13C25 12.45 25.45 12 26 12ZM14 21C13.45 21 13 20.55 13 20C13 19.45 13.45 19 14 19C14.55 19 15 19.45 15 20C15 20.55 14.55 21 14 21ZM26 28.02C25.45 28.02 25 27.57 25 27.02C25 26.47 25.45 26.02 26 26.02C26.55 26.02 27 26.47 27 27.02C27 27.57 26.55 28.02 26 28.02Z" />
        </g>
      );
    case 'download':
      return (
        <g>
          <path d="M27 20V27H13V20H11V27C11 28.1 11.9 29 13 29H27C28.1 29 29 28.1 29 27V20H27ZM21 20.67L23.59 18.09L25 19.5L20 24.5L15 19.5L16.41 18.09L19 20.67V11H21V20.67Z" />
        </g>
      );
    case 'rename':
      return (
        <g>
          <path d="M22.06 16.02L22.98 16.94L13.92 26H13V25.08L22.06 16.02ZM25.66 10C25.41 10 25.15 10.1 24.96 10.29L23.13 12.12L26.88 15.87L28.71 14.04C29.1 13.65 29.1 13.02 28.71 12.63L26.37 10.29C26.17 10.09 25.92 10 25.66 10ZM22.06 13.19L11 24.25V28H14.75L25.81 16.94L22.06 13.19Z" />
        </g>
      );
    case 'delete':
      return (
        <g>
          <path d="M24 17V27H16V17H24ZM22.5 11H17.5L16.5 12H13V14H27V12H23.5L22.5 11ZM26 15H14V27C14 28.1 14.9 29 16 29H24C25.1 29 26 28.1 26 27V15Z" />
          <path d="M18 18H19V26H18V18Z" />
          <path d="M21 18H22V26H21V18Z" />
        </g>
      );
    case 'document':
      return (
        <g>
          <path d="M21 17H26.5L21 11.5V17ZM14 10H22L28 16V28C28 28.5 27.8 29 27.4 29.4C27 29.8 26.5 30 26 30H14C12.9 30 12 29.1 12 28V12C12 10.9 12.9 10 14 10ZM19 12H14V28H19H26V19H19V12Z" />
        </g>
      );
    case 'data':
      return (
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 11.7V28.7C9 30.2 13.9 31.4 20 31.4C26.1 31.4 31 30.2 31 28.7V11.7H29V28.0984C28.6616 28.2703 28.1078 28.4747 27.3111 28.6701C25.5217 29.109 22.9396 29.4 20 29.4C17.0604 29.4 14.4783 29.109 12.6889 28.6701C11.8922 28.4747 11.3384 28.2703 11 28.0984V11.7H9ZM10.6956 27.908C10.6977 27.9078 10.7138 27.9194 10.7375 27.9436C10.7054 27.9204 10.6935 27.9083 10.6956 27.908ZM29.3044 27.908C29.3065 27.9083 29.2946 27.9204 29.2625 27.9436C29.2862 27.9194 29.3023 27.9078 29.3044 27.908Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.5005 18.1174C9.25464 17.8752 9 17.4989 9 17H11C11 16.7959 10.9018 16.6903 10.9018 16.6903C10.9018 16.6903 10.9025 16.691 10.904 16.6925C10.9258 16.714 11.0113 16.7828 11.2118 16.878C11.608 17.0662 12.246 17.2621 13.1164 17.4361C14.8408 17.7807 17.2719 18 20 18C22.7281 18 25.1592 17.7807 26.8836 17.4361C27.754 17.2621 28.392 17.0662 28.7882 16.878C28.9887 16.7828 29.0742 16.714 29.096 16.6925C29.0975 16.691 29.0982 16.6903 29.0982 16.6903C29.0982 16.6903 29 16.7959 29 17H31C31 17.4989 30.7454 17.8752 30.4995 18.1174C30.256 18.3572 29.9503 18.5402 29.6465 18.6845C29.0341 18.9754 28.2119 19.2101 27.2755 19.3973C25.3863 19.7749 22.8173 20 20 20C17.1827 20 14.6137 19.7749 12.7245 19.3973C11.7881 19.2101 10.9659 18.9754 10.3535 18.6845C10.0497 18.5402 9.74398 18.3572 9.5005 18.1174Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.5005 24.1174C9.25464 23.8752 9 23.4989 9 23H11C11 22.7959 10.9018 22.6903 10.9018 22.6903C10.9018 22.6903 10.9025 22.691 10.904 22.6925C10.9258 22.714 11.0113 22.7828 11.2118 22.878C11.608 23.0662 12.246 23.2621 13.1164 23.4361C14.8408 23.7807 17.2719 24 20 24C22.7281 24 25.1592 23.7807 26.8836 23.4361C27.754 23.2621 28.392 23.0662 28.7882 22.878C28.9887 22.7828 29.0742 22.714 29.096 22.6925C29.0975 22.691 29.0982 22.6903 29.0982 22.6903C29.0982 22.6903 29 22.7959 29 23H31C31 23.4989 30.7454 23.8752 30.4995 24.1174C30.256 24.3572 29.9503 24.5402 29.6465 24.6845C29.0341 24.9754 28.2119 25.2101 27.2755 25.3973C25.3863 25.7749 22.8173 26 20 26C17.1827 26 14.6137 25.7749 12.7245 25.3973C11.7881 25.2101 10.9659 24.9754 10.3535 24.6845C10.0497 24.5402 9.74398 24.3572 9.5005 24.1174Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.3089 10.8995C29.3111 10.8998 29.2987 10.9124 29.2652 10.9365C29.2899 10.9113 29.3067 10.8993 29.3089 10.8995ZM27.1637 11.7C25.3768 11.2783 22.8514 11 20 11C17.1486 11 14.6232 11.2783 12.8363 11.7C14.6232 12.1217 17.1486 12.4 20 12.4C22.8514 12.4 25.3768 12.1217 27.1637 11.7ZM10.6911 10.8995C10.6933 10.8993 10.7101 10.9113 10.7348 10.9365C10.7012 10.9124 10.6889 10.8998 10.6911 10.8995ZM10.6911 12.5005C10.6889 12.5002 10.7012 12.4876 10.7348 12.4635C10.7101 12.4887 10.6933 12.5007 10.6911 12.5005ZM29.2652 12.4636C29.2988 12.4876 29.3111 12.5002 29.3089 12.5005C29.3067 12.5007 29.2899 12.4887 29.2652 12.4636ZM31 11.7C31 13.1912 26.0751 14.4 20 14.4C13.9249 14.4 9 13.1912 9 11.7C9 10.2088 13.9249 9 20 9C26.0751 9 31 10.2088 31 11.7Z"
          />
        </g>
      );
    case 'settings':
      return (
        <g>
          <path d="M27.43 20.98C27.47 20.66 27.5 20.34 27.5 20C27.5 19.66 27.47 19.34 27.43 19.02L29.54 17.37C29.73 17.22 29.78 16.95 29.66 16.73L27.66 13.27C27.57 13.11 27.4 13.02 27.22 13.02C27.16 13.02 27.1 13.03 27.05 13.05L24.56 14.05C24.04 13.65 23.48 13.32 22.87 13.07L22.49 10.42C22.46 10.18 22.25 10 22 10H18C17.75 10 17.54 10.18 17.51 10.42L17.13 13.07C16.52 13.32 15.96 13.66 15.44 14.05L12.95 13.05C12.89 13.03 12.83 13.02 12.77 13.02C12.6 13.02 12.43 13.11 12.34 13.27L10.34 16.73C10.21 16.95 10.27 17.22 10.46 17.37L12.57 19.02C12.53 19.34 12.5 19.67 12.5 20C12.5 20.33 12.53 20.66 12.57 20.98L10.46 22.63C10.27 22.78 10.22 23.05 10.34 23.27L12.34 26.73C12.43 26.89 12.6 26.98 12.78 26.98C12.84 26.98 12.9 26.97 12.95 26.95L15.44 25.95C15.96 26.35 16.52 26.68 17.13 26.93L17.51 29.58C17.54 29.82 17.75 30 18 30H22C22.25 30 22.46 29.82 22.49 29.58L22.87 26.93C23.48 26.68 24.04 26.34 24.56 25.95L27.05 26.95C27.11 26.97 27.17 26.98 27.23 26.98C27.4 26.98 27.57 26.89 27.66 26.73L29.66 23.27C29.78 23.05 29.73 22.78 29.54 22.63L27.43 20.98ZM25.45 19.27C25.49 19.58 25.5 19.79 25.5 20C25.5 20.21 25.48 20.43 25.45 20.73L25.31 21.86L26.2 22.56L27.28 23.4L26.58 24.61L25.31 24.1L24.27 23.68L23.37 24.36C22.94 24.68 22.53 24.92 22.12 25.09L21.06 25.52L20.9 26.65L20.7 28H19.3L18.95 25.52L17.89 25.09C17.46 24.91 17.06 24.68 16.66 24.38L15.75 23.68L14.69 24.11L13.42 24.62L12.72 23.41L13.8 22.57L14.69 21.87L14.55 20.74C14.52 20.43 14.5 20.2 14.5 20C14.5 19.8 14.52 19.57 14.55 19.27L14.69 18.14L13.8 17.44L12.72 16.6L13.42 15.39L14.69 15.9L15.73 16.32L16.63 15.64C17.06 15.32 17.47 15.08 17.88 14.91L18.94 14.48L19.1 13.35L19.3 12H20.69L21.04 14.48L22.1 14.91C22.53 15.09 22.93 15.32 23.33 15.62L24.24 16.32L25.3 15.89L26.57 15.38L27.27 16.59L26.2 17.44L25.31 18.14L25.45 19.27Z" />
          <path d="M20 16C17.79 16 16 17.79 16 20C16 22.21 17.79 24 20 24C22.21 24 24 22.21 24 20C24 17.79 22.21 16 20 16ZM20 22C18.9 22 18 21.1 18 20C18 18.9 18.9 18 20 18C21.1 18 22 18.9 22 20C22 21.1 21.1 22 20 22Z" />
        </g>
      );
    case 'language':
      return (
        <g>
          <path d="M19.99 10C14.47 10 10 14.48 10 20C10 25.52 14.47 30 19.99 30C25.52 30 30 25.52 30 20C30 14.48 25.52 10 19.99 10ZM26.92 16H23.97C23.65 14.75 23.19 13.55 22.59 12.44C24.43 13.07 25.96 14.35 26.92 16ZM20 12.04C20.83 13.24 21.48 14.57 21.91 16H18.09C18.52 14.57 19.17 13.24 20 12.04ZM12.26 22C12.1 21.36 12 20.69 12 20C12 19.31 12.1 18.64 12.26 18H15.64C15.56 18.66 15.5 19.32 15.5 20C15.5 20.68 15.56 21.34 15.64 22H12.26ZM13.08 24H16.03C16.35 25.25 16.81 26.45 17.41 27.56C15.57 26.93 14.04 25.66 13.08 24ZM16.03 16H13.08C14.04 14.34 15.57 13.07 17.41 12.44C16.81 13.55 16.35 14.75 16.03 16ZM20 27.96C19.17 26.76 18.52 25.43 18.09 24H21.91C21.48 25.43 20.83 26.76 20 27.96ZM22.34 22H17.66C17.57 21.34 17.5 20.68 17.5 20C17.5 19.32 17.57 18.65 17.66 18H22.34C22.43 18.65 22.5 19.32 22.5 20C22.5 20.68 22.43 21.34 22.34 22ZM22.59 27.56C23.19 26.45 23.65 25.25 23.97 24H26.92C25.96 25.65 24.43 26.93 22.59 27.56ZM24.36 22C24.44 21.34 24.5 20.68 24.5 20C24.5 19.32 24.44 18.66 24.36 18H27.74C27.9 18.64 28 19.31 28 20C28 20.69 27.9 21.36 27.74 22H24.36Z" />
        </g>
      );
    case 'center':
      return (
        <g>
          <path d="M20 16C17.79 16 16 17.79 16 20C16 22.21 17.79 24 20 24C22.21 24 24 22.21 24 20C24 17.79 22.21 16 20 16ZM28.94 19C28.48 14.83 25.17 11.52 21 11.06V9H19V11.06C14.83 11.52 11.52 14.83 11.06 19H9V21H11.06C11.52 25.17 14.83 28.48 19 28.94V31H21V28.94C25.17 28.48 28.48 25.17 28.94 21H31V19H28.94ZM20 27C16.13 27 13 23.87 13 20C13 16.13 16.13 13 20 13C23.87 13 27 16.13 27 20C27 23.87 23.87 27 20 27Z" />
        </g>
      );
    case 'move':
      return (
        <g>
          <path d="M26 32H19.45C18.37 32 17.31 31.55 16.56 30.77L9.26 23.16L11.33 21.33C11.95 20.78 12.86 20.67 13.59 21.06L16 22.34V12.79C16 11.41 17.12 10.29 18.5 10.29C18.67 10.29 18.84 10.31 19.01 10.34C19.1 9.04001 20.18 8.01001 21.5 8.01001C22.36 8.01001 23.11 8.44001 23.56 9.10001C23.85 8.98001 24.17 8.92001 24.5 8.92001C25.88 8.92001 27 10.04 27 11.42V11.7C27.16 11.67 27.33 11.65 27.5 11.65C28.88 11.65 30 12.77 30 14.15V28C30 30.21 28.21 32 26 32ZM12.14 23.28L18 29.38C18.38 29.77 18.9 30 19.44 30H26C27.1 30 28 29.1 28 28V14.15C28 13.87 27.78 13.65 27.5 13.65C27.22 13.65 27 13.87 27 14.15V20H25V11.42C25 11.14 24.78 10.92 24.5 10.92C24.22 10.92 24 11.14 24 11.42V20H22V10.51C22 10.23 21.78 10.01 21.5 10.01C21.22 10.01 21 10.23 21 10.51V20H19V12.79C19 12.51 18.78 12.29 18.5 12.29C18.22 12.29 18 12.52 18 12.79V25.66L12.65 22.83L12.14 23.28Z" />
        </g>
      );
    case 'minusZoomout':
      return <rect width="16" height="2" transform="translate(12 19)" />;
    case 'addZoomin':
      return (
        <g>
          <rect width="16" height="2" transform="translate(12 19)" />
          <rect width="16" height="2" transform="translate(19 28) rotate(-90)" />
        </g>
      );
    case 'magnifyingGlass':
      return (
        <g>
          <path
            d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
            transform="translate(11 11)"
          />
        </g>
      );
    case 'checkmark':
      return <path d="M17 24.2L12.8 20L11.4 21.4L17 27L29 15L27.6 13.6L17 24.2Z" />;
    case 'reorder':
      return (
        <g>
          <path d="M24 25.01V18H22V25.01H19L23 29L27 25.01H24ZM17 11L13 14.99H16V22H18V14.99H21L17 11Z" />
        </g>
      );
    case 'play':
      return (
        <g>
          <path d="M18 16.64L23.27 20L18 23.36V16.64ZM16 13V27L27 20L16 13Z" />
          <path d="M20 8C13.376 8 8 13.376 8 20C8 26.624 13.376 32 20 32C26.624 32 32 26.624 32 20C32 13.376 26.624 8 20 8ZM20 29.6C14.708 29.6 10.4 25.292 10.4 20C10.4 14.708 14.708 10.4 20 10.4C25.292 10.4 29.6 14.708 29.6 20C29.6 25.292 25.292 29.6 20 29.6Z" />
        </g>
      );
    case 'pause':
      return (
        <g>
          <path d="M16.4 24.8H18.8V15.2H16.4V24.8ZM20 8C13.376 8 8 13.376 8 20C8 26.624 13.376 32 20 32C26.624 32 32 26.624 32 20C32 13.376 26.624 8 20 8ZM20 29.6C14.708 29.6 10.4 25.292 10.4 20C10.4 14.708 14.708 10.4 20 10.4C25.292 10.4 29.6 14.708 29.6 20C29.6 25.292 25.292 29.6 20 29.6ZM21.2 24.8H23.6V15.2H21.2V24.8Z" />
        </g>
      );
    case 'visible':
      return (
        <g>
          <path d="M20 14C23.79 14 27.17 16.13 28.82 19.5C27.17 22.87 23.79 25 20 25C16.21 25 12.83 22.87 11.18 19.5C12.83 16.13 16.21 14 20 14ZM20 12C15 12 10.73 15.11 9 19.5C10.73 23.89 15 27 20 27C25 27 29.27 23.89 31 19.5C29.27 15.11 25 12 20 12Z" />
          <path d="M20 17C21.38 17 22.5 18.12 22.5 19.5C22.5 20.88 21.38 22 20 22C18.62 22 17.5 20.88 17.5 19.5C17.5 18.12 18.62 17 20 17ZM20 15C17.52 15 15.5 17.02 15.5 19.5C15.5 21.98 17.52 24 20 24C22.48 24 24.5 21.98 24.5 19.5C24.5 17.02 22.48 15 20 15Z" />
        </g>
      );
    case 'hidden':
      return (
        <g>
          <path d="M20 14C23.79 14 27.17 16.13 28.82 19.5C28.23 20.72 27.4 21.77 26.41 22.62L27.82 24.03C29.21 22.8 30.31 21.26 31 19.5C29.27 15.11 25 12 20 12C18.73 12 17.51 12.2 16.36 12.57L18.01 14.22C18.66 14.09 19.32 14 20 14Z" />
          <path d="M18.93 15.14L21 17.21C21.57 17.46 22.03 17.92 22.28 18.49L24.35 20.56C24.43 20.22 24.49 19.86 24.49 19.49C24.5 17.01 22.48 15 20 15C19.63 15 19.28 15.05 18.93 15.14Z" />
          <path d="M10.01 11.87L12.69 14.55C11.06 15.83 9.77 17.53 9 19.5C10.73 23.89 15 27 20 27C21.52 27 22.98 26.71 24.32 26.18L27.74 29.6L29.15 28.19L11.42 10.45L10.01 11.87ZM17.51 19.37L20.12 21.98C20.08 21.99 20.04 22 20 22C18.62 22 17.5 20.88 17.5 19.5C17.5 19.45 17.51 19.42 17.51 19.37ZM14.11 15.97L15.86 17.72C15.63 18.27 15.5 18.87 15.5 19.5C15.5 21.98 17.52 24 20 24C20.63 24 21.23 23.87 21.77 23.64L22.75 24.62C21.87 24.86 20.95 25 20 25C16.21 25 12.83 22.87 11.18 19.5C11.88 18.07 12.9 16.89 14.11 15.97Z" />
        </g>
      );
    case 'close':
      return (
        <g>
          <path
            d="M14 1.4L12.6 0L7 5.6L1.4 0L0 1.4L5.6 7L0 12.6L1.4 14L7 8.4L12.6 14L14 12.6L8.4 7L14 1.4Z"
            transform="translate(13 13)"
          />
        </g>
      );
    case 'select':
      return (
        <g>
          <path d="M13.73 14.73L17.97 24.86L19.29 21.44L19.61 20.61L20.43 20.29L23.86 18.96L13.73 14.73ZM10 11L28 18.53V19.51L21.16 22.16L18.52 29H17.54L10 11Z" />
        </g>
      );
    case 'move1':
      return (
        <g>
          <path d="M21 26V19H19V26H16L20 30L24 26H21Z" />
          <path d="M19 12V19H21V12H24L20 8L16 12H19Z" />
          <path d="M13 20H20V18H13V15L9 19L13 23V20Z" />
          <path d="M27 18H20V20H27V23L31 19L27 15V18Z" />
        </g>
      );
    case 'ditches':
      return (
        <g>
          <path d="M27.1 11l-.6 4.6c-.4.2-.6.5-.9.8-.5.5-.9.9-1.6.9-.7 0-1.2-.4-1.6-.9-.5-.5-1.1-1.1-2.4-1.1-1.3 0-1.8.6-2.4 1.1-.4.4-.8.8-1.6.8s-1.2-.4-1.6-.8c-.3-.3-.6-.6-.9-.8l-.6-4.6H8v2h3.1l.9 7.1v.1l1.1 8.8h13.8l1.1-8.8v-.1l.9-7.1H32v-2h-4.9zm-13.5 6.1c.6.5 1.2 1.1 2.4 1.1s1.8-.6 2.4-1.1c.5-.5.9-.9 1.6-.9.7 0 1.2.4 1.6.9.5.5 1.1 1.1 2.4 1.1 1.3 0 1.8-.6 2.4-1.1L26 20l-.3.3c-.5.5-.9.9-1.6.9-.7 0-1.2-.4-1.6-.9-.5-.5-1.1-1.1-2.4-1.1-1.3 0-1.8.6-2.4 1.1-.5.5-.9.9-1.7.9-.8 0-1.2-.4-1.6-.8L14 20l-.4-2.9zm1.3 9.9l-.7-5.4c.4.3 1 .6 1.8.6 1.2 0 1.8-.6 2.4-1.1.5-.5.9-.9 1.6-.9.7 0 1.2.4 1.6.9.5.5 1.1 1.1 2.4 1.1.8 0 1.4-.3 1.8-.6l-.7 5.4H14.9z" />
        </g>
      );
    case 'dikes':
      return (
        <g>
          <path d="M17.6 17.1c.5.5 1.1 1.1 2.4 1.1 1.3 0 1.8-.6 2.4-1.1.5-.5.9-.9 1.6-.9.7 0 1.2.4 1.6.9.5.5 1.1 1.1 2.4 1.1 1.3 0 1.8-.6 2.4-1.1.5-.5.9-.9 1.6-.9v-1c-1.2 0-1.8.6-2.4 1.1-.5.5-.9.9-1.6.9-.7 0-1.2-.4-1.6-.9-.5-.5-1.1-1.1-2.4-1.1-1.3 0-1.8.6-2.4 1.1-.5.5-.9.9-1.6.9-.7 0-1.2-.4-1.6-.9-.4-.3-.8-.7-1.4-.9V11h-6v16H8v2h5V13h2v16h17v-2H17v-6.5c.2.1.4.3.6.6.5.5 1.1 1.1 2.4 1.1 1.3 0 1.8-.6 2.4-1.1.5-.5.9-.9 1.6-.9.7 0 1.2.4 1.6.9.5.5 1.1 1.1 2.4 1.1 1.3 0 1.8-.6 2.4-1.1.5-.5.9-.9 1.6-.9v-1c-1.2 0-1.8.6-2.4 1.1-.5.5-.9.9-1.6.9-.7 0-1.2-.4-1.6-.9-.5-.5-1.1-1.1-2.4-1.1-1.3 0-1.8.6-2.4 1.1-.5.5-.9.9-1.6.9-.7 0-1.2-.4-1.6-.9-.4-.3-.8-.7-1.4-.9v-2.9c.2.1.4.3.6.6z" />
        </g>
      );
    case 'deleteLastPoint':
      return (
        <g>
          <path d="M22 21.41L18.41 25L17 23.59L20.59 20L17 16.41L18.41 15L22 18.59L25.59 15L27 16.41L23.41 20L27 23.59L25.59 25L22 21.41Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 11H30C31.1 11 32 11.9 32 13V27C32 28.1 31.1 29 30 29H15C14.31 29 13.77 28.64 13.41 28.11L8 20L13.41 11.88C13.77 11.35 14.31 11 15 11ZM15.07 27H30V13H15.06L10.4 20L15.07 27Z"
          />
        </g>
      );
    case 'draw':
      return (
        <g>
          <path d="M8 13C8 13.5523 8.44772 14 9 14H15.7858L7.58578 22.2L15.8929 30.5071C16.2834 30.8976 16.9166 30.8976 17.3071 30.5071C17.6976 30.1166 17.6976 29.4834 17.3071 29.0929L10.4142 22.2L20.6142 12H9C8.44772 12 8 12.4477 8 13Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.69 25.25L28.75 14.19L32.5 17.94L21.44 29H17.69V25.25ZM29.67 17.94L28.75 17.02L19.69 26.08V27H20.61L29.67 17.94Z"
          />
        </g>
      );
    case 'polygon':
      return (
        <g>
          <path d="M31.8 11.8L28.1 8 17 19.1v3.8h3.8l11-11.1zM19 19.9l9.1-9.1.9 1-9.1 9.1H19v-1z" />
          <path d="M30 26v-2.4c1.2-.4 2-1.5 2-2.8 0-1.7-1.3-3-3-3s-3 1.3-3 3c0 1.3.8 2.4 2 2.8V26c-.8.3-1.5 1-1.8 1.8H13.8c0-.1-.1-.2-.1-.3l3-3-1.4-1.4-3 3c-.4-.2-.8-.3-1.3-.3-1.7 0-3 1.3-3 3s1.3 3 3 3c1.3 0 2.4-.8 2.8-2h12.4c.4 1.2 1.5 2 2.8 2 1.7 0 3-1.3 3-3 0-1.3-.8-2.4-2-2.8zm-1-6.2c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm-18 10c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm18 0c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
        </g>
      );
    case 'info':
      return (
        <g>
          <path d="M21 17V15H19V17H21Z" /> <path d="M21 25V19H19V25H21Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 20C10 14.48 14.48 10 20 10C25.52 10 30 14.48 30 20C30 25.52 25.52 30 20 30C14.48 30 10 25.52 10 20ZM12 20C12 24.41 15.59 28 20 28C24.41 28 28 24.41 28 20C28 15.59 24.41 12 20 12C15.59 12 12 15.59 12 20Z"
          />
        </g>
      );
    case 'save':
      return (
        <g>
          <path d="M20 20C18.34 20 17 21.34 17 23C17 24.66 18.34 26 20 26C21.66 26 23 24.66 23 23C23 21.34 21.66 20 20 20Z" />
          <path d="M14 14H23V18H14V14Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25 11H13C11.89 11 11 11.9 11 13V27C11 28.1 11.89 29 13 29H27C28.1 29 29 28.1 29 27V15L25 11ZM13 27V13H24.17L27 15.83V27H13Z"
          />
        </g>
      );
    case 'special':
      return (
        <g>
          <path d="M30 17.24L22.81 16.62L20 10L17.19 16.63L10 17.24L15.46 21.97L13.82 29L20 25.27L26.18 29L24.55 21.97L30 17.24ZM20 23.4L16.24 25.67L17.24 21.39L13.92 18.51L18.3 18.13L20 14.1L21.71 18.14L26.09 18.52L22.77 21.4L23.77 25.68L20 23.4Z" />
        </g>
      );
    case 'rotateLeft':
      return (
        <g>
          <path d="M23.6 14.9C21.8 13.1 19.5 12.2 17.2 12.2V9L13 13.2L17.2 17.4V14.2C19 14.2 20.8 14.9 22.2 16.2C24.9 18.9 24.9 23.4 22.2 26.1C20.8 27.5 19 28.1 17.2 28.1V30.1C19.5 30.1 21.8 29.2 23.6 27.5C27.1 24.1 27.1 18.4 23.6 14.9Z" />
        </g>
      );
    case 'rotateRight':
      return (
        <g>
          <path d="M16.4001 14.9C18.2001 13.1 20.5001 12.2 22.8001 12.2V9L27.0001 13.2L22.8001 17.4V14.2C21.0001 14.2 19.2001 14.9 17.8001 16.2C15.1001 18.9 15.1001 23.4 17.8001 26.1C19.2001 27.5 21.0001 28.1 22.8001 28.1V30.1C20.5001 30.1 18.2001 29.2 16.4001 27.5C12.9001 24.1 12.9001 18.4 16.4001 14.9Z" />
        </g>
      );
    case 'tableView':
      return (
        <g>
          <path d="M27 11H12C10.9 11 10 11.9 10 13V27C10 28.1 10.9 29 12 29H27C28.1 29 29 28.1 29 27V13C29 11.9 28.1 11 27 11ZM27 13V16H12V13H27ZM22 27H17V18H22V27ZM12 18H15V27H12V18ZM24 27V18H27V27H24Z" />
        </g>
      );
    case 'mapView':
      return (
        <g>
          <path d="M25.9 25.4c-.3-.8-1-1.4-1.9-1.4h-1v-3c0-.6-.4-1-1-1h-6v-2h2c.6 0 1-.4 1-1v-2h2c1.1 0 2-.9 2-2v-.4c2.9 1.2 5 4.1 5 7.4 0 2.1-.8 4-2.1 5.4zM19 27.9c-3.9-.5-7-3.9-7-7.9 0-.6.1-1.2.2-1.8L17 23v1c0 1.1.9 2 2 2v1.9zM20 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10z" />
        </g>
      );
    case 'opacity':
      return (
        <g>
          <path d="M25.7 15.7L20 10L14.3 15.7C12.8 17.2 12 19.3 12 21.3C12 23.3 12.8 25.4 14.3 27C15.8 28.6 17.9 29.4 20 29.4C22.1 29.4 24.1 28.6 25.7 27C27.3 25.4 28 23.3 28 21.3C28 19.3 27.2 17.2 25.7 15.7ZM14 21.6C14 19.6 14.6 18.3 15.8 17.2L20 12.9L24.2 17.3C25.3 18.4 26 19.6 26 21.6H14Z" />
        </g>
      );
    case 'undo':
      return (
        <g>
          <path d="M20.5 16C17.85 16 15.45 16.99 13.6 18.6L10 15V24H19L15.38 20.38C16.77 19.22 18.54 18.5 20.5 18.5C24.04 18.5 27.05 20.81 28.1 24L30.47 23.22C29.08 19.03 25.15 16 20.5 16Z" />
        </g>
      );
    case 'redo':
      return (
        <g>
          <path d="M19.5 16C22.15 16 24.55 16.99 26.4 18.6L30 15V24H21L24.62 20.38C23.23 19.22 21.46 18.5 19.5 18.5C15.96 18.5 12.95 20.81 11.9 24L9.53 23.22C10.92 19.03 14.85 16 19.5 16Z" />
        </g>
      );
    case 'sortByAscending':
      return (
        <g>
          <path d="M11 26h6v-2h-6v2zm0-12v2h18v-2H11zm0 7h12v-2H11v2z" />
        </g>
      );
    case 'sortByDecending':
      return (
        <g>
          <path d="M23 14h6v2h-6v-2zM29 24v2H11v-2h18zM17 21v-2h12v2H17z" />
        </g>
      );
    case 'history':
      return (
        <g>
          <path d="M19 15H20.5V20.25L25 22.92L24.25 24.15L19 21V15Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 20C10 14.48 14.47 10 19.99 10C25.52 10 30 14.48 30 20C30 25.52 25.52 30 19.99 30C14.47 30 10 25.52 10 20ZM12 20C12 24.42 15.58 28 20 28C24.42 28 28 24.42 28 20C28 15.58 24.42 12 20 12C15.58 12 12 15.58 12 20Z"
          />
        </g>
      );
    case 'rotateDevice':
      return (
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.88 11.6368C8.12 13.3868 7.23999 15.6968 7.23999 17.9968H4L8.23999 22.2368L12.48 17.9968H9.23999C9.23999 16.2068 9.91998 14.4169 11.29 13.0468C14.02 10.3168 18.46 10.3168 21.19 13.0468C22.56 14.4169 23.24 16.2068 23.24 17.9968V18H16.5C15.1 18 14 19.1 14 20.5V28.5C14 29.9 15.1 31 16.5 31H33.5C34.9 31 36 29.9 36 28.5V20.5C36 19.1 34.9 18 33.5 18H25.24V17.9968C25.24 15.6968 24.36 13.3868 22.6 11.6368C19.09 8.11682 13.39 8.11682 9.88 11.6368ZM17 20V29H31V20H17ZM33.5 26C34.3 26 35 25.3 35 24.5C35 23.7 34.3 23 33.5 23C32.7 23 32 23.7 32 24.5C32 25.3 32.7 26 33.5 26Z"
          />
        </g>
      );
    case 'dropDownArrow':
      return (
        <g>
          <path fillRule="evenodd" clipRule="evenodd" d="M15 18L20 23L25 18H15Z" />
        </g>
      );
    case 'closeMini':
      return (
        <g>
          <path d="M26 15.4L24.6 14L20 18.6L15.4 14L14 15.4L18.6 20L14 24.6L15.4 26L20 21.4L24.6 26L26 24.6L21.4 20L26 15.4Z" />
        </g>
      );
    case 'upload':
      return (
        <g>
          <path d="M19 14.8V24.5H21V14.8L23.6 17.4L25 16L20 11L15 16L16.4 17.4L19 14.8Z" />
          <path d="M27 27V20H29V27C29 28.1 28.1 29 27 29H13C11.9 29 11 28.1 11 27V20H13V27H27Z" />
        </g>
      );
    case 'lock':
      return (
        <g>
          <path d="M26 16H25V14C25 11.2 22.8 9 20 9C17.2 9 15 11.2 15 14V16H14C12.9 16 12 16.9 12 18V28C12 29.1 12.9 30 14 30H26C27.1 30 28 29.1 28 28V18C28 16.9 27.1 16 26 16ZM17 14C17 12.3 18.3 11 20 11C21.7 11 23 12.3 23 14V16H17V14ZM26 28H14V18H26V28ZM20 25C21.1 25 22 24.1 22 23C22 21.9 21.1 21 20 21C18.9 21 18 21.9 18 23C18 24.1 18.9 25 20 25Z" />
        </g>
      );
    case 'block':
      return (
        <g>
          <path d="M20 10C14.48 10 10 14.48 10 20C10 25.52 14.48 30 20 30C25.52 30 30 25.52 30 20C30 14.48 25.52 10 20 10ZM12 20C12 15.58 15.58 12 20 12C21.85 12 23.55 12.63 24.9 13.69L13.69 24.9C12.63 23.55 12 21.85 12 20ZM20 28C18.15 28 16.45 27.37 15.1 26.31L26.31 15.1C27.37 16.45 28 18.15 28 20C28 24.42 24.42 28 20 28Z" />
        </g>
      );
    case 'subscription':
      return (
        <g>
          <path d="M14 10H26V12H14V10Z" /> <path d="M12 14H28V16H12V14Z" />
          <path d="M18 27.26V20.73L24 24L18 27.26Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 18H12C10.9 18 10 18.9 10 20V28C10 29.1 10.9 30 12 30H28C29.1 30 30 29.1 30 28V20C30 18.9 29.1 18 28 18ZM28 28H12V20H28V28Z"
          />
        </g>
      );
    case 'analyze':
      return (
        <g>
          <path d="M14.2857 20.8572H18V26H14.2857V20.8572Z" />
          <path d="M19.4286 13.4286H23.1429V26H19.4286V13.4286Z" />
          <path d="M24.5714 17.7142H28.2857V25.9999H24.5714V17.7142Z" />
          <path d="M10 28H30V30H10V28Z" />
          <path d="M10 30L10 10L12 10L12 30H10Z" />
        </g>
      );
    case 'reports':
      return (
        <g>
          <path d="M13 19.2H16V29H13V19.2ZM18.6 15H21.4V29H18.6V15ZM24.2 23H27V29H24.2V23Z" />
        </g>
      );
    case 'unlock':
      return (
        <g>
          <path d="M21 23C21 24.1 20.1 25 19 25C17.9 25 17 24.1 17 23C17 21.9 17.9 21 19 21C20.1 21 21 21.9 21 23Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 16H25C26.1 16 27 16.9 27 18V28C27 29.1 26.1 30 25 30H13C11.9 30 11 29.1 11 28V18C11 16.9 11.9 16 13 16H22V14C22 12.34 20.66 11 19 11C17.34 11 16 12.34 16 14H14C14 11.24 16.24 9 19 9C21.76 9 24 11.24 24 14V16ZM13 18V28H25V18H13Z"
          />
        </g>
      );
    case 'send':
      return (
        <g>
          <path d="M13.01 14.03L20.52 17.25L13 16.25L13.01 14.03ZM20.51 22.75L13 25.97V23.75L20.51 22.75ZM11.01 11L11 18L26 20L11 22L11.01 29L32 20L11.01 11Z" />
        </g>
      );
    case 'support':
      return (
        <g>
          <path d="M19 31.59V27.99C13.99 27.73 10 23.57 10 18.5C10 13.26 14.26 9 19.5 9C24.74 9 29 13.26 29 18.5C29 23.45 25.56 28.43 20.43 30.9L19 31.59ZM19.5 11C15.36 11 12 14.36 12 18.5C12 22.64 15.36 26 19.5 26H21V28.3C24.64 26 27 22.22 27 18.5C27 14.36 23.64 11 19.5 11Z" />
          <path d="M20.5 22.5H18.5V24.5H20.5V22.5Z" />
          <path d="M20.5 21H18.5C18.5 17.75 21.5 18 21.5 16C21.5 14.9 20.6 14 19.5 14C18.4 14 17.5 14.9 17.5 16H15.5C15.5 13.79 17.29 12 19.5 12C21.71 12 23.5 13.79 23.5 16C23.5 18.5 20.5 18.75 20.5 21Z" />
        </g>
      );
    case 'dashboard':
      return (
        <g>
          <path d="M27 13V15H23V13H27ZM17 13V19H13V13H17ZM27 21V27H23V21H27ZM17 25V27H13V25H17ZM29 11H21V17H29V11ZM19 11H11V21H19V11ZM29 19H21V29H29V19ZM19 23H11V29H19V23Z" />
        </g>
      );
    case 'home':
      return (
        <g>
          <path d="M20 13.7L25 18.2V20V26H23V22V20H21H19H17V22V26H15V20V18.2L20 13.7ZM20 11L10 20H13V28H19V22H21V28H27V20H30L20 11Z" />
        </g>
      );
    case 'invoice':
      return (
        <g>
          <path d="M20.48 18.9C18.21 18.31 17.48 17.7 17.48 16.75C17.48 15.66 18.49 14.9 20.18 14.9C21.96 14.9 22.62 15.75 22.68 17H24.89C24.82 15.28 23.77 13.7 21.68 13.19V11H18.68V13.16C16.74 13.58 15.18 14.84 15.18 16.77C15.18 19.08 17.09 20.23 19.88 20.9C22.38 21.5 22.88 22.38 22.88 23.31C22.88 24 22.39 25.1 20.18 25.1C18.12 25.1 17.31 24.18 17.2 23H15C15.12 25.19 16.76 26.42 18.68 26.83V29H21.68V26.85C23.63 26.48 25.18 25.35 25.18 23.3C25.18 20.46 22.75 19.49 20.48 18.9Z" />
        </g>
      );
    case 'calendar':
      return (
        <g>
          <mask id="path-1-inside-1">
            <path d="M28 11H27V9H25V11H15V9H13V11H12C10.9 11 10 11.9 10 13V29C10 30.1 10.9 31 12 31H28C29.1 31 30 30.1 30 29V13C30 11.9 29.1 11 28 11ZM28 29H12V18H28V29ZM28 16H12V13H28V16Z" />
            <path d="M15 21H17V23H15V21Z" /> <path d="M18 21H20V23H18V21Z" />
            <path d="M21 21H23V23H21V21Z" /> <path d="M18 24H20V26H18V24Z" />
            <path d="M21 24H23V26H21V24Z" /> <path d="M24 24H26V26H24V24Z" />
          </mask>
          <path d="M28 11H27V9H25V11H15V9H13V11H12C10.9 11 10 11.9 10 13V29C10 30.1 10.9 31 12 31H28C29.1 31 30 30.1 30 29V13C30 11.9 29.1 11 28 11ZM28 29H12V18H28V29ZM28 16H12V13H28V16Z" />
          <path d="M15 21H17V23H15V21Z" /> <path d="M18 21H20V23H18V21Z" />
          <path d="M21 21H23V23H21V21Z" /> <path d="M18 24H20V26H18V24Z" />
          <path d="M21 24H23V26H21V24Z" /> <path d="M24 24H26V26H24V24Z" />
          <path
            d="M27 11H26V12H27V11ZM27 9H28V8H27V9ZM25 9V8H24V9H25ZM25 11V12H26V11H25ZM15 11H14V12H15V11ZM15 9H16V8H15V9ZM13 9V8H12V9H13ZM13 11V12H14V11H13ZM28 29V30H29V29H28ZM12 29H11V30H12V29ZM12 18V17H11V18H12ZM28 18H29V17H28V18ZM28 16V17H29V16H28ZM12 16H11V17H12V16ZM12 13V12H11V13H12ZM28 13H29V12H28V13ZM15 21V20H14V21H15ZM17 21H18V20H17V21ZM17 23V24H18V23H17ZM15 23H14V24H15V23ZM18 21V20H17V21H18ZM20 21H21V20H20V21ZM20 23V24H21V23H20ZM18 23H17V24H18V23ZM21 21V20H20V21H21ZM23 21H24V20H23V21ZM23 23V24H24V23H23ZM21 23H20V24H21V23ZM18 24V23H17V24H18ZM20 24H21V23H20V24ZM20 26V27H21V26H20ZM18 26H17V27H18V26ZM21 24V23H20V24H21ZM23 24H24V23H23V24ZM23 26V27H24V26H23ZM21 26H20V27H21V26ZM24 24V23H23V24H24ZM26 24H27V23H26V24ZM26 26V27H27V26H26ZM24 26H23V27H24V26ZM28 10H27V12H28V10ZM28 11V9H26V11H28ZM27 8H25V10H27V8ZM24 9V11H26V9H24ZM25 10H15V12H25V10ZM16 11V9H14V11H16ZM15 8H13V10H15V8ZM12 9V11H14V9H12ZM13 10H12V12H13V10ZM12 10C10.3477 10 9 11.3477 9 13H11C11 12.4523 11.4523 12 12 12V10ZM9 13V29H11V13H9ZM9 29C9 30.6523 10.3477 32 12 32V30C11.4523 30 11 29.5477 11 29H9ZM12 32H28V30H12V32ZM28 32C29.6523 32 31 30.6523 31 29H29C29 29.5477 28.5477 30 28 30V32ZM31 29V13H29V29H31ZM31 13C31 11.3477 29.6523 10 28 10V12C28.5477 12 29 12.4523 29 13H31ZM28 28H12V30H28V28ZM13 29V18H11V29H13ZM12 19H28V17H12V19ZM27 18V29H29V18H27ZM28 15H12V17H28V15ZM13 16V13H11V16H13ZM27 13V16H29V13H27ZM15 22H17V20H15V22ZM16 21V23H18V21H16ZM17 22H15V24H17V22ZM16 23V21H14V23H16ZM18 22H20V20H18V22ZM19 21V23H21V21H19ZM20 22H18V24H20V22ZM19 23V21H17V23H19ZM21 22H23V20H21V22ZM22 21V23H24V21H22ZM23 22H21V24H23V22ZM22 23V21H20V23H22ZM18 25H20V23H18V25ZM19 24V26H21V24H19ZM20 25H18V27H20V25ZM19 26V24H17V26H19ZM21 25H23V23H21V25ZM22 24V26H24V24H22ZM23 25H21V27H23V25ZM22 26V24H20V26H22ZM24 25H26V23H24V25ZM25 24V26H27V24H25ZM26 25H24V27H26V25ZM25 26V24H23V26H25ZM12 14H28V12H12V14Z"
            mask="url(#path-1-inside-1)"
          />
        </g>
      );
    case 'listView':
      return (
        <g>
          <path d="M14 13h-2v2h2v-2zM14 17h-2v2h2v-2zM14 21h-2v2h2v-2zM28 13H16v2h12v-2zM28 17H16v2h12v-2zM28 21H16v2h12v-2zM14 25h-2v2h2v-2zM28 25H16v2h12v-2z" />
        </g>
      );
    case 'tileView':
      return (
        <g>
          <path d="M17 14v3h-3v-3h3zm2-2h-7v7h7v-7zM17 23v3h-3v-3h3zm2-2h-7v7h7v-7zM26 14v3h-3v-3h3zm2-2h-7v7h7v-7zM26 23v3h-3v-3h3zm2-2h-7v7h7v-7z" />
        </g>
      );
    case 'featureList':
      return (
        <g>
          <path d="M27 10H12C10.9 10 10 10.9 10 12V26C10 27.1 10.9 28 12 28H27C28.1 28 29 27.1 29 26V12C29 10.9 28.1 10 27 10ZM27 12V14H12V12H27ZM22 26H19V16H22V26ZM12 16H17V26H12V16ZM24 26V16H27V26H24Z" />
          <path d="M11 18H28V20H11V18Z" /> <path d="M11 22H28V24H11V22Z" />
        </g>
      );
    case 'merge':
      return (
        <g>
          <path d="M25 28.4L26.4 27L23 23.6L21.6 25L25 28.4ZM15.5 16H19V21.6L13.6 27L15 28.4L21 22.4V16H24.5L20 11.5L15.5 16Z" />
        </g>
      );
    case 'group':
      return (
        <g>
          <path d="M29 11v18H11V11h18zm2-2H9v22h22V9z" />
          <path d="M25 20v5h-5v-5h5zm2-2h-9v9h9v-9z" />
          <path d="M18 20h-3v-5h5v3h2v-5h-9v9h5v-2z" />
        </g>
      );
    case 'ungroup':
      return (
        <g>
          <path d="M28 23v5h-5v-5h5zm2-2h-9v9h9v-9zM17 12v5h-5v-5h5zm2-2h-9v9h9v-9z" />
        </g>
      );
    case 'grid':
      return (
        <g>
          <path fillRule="evenodd" clipRule="evenodd" d="M16.5 29V11H17.5V29H16.5Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M29 17.5L11 17.5V16.5L29 16.5V17.5Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M29 23.5L11 23.5V22.5L29 22.5V23.5Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M22.5 29V11H23.5V29H22.5Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M29 11H11V29H29V11ZM9 9V31H31V9H9Z" />
        </g>
      );
    case 'adjust':
      return (
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.8293 14C26.4174 12.8348 25.3062 12 24 12C22.6938 12 21.5826 12.8348 21.1707 14H10V16H21.1707C21.5826 17.1652 22.6938 18 24 18C25.3062 18 26.4174 17.1652 26.8293 16H30V14H26.8293ZM23 15C23 14.4477 23.4477 14 24 14C24.5523 14 25 14.4477 25 15C25 15.5523 24.5523 16 24 16C23.4477 16 23 15.5523 23 15Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 23H13.1707C13.5826 21.8348 14.6938 21 16 21C17.3062 21 18.4174 21.8348 18.8293 23H30V25H18.8293C18.4174 26.1652 17.3062 27 16 27C14.6938 27 13.5826 26.1652 13.1707 25H10V23ZM16 25C16.5523 25 17 24.5523 17 24C17 23.4477 16.5523 23 16 23C15.4477 23 15 23.4477 15 24C15 24.5523 15.4477 25 16 25Z"
          />
        </g>
      );
    case 'sun':
      return (
        <g>
          <path d="M19 9H21V12H19V9Z" />
          <path d="M14.8 13.3L13 11.5L11.5 12.9L13.3 14.7L14.8 13.3Z" />
          <path d="M12 18.9H9V20.9H12V18.9Z" />
          <path d="M27.0314 11.5067L25.2637 13.2745L26.6779 14.6887L28.4456 12.9209L27.0314 11.5067Z" />
          <path d="M27 28.4L25.2 26.6L26.6 25.2L28.4 27L27 28.4Z" />
          <path d="M31 18.9H28V20.9H31V18.9Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 19.9C14 16.6 16.7 13.9 20 13.9C23.3 13.9 26 16.6 26 19.9C26 23.2 23.3 25.9 20 25.9C16.7 25.9 14 23.2 14 19.9ZM16 19.9C16 22.1 17.8 23.9 20 23.9C22.2 23.9 24 22.1 24 19.9C24 17.7 22.2 15.9 20 15.9C17.8 15.9 16 17.7 16 19.9Z"
          />
          <path d="M19 28V31H21V28H19Z" />
          <path d="M13 28.4L11.5 27L13.3 25.2L14.8 26.6L13 28.4Z" />
        </g>
      );
    case 'selectMultiple':
      return (
        <g>
          <path d="M11 9a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h2v-2h-2V11h12v2h2v-2a2 2 0 0 0-2-2H11z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 17a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H17a2 2 0 0 1-2-2V17zm2 0h12v12H17V17z"
          />
        </g>
      );
    case 'nestedTools':
      return (
        <g>
          <path d="M36 33l-3-3v6l3-3z" />
        </g>
      );
    case 'isolationMode':
      return (
        <g>
          <path d="M20 10h-.1c-.5 0-1 .5-1 1 0 .6.5 1 1 1h.1c.6 0 1-.5 1-1 0-.6-.4-1-1-1zm-4.5 1.2c-.2 0-.3 0-.5.1h-.1c-.5.4-.6 1-.4 1.5.2.3.5.5.9.5.2 0 .3 0 .5-.1h.1c.5-.3.6-.9.4-1.4-.2-.4-.6-.6-.9-.6zm-3.3 3.3c-.4 0-.7.2-.9.5v.1c-.3.5-.1 1.1.4 1.4.1.1.3.1.4.1.4 0 .7-.2.9-.5V16c.3-.5.1-1.1-.4-1.4-.1 0-.2-.1-.4-.1zM11 19c-.6 0-1 .4-1 1v.1c0 .5.5 1 1 1 .6 0 1-.5 1-1V20c0-.5-.5-1-1-1zm1.2 4.5c-.2 0-.3 0-.5.1-.5.4-.6 1-.3 1.4v.1c.2.3.5.5.9.5.2 0 .4 0 .5-.1.5-.3.6-.9.3-1.4V24c-.2-.3-.5-.5-.9-.5zm3.4 3.3c-.3 0-.7.2-.9.5-.3.5-.1 1.1.4 1.4.2.1.3.1.5.1.3 0 .7-.2.9-.5.3-.5.1-1.1-.4-1.4l-.1.1c-.1-.1-.3-.2-.4-.2zm4.6 1.2c-.6 0-1.1.5-1.1 1s.4 1 1 1h.1c.5 0 1-.5 1-1 0-.6-.5-1-1-1zm4.4-1.3c-.2 0-.4 0-.5.1H24c-.5.3-.6.9-.3 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1h.1c.5-.3.6-.9.3-1.4-.2-.3-.5-.5-.9-.5zm3.3-3.4c-.4 0-.7.2-.9.5v.1c-.3.5-.1 1.1.4 1.4.2.1.3.1.5.1.3 0 .7-.2.9-.5 0 0 0-.1.1-.1.3-.5.1-1.1-.4-1.3-.3-.1-.4-.2-.6-.2zm1.1-4.5c-.6 0-1 .5-1 1v.1c0 .5.5 1 1 1s1-.5 1-1v-.1-.1c0-.5-.5-.9-1-.9zm-1.3-4.5c-.2 0-.4 0-.5.1-.5.3-.6.9-.3 1.4v.1c.2.3.5.5.9.5.2 0 .4 0 .5-.1.5-.3.6-.9.3-1.4-.3-.4-.6-.6-.9-.6zm-3.4-3.2c-.4 0-.7.2-.9.5-.3.5-.1 1.1.4 1.4h.1c.2.1.3.1.5.1.4 0 .7-.2.9-.5.3-.5.1-1.1-.4-1.4h-.1c-.2-.1-.3-.1-.5-.1zM20 24a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" />
        </g>
      );
    case 'user':
      return (
        <g>
          <path d="M20 13.0839C21.16 13.0839 22.1 14.1148 22.1 15.3871C22.1 16.6594 21.16 17.6903 20 17.6903C18.84 17.6903 17.9 16.6594 17.9 15.3871C17.9 14.1148 18.84 13.0839 20 13.0839ZM20 22.9548C22.97 22.9548 26.1 24.5561 26.1 25.2581V26.4645H13.9V25.2581C13.9 24.5561 17.03 22.9548 20 22.9548ZM20 11C17.79 11 16 12.9632 16 15.3871C16 17.811 17.79 19.7742 20 19.7742C22.21 19.7742 24 17.811 24 15.3871C24 12.9632 22.21 11 20 11ZM20 20.871C17.33 20.871 12 22.3406 12 25.2581V28.5484H28V25.2581C28 22.3406 22.67 20.871 20 20.871Z" />
        </g>
      );
    case 'admin':
      return (
        <g>
          <path d="M20 11C17.8 11 16 13 16 15.4C16 17.8 17.8 19.8 20 19.8C22.2 19.8 24 17.8 24 15.4C24 13 22.2 11 20 11ZM20 20.9C17.3 20.9 12 22.4 12 25.3V28.6H28V25.3C28 22.3 22.7 20.9 20 20.9Z" />
        </g>
      );
    case 'addUser':
      return (
        <g>
          <path d="M20 13.0839C21.16 13.0839 22.1 14.1148 22.1 15.3871C22.1 16.6594 21.16 17.6903 20 17.6903C18.84 17.6903 17.9 16.6594 17.9 15.3871C17.9 14.1148 18.84 13.0839 20 13.0839ZM20 22.9548C22.97 22.9548 26.1 24.5561 26.1 25.2581V26.4645H13.9V25.2581C13.9 24.5561 17.03 22.9548 20 22.9548ZM20 11C17.79 11 16 12.9632 16 15.3871C16 17.811 17.79 19.7742 20 19.7742C22.21 19.7742 24 17.811 24 15.3871C24 12.9632 22.21 11 20 11ZM20 20.871C17.33 20.871 12 22.3406 12 25.2581V28.5484H28V25.2581C28 22.3406 22.67 20.871 20 20.871Z" />
          <path d="M9 19V16H12V14H9V11H7V14H4V16H7V19H9Z" />
        </g>
      );
    case 'users':
      return (
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 20C18.9 20 20.5 18.4 20.5 16.5C20.5 14.6 18.9 13 17 13C15.1 13 13.5 14.6 13.5 16.5C13.5 18.4 15.1 20 17 20ZM17 15C17.8 15 18.5 15.7 18.5 16.5C18.5 17.3 17.8 18 17 18C16.2 18 15.5 17.3 15.5 16.5C15.5 15.7 16.2 15 17 15Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 21.8C14.7 21.8 10 23 10 25.3V27H24V25.2C24 22.9 19.3 21.8 17 21.8ZM12.3 25C13.1 24.4 15.2 23.8 17 23.8C18.8 23.8 20.8 24.5 21.7 25H12.3Z"
          />
          <path d="M26 25.2C26 23.8 25.2 22.6 24 21.8C26.5 22.1 30 23.2 30 25.2V27H26V25.2Z" />
          <path d="M22 19.5477C22.5054 19.8349 23.0848 20 23.6952 20C25.5952 20 27.1952 18.4 27.1952 16.5C27.1952 14.6 25.5952 13 23.6952 13C23.2741 13 22.8678 13.0786 22.4904 13.2216C22.8046 13.8069 23.0244 14.4469 23.1279 15.1199C23.3048 15.043 23.4969 15 23.6952 15C24.4952 15 25.1952 15.7 25.1952 16.5C25.1952 17.3 24.4952 18 23.6952 18C23.4156 18 23.1483 17.9145 22.9187 17.7691C22.7131 18.4128 22.3994 19.0132 22 19.5477Z" />
        </g>
      );
    case 'emoticon':
      return (
        <g>
          <path d="M23.5 19a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM16.5 19a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM20 24c-1.5 0-2.8-.8-3.4-2h-1.7c.8 2 2.8 3.5 5.1 3.5s4.3-1.5 5.1-3.5h-1.7c-.6 1.2-1.9 2-3.4 2z" />
          <path d="M20 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z" />
        </g>
      );
    case 'comment':
      return (
        <g>
          <path d="M30 12c0-1.1-.9-2-2-2H12c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4V12zm-2 0v13.2L26.8 24H12V12h16z" />
          <path d="M26 20H14v2h12v-2zM26 17H14v2h12v-2zM26 14H14v2h12v-2z" />
        </g>
      );
    case 'mapPin':
      return (
        <g>
          <path d="M20 10c3.3 0 6 2.9 6 6.4 0 3.7-3.5 9.1-6 12.4-2.5-3.3-6-8.7-6-12.4 0-3.5 2.7-6.4 6-6.4zm0-2c-4.4 0-8 3.8-8 8.4 0 6.3 8 15.6 8 15.6s8-9.3 8-15.6c0-4.6-3.6-8.4-8-8.4z" />
          <path d="M19.8 19a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
        </g>
      );
    case 'pointInfo':
      return (
        <g>
          <path d="M20 8c-4.457 0-8 3.72-8 8.4 0 6.24 8 15.6 8 15.6s8-9.36 8-15.6c0-4.68-3.543-8.4-8-8.4zm-5.714 8.4c0-3.36 2.514-6 5.714-6 3.2 0 5.714 2.64 5.714 6 0 3.48-3.314 8.64-5.714 11.88-2.4-3.24-5.714-8.4-5.714-11.88z" />
          <path d="M21 15v-2h-2v2h2zM21 23v-6h-2v6h2z" />
        </g>
      );
    case 'areaInfo':
      return (
        <g>
          <path d="M32 22c0 1.7-1.3 3-3 3-.6 0-1.1-.2-1.6-.5L22.8 28c.1.4.2.7.2 1 0 1.7-1.3 3-3 3s-3-1.3-3-3c0-.3.1-.6.2-.9l-4.6-3.6c-.5.3-1 .5-1.6.5-1.7 0-3-1.3-3-3 0-1.6 1.2-2.9 2.8-3l1.5-5.6C11.5 12.9 11 12 11 11c0-1.7 1.3-3 3-3 1.3 0 2.4.8 2.8 2h6.4c.4-1.2 1.5-2 2.8-2 1.7 0 3 1.3 3 3 0 1-.5 1.9-1.3 2.5l1.5 5.6c1.6 0 2.8 1.3 2.8 2.9zm-8.8-10h-6.4c-.4 1.1-1.4 1.9-2.6 2l-1.5 5.6c.8.5 1.3 1.4 1.3 2.4 0 .3-.1.6-.2.9l4.6 3.5c.5-.2 1-.4 1.6-.4.6 0 1.1.2 1.6.5l4.6-3.5c-.1-.4-.2-.7-.2-1 0-1 .5-1.9 1.3-2.5L25.8 14c-1.2-.1-2.2-.9-2.6-2zM29 23c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1zm-9 7c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1zm-10-8c0 .6.4 1 1 1s1-.4 1-1-.4-1-1-1-1 .4-1 1zm4-12c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zm12 0c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1z" />
          <path d="M21 16v-2h-2v2h2zM21 24v-6h-2v6h2z" />
        </g>
      );
    case 'zoomToArea':
      return (
        <g>
          <path d="M25.9 25.4c-.3-.8-1-1.4-1.9-1.4h-1v-3c0-.6-.4-1-1-1h-6v-2h2c.6 0 1-.4 1-1v-2h2c1.1 0 2-.9 2-2v-.4c2.9 1.2 5 4.1 5 7.4 0 2.1-.8 4-2.1 5.4zM19 27.9c-3.9-.5-7-3.9-7-7.9 0-.6.1-1.2.2-1.8L17 23v1c0 1.1.9 2 2 2v1.9zM20 10c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zM10 13H8V8h5v2h-3v3zM32 13h-2v-3h-3V8h5v5zM13 32H8v-5h2v3h3v2zM32 32h-5v-2h3v-3h2v5z" />
        </g>
      );
    case 'profile':
      return (
        <g>
          <path d="M31 18h-3v4h-1v-8h-5v14h-1v-5h-2v-2h-3v-4h-5v2H9v2h4v-2h1v4h3v2h2v5h5V16h1v8h5v-4h1v-2z" />
        </g>
      );
    case 'north':
      return (
        <g>
          <path d="M20.4 15.3v8.3l.8.4 3.4 1.5-4.2-10.2zm0-5.3l7.4 18.1-.7.7-6.7-3-6.7 3-.7-.7L20.4 10z" />
        </g>
      );
    case 'more':
      return (
        <g>
          <path d="M20 16c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
        </g>
      );
    case 'link':
      return (
        <g>
          <path d="M25 15h-4v2h4c1.6 0 3 1.4 3 3s-1.4 3-3 3h-4v2h4c2.8 0 5-2.2 5-5s-2.2-5-5-5zM19 23h-4c-1.7 0-3-1.4-3-3s1.3-3 3-3h4v-2h-4c-2.8 0-5 2.2-5 5s2.2 5 5 5h4v-2z" />
          <path d="M24 19h-8v2h8v-2z" />
        </g>
      );
    case 'back':
      return (
        <g>
          <path d="M28 19H15.83L21.42 13.41L20 12L12 20L20 28L21.41 26.59L15.83 21H28V19Z" />
        </g>
      );
    case 'dhi':
      return (
        <g>
          <path
            d="M6.85134 0C3.66607 0 0.480796 1.32001 0.360597 1.82287C0.360597 1.94858 0.480796 2.01144 0.661094 2.01144C1.26209 2.01144 2.64438 1.19429 5.58925 1.19429C6.97154 1.19429 8.41393 1.44572 8.41393 1.82287C8.41393 2.13715 7.39223 2.38858 7.39223 2.82859C7.39223 3.01716 7.69273 3.20573 8.41393 3.20573C11.0583 3.20573 14.484 4.27431 14.484 5.34289C14.484 5.4686 14.3638 5.59432 14.2436 5.59432C13.7027 5.59432 12.0199 4.65145 8.95482 4.65145C3.30547 4.65145 0 8.61148 0 9.93148C0 10.3086 0.180298 10.4972 0.540895 10.4972C1.62269 10.4972 2.34388 9.30291 4.68776 9.30291C6.49074 9.30291 7.03164 9.99434 8.11343 9.99434C8.59422 9.99434 9.07502 9.80577 9.07502 9.36577C9.07502 8.67433 8.23363 8.23433 6.91144 7.92004C5.76955 7.66861 5.10845 7.48004 5.10845 6.97718C5.10845 6.16003 6.97154 5.4686 8.83462 5.4686C15.2052 5.4686 17.9697 9.99434 18.3904 9.99434C18.5106 9.99434 18.5106 9.93148 18.5106 9.74291C18.5106 8.4229 17.0081 5.84575 15.3855 4.46288C12.9815 2.38858 11.1785 1.94858 11.1785 1.63429C11.1785 1.57144 11.2386 1.57144 11.3588 1.57144C11.8997 1.57144 14.2436 2.57716 16.1067 4.33717C18.751 6.78861 20.1934 10.6229 20.1934 13.6401C20.1934 14.2687 20.1333 14.5829 19.8929 14.5829C19.4722 14.5829 18.9313 12.3201 17.1283 10.1201C15.6259 8.23433 13.0416 6.41146 10.6376 6.41146C10.0366 6.41146 9.67601 6.53718 9.67601 6.91432C9.67601 7.92004 13.1618 8.86291 13.1618 12.5086C13.1618 12.8229 13.1618 13.0744 13.0416 13.3258C12.7411 14.3315 13.6426 15.5258 14.1835 15.4001C15.1451 15.2115 14.9648 12.3201 15.0249 12.6344V12.5715C14.9047 11.4401 14.6643 11.0629 14.6643 10.4343C14.6643 10.0572 14.8446 9.86863 15.085 9.86863C15.4456 9.86863 16.3471 10.4972 17.3687 11.9429C18.3904 13.4515 19.1116 15.6515 19.1116 18.103C19.1116 19.7373 19.1717 20.6173 19.5924 20.6173C20.2535 20.6173 21.2752 17.9772 21.2752 14.9601C21.3353 6.78861 14.9648 0 6.85134 0Z"
            transform="translate(9.72437 10)"
          />
        </g>
      );
    case 'applications':
      return (
        <g>
          <path d="M12 16H16V12H12V16ZM18 28H22V24H18V28ZM12 28H16V24H12V28ZM18 22H22V18H18V22Z" />
          <path d="M16 18H12V22H16V18Z" /> <path d="M28 12H24V16H28V12Z" />
          <path d="M22 12H18V16H22V12Z" /> <path d="M28 18H24V22H28V18Z" />
          <path d="M28 24H24V28H28V24Z" />
        </g>
      );
    case 'plans':
      return (
        <g>
          <path d="M12 16H16V12H12V16ZM18 28H22V24H18V28ZM12 28H16V24H12V28ZM18 22H22V18H18V22Z" />
          <path d="M28 18H24V22H28V18Z" />
        </g>
      );
    case 'cloudUsers':
      return (
        <g>
          <path d="M20 10C14.5 10 10 14.5 10 20C10 25.5 14.5 30 20 30C25.5 30 30 25.5 30 20C30 14.5 25.5 10 20 10ZM15.1 26.3C15.5 25.4 18.1 24.5 20 24.5C21.9 24.5 24.5 25.4 24.9 26.3C23.6 27.4 21.9 28 20 28C18.1 28 16.4 27.4 15.1 26.3ZM26.4 24.8C25 23.1 21.5 22.5 20 22.5C18.5 22.5 15.1 23.1 13.6 24.8C12.6 23.5 12 21.8 12 20C12 15.6 15.6 12 20 12C24.4 12 28 15.6 28 20C28 21.8 27.4 23.5 26.4 24.8ZM20 14C18.1 14 16.5 15.6 16.5 17.5C16.5 19.4 18.1 21 20 21C21.9 21 23.5 19.4 23.5 17.5C23.5 15.6 21.9 14 20 14ZM20 19C19.2 19 18.5 18.3 18.5 17.5C18.5 16.7 19.2 16 20 16C20.8 16 21.5 16.7 21.5 17.5C21.5 18.3 20.8 19 20 19Z" />
        </g>
      );
    case 'layer':
      return (
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.36 18.27L20 24L12.63 18.27L11 17L20 10L29 17L27.36 18.27ZM25.74 17L20 12.53L14.26 17L20 21.47L25.74 17ZM12.62 20.81L19.99 26.54L27.37 20.8L29 22.07L20 29.07L11 22.07L12.62 20.81Z"
          />
        </g>
      );
    case 'rightCompress':
      return (
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.58 20L11 24.59L12.41 26L18.41 20L12.41 14L11 15.41L15.58 20ZM26.58 20L22 24.59L23.41 26L29.41 20L23.41 14L22 15.41L26.58 20Z"
          />
        </g>
      );
    case 'warning':
      return (
        <>
          <g>
            <path d="M8.3302 27.4409C7.55448 28.9462 8.17505 30 9.88163 30H30.0503C31.7569 30 32.5326 28.7957 31.6018 27.4409L21.5174 10.129C20.7417 8.62366 19.3454 8.62366 18.4145 10.129L8.3302 27.4409Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21 27H19V25H21V27Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21 23H19V15H21V23Z" fill="white" />
          </g>
        </>
      );
    case 'ProcessedData':
      return (
        <>
          <g>
            <path d="M32 21.3L25.1 28.2L20.9 24L19.5 25.4L25.1 31L32 24.1V21.3Z" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28 12.4546C28 11.0989 23.5228 10 18 10C12.4772 10 8 11.0989 8 12.4546V27.9091C8 29.2727 12.4545 30.3636 18 30.3636V28.5455C15.3276 28.5455 12.9803 28.2809 11.3536 27.8819C10.6292 27.7042 10.1258 27.5185 9.81818 27.3622V24.4962C10.2743 24.6548 10.806 24.7907 11.3859 24.9066C13.1034 25.2499 15.4388 25.4545 18 25.4545V23.6364C15.5199 23.6364 13.3098 23.437 11.7422 23.1237C10.9509 22.9655 10.3709 22.7875 10.0107 22.6164C9.92509 22.5757 9.86251 22.5403 9.81818 22.5122V19.0417C10.2743 19.2002 10.806 19.3362 11.3859 19.4521C13.1034 19.7953 15.4388 20 18 20C20.5612 20 22.8966 19.7953 24.6141 19.4521C25.194 19.3362 25.7257 19.2002 26.1818 19.0417V21H28V12.4546ZM26.4626 11.7269C26.4646 11.7271 26.4534 11.7385 26.4229 11.7604C26.4454 11.7376 26.4606 11.7266 26.4626 11.7269ZM18 11.8182C20.5922 11.8182 22.888 12.0712 24.5124 12.4545C22.888 12.8379 20.5922 13.0909 18 13.0909C15.4078 13.0909 13.112 12.8379 11.4876 12.4545C13.112 12.0712 15.4078 11.8182 18 11.8182ZM9.53739 11.7269C9.5394 11.7266 9.55464 11.7376 9.57707 11.7604C9.54659 11.7385 9.53537 11.7271 9.53739 11.7269ZM9.81818 13.8662V17.0577C9.86251 17.0858 9.92509 17.1211 10.0107 17.1618C10.3709 17.3329 10.9509 17.511 11.7422 17.6691C13.3098 17.9824 15.5199 18.1818 18 18.1818C20.4801 18.1818 22.6902 17.9824 24.2578 17.6691C25.0491 17.511 25.6291 17.3329 25.9893 17.1618C26.0749 17.1211 26.1375 17.0858 26.1818 17.0577V13.8662C24.3721 14.497 21.3821 14.9091 18 14.9091C14.6179 14.9091 11.6279 14.497 9.81818 13.8662ZM9.54145 27.1891C9.54145 27.1891 9.55796 27.1994 9.57957 27.2214C9.55034 27.2003 9.54145 27.1891 9.54145 27.1891Z"
            />
          </g>
        </>
      );
    case 'CalendarSetting':
      return (
        <>
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26 11H27C28.1 11 29 11.9 29 13V19.5H27V17H13V27H20V29H13C11.9 29 11 28.1 11 27V13C11 11.9 11.9 11 13 11H14V9H16V11H24V9H26V11ZM13 13V15H27V13H13Z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M30.7024 26.539C30.725 26.363 30.742 26.187 30.742 26C30.742 25.813 30.725 25.637 30.7024 25.461L31.8954 24.5535C32.0029 24.471 32.0311 24.3225 31.9633 24.2015L30.8324 22.2985C30.7815 22.2105 30.6854 22.161 30.5836 22.161C30.5497 22.161 30.5158 22.1665 30.4875 22.1775L29.0796 22.7275C28.7856 22.5075 28.4689 22.326 28.124 22.1885L27.9092 20.731C27.8922 20.599 27.7735 20.5 27.6321 20.5H25.3704C25.229 20.5 25.1103 20.599 25.0933 20.731L24.8785 22.1885C24.5336 22.326 24.2169 22.513 23.9229 22.7275L22.515 22.1775C22.481 22.1665 22.4471 22.161 22.4132 22.161C22.3171 22.161 22.221 22.2105 22.1701 22.2985L21.0392 24.2015C20.9657 24.3225 20.9996 24.471 21.1071 24.5535L22.3001 25.461C22.2775 25.637 22.2605 25.8185 22.2605 26C22.2605 26.1815 22.2775 26.363 22.3001 26.539L21.1071 27.4465C20.9996 27.529 20.9714 27.6775 21.0392 27.7985L22.1701 29.7015C22.221 29.7895 22.3171 29.839 22.4188 29.839C22.4528 29.839 22.4867 29.8335 22.515 29.8225L23.9229 29.2725C24.2169 29.4925 24.5336 29.674 24.8785 29.8115L25.0933 31.269C25.1103 31.401 25.229 31.5 25.3704 31.5H27.6321C27.7735 31.5 27.8922 31.401 27.9092 31.269L28.124 29.8115C28.4689 29.674 28.7856 29.487 29.0796 29.2725L30.4875 29.8225C30.5215 29.8335 30.5554 29.839 30.5893 29.839C30.6854 29.839 30.7815 29.7895 30.8324 29.7015L31.9633 27.7985C32.0311 27.6775 32.0029 27.529 31.8954 27.4465L30.7024 26.539ZM26.5 24.0776C25.4379 24.0776 24.5776 24.9379 24.5776 26C24.5776 27.0621 25.4379 27.9224 26.5 27.9224C27.5621 27.9224 28.4224 27.0621 28.4224 26C28.4224 24.9379 27.5621 24.0776 26.5 24.0776Z"
            />
          </g>
        </>
      );
    case 'Projects':
      return (
        <>
          <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5 7C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V9C21 7.89543 20.1046 7 19 7H5ZM5 9V19H7.5V9H5ZM8.5 9V19H15.5V9H8.5ZM16.5 9V19H19V9H16.5Z"
              fill="#0B4566"
            />
            <path
              d="M9.5 5.5H14.5V9H16V6C16 4.89543 15.1046 4 14 4H10C8.89543 4 8 4.89543 8 6V9H9.5V5.5Z"
              fill="#0B4566"
            />
          </g>
        </>
      );
    case 'MyProjects':
      return (
        <>
          <g>
            <path
              d="M18.0105 14.9972C17.1138 14.998 16.3809 15.8138 16.3818 16.7919C16.3826 17.77 17.1169 18.5845 18.0136 18.5837C18.9102 18.5829 19.6431 17.7672 19.6422 16.7891C19.6414 15.8109 18.9071 14.9964 18.0105 14.9972ZM18.014 19.032C16.9136 19.033 14.754 19.6462 14.7551 20.8281L14.7562 22.173L21.2771 22.1674L21.276 20.8224C21.2749 19.5998 19.1144 19.0311 18.014 19.032Z"
              fill="#0B4566"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16 7V6C16 4.89543 15.1046 4 14 4H10C8.89543 4 8 4.89543 8 6V7H5C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H12.9991L12.9987 20.5013C12.9982 19.9329 13.2024 19.432 13.536 19H8.5V9H15.5V14.7549C15.7667 14.3984 16.1077 14.1008 16.5 13.885V9H19V13.6621C19.9593 13.9845 20.7107 14.7598 21 15.7338V9C21 7.89543 20.1046 7 19 7H16ZM14.5 5.5H9.5V7H14.5V5.5ZM5 19V9H7.5V19H5Z"
              fill="#0B4566"
            />
          </g>
        </>
      );
    default:
      return <path />;
  }
};

export default MikeSvgIcon;
