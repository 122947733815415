import { MenuItem } from '@material-ui/core';
import React from 'react';
import { OptionProps } from './MikeInnerExpandableButton';

interface IMikeExpandableButtonEntriesProps {
  options: Array<OptionProps>;
  selectedIndex: number;
  handleClick: (event: any, index: number) => void;
}

const MikeExpandableButtonEntries: React.FC<IMikeExpandableButtonEntriesProps> = ({
  options,
  selectedIndex,
  handleClick,
}) => {
  return (
    <>
      {options.map((option, index) => (
        <MenuItem
          key={option.label}
          disabled={option.disabled}
          selected={index === selectedIndex}
          onClick={(event) => handleClick(event, index)}
        >
          {option.label}
        </MenuItem>
      ))}
    </>
  );
};

export default MikeExpandableButtonEntries;
