import React, { ReactNode } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core/';
import { IMikeTheme } from '../mike-shared-styles';
import MikeSupportMenuButton from './MikeSupportMenuButton';
import { ClickAwayListener } from '@material-ui/core';
import MikeTopbarDropdown, { wrapperStyle } from '../mike-topbar-dropdown';
import MikeTooltip from '../mike-tooltip';
import { IMikeTooltipPosition } from '../mike-tooltip/MikeTooltip';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import MikeSupportMenuMailItem from './MikeSupportMenuMailItem';
import MikeSupportMenuDocumentationItem from './MikeSupportMenuDocumentationItem';
import { IMailConfig } from './IMailConfig';
import { ILinkConfig } from './ILinkConfig';
import MikeSupportMenuLinkItem from './MikeSupportMenuLinkItem';
import { ReactComponent as AdminCenter } from 'icons/AdminCenter';

interface IProps {
  /**
   * Admin Center link configuration.
   * To be used in conjunction with showAdminCenterLink
   */
  adminCenterConfig?: ILinkConfig;
  /**
   * Documentation configuration.
   */
  documentationConfig?: Array<ILinkConfig>;
  /**
   * Mail configuration.
   */
  mailConfig?: IMailConfig;
  /**
   * Custom children.
   */
  children?: ReactNode;
  /**
   * Click away callback.
   */
  clickAwayCallback?: () => void;
  /**
   * Show Admin Center link.
   * Set to true if logged in user is an administrator
   * Depends on adminCenterConfig
   */
  showAdminCenterLink?: boolean;
  /**
   * Custom title.
   */
  title?: string;
  /**
   * Tooltip title.
   * Use "" for no tooltip.
   */
  tooltipTitle?: string;
  /**
   * How to position the tooltip in relation to its target/anchor.
   * Structure is `{ top?: number, left?: number }`.
   */
  tooltipPosition?: IMikeTooltipPosition;
}

const useStyles = makeStyles((theme: IMikeTheme) => {
  const mediumGrey = theme.palette.mediumGrey as PaletteColor; // possibly undefined
  return createStyles({
    supportMenuTitle: {
      paddingTop: 0,
      paddingBottom: theme.spacing(1),
      fontWeight: 'bold',
    },
    supportMenuInnerContainer: {
      backgroundColor: mediumGrey && mediumGrey.light,
      paddingBottom: theme.spacing(2),
    },
  });
});

/**
 * @name MikeSupportMenuContainer
 * @summary The main support menu wrapper-component. Used to display support menu in the topbar.
 */
export const MikeSupportMenuContainer = (props: IProps) => {
  const {
    adminCenterConfig,
    children,
    clickAwayCallback,
    documentationConfig,
    mailConfig,
    showAdminCenterLink = false,
    title = 'Help and support',
    tooltipTitle = 'Help and support',
    tooltipPosition,
  } = props;
  const classes = useStyles();
  const ttPos = { left: -9, top: -1, ...tooltipPosition };
  const [showMenu, setShowMenu] = React.useState(false);
  const [
    adminCenterConfiguration,
    setAadminCenterConfiguration,
  ] = React.useState<ILinkConfig | null>(null);

  const handleOnClick = React.useCallback(() => {
    if (clickAwayCallback) {
      clickAwayCallback();
    }
    setShowMenu(true);
  }, []);

  React.useEffect(() => {
    if (adminCenterConfig) {
      const config = {} as ILinkConfig;
      config.href = adminCenterConfig.href;
      config.icon = adminCenterConfig.icon ? adminCenterConfig.icon : <AdminCenter />;
      config.label = adminCenterConfig.label ? adminCenterConfig.label : 'Admin Center';
      config.target = adminCenterConfig.target ? adminCenterConfig.label : '_blank';
      setAadminCenterConfiguration(config);
    }
  }, [adminCenterConfig]);

  // Only show tooltips when collapsed:
  const ttTitle = showMenu ? '' : tooltipTitle;

  return (
    <div className={wrapperStyle}>
      <MikeTooltip title={ttTitle} arrow mikePosition={ttPos}>
        <MikeSupportMenuButton onClickCallBack={handleOnClick} />
      </MikeTooltip>
      {showMenu && (
        <ClickAwayListener onClickAway={() => setShowMenu(false)}>
          <div>
            <MikeTopbarDropdown>
              <>
                <Typography className={classes.supportMenuTitle} variant="h4">
                  {title}
                </Typography>
                <div className={classes.supportMenuInnerContainer}>
                  {children ? children : null}
                  {adminCenterConfiguration && showAdminCenterLink && (
                    <MikeSupportMenuLinkItem config={adminCenterConfiguration} />
                  )}
                  {documentationConfig && documentationConfig.length > 0
                    ? documentationConfig.map((docConfig, index) => {
                        return <MikeSupportMenuDocumentationItem key={index} config={docConfig} />;
                      })
                    : null}
                  {mailConfig && <MikeSupportMenuMailItem config={mailConfig} />}
                </div>
              </>
            </MikeTopbarDropdown>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default MikeSupportMenuContainer;
