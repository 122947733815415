import React from 'react';
import clsx from 'clsx';

import CloseIcon from '@material-ui/icons/Close';

import { withStyles, SnackbarContent, IconButton, Theme, WithStyles } from '@material-ui/core';

import './message.css';

const styles = (theme: Theme) => ({
  close: {
    padding: theme.spacing(2),
  },
  icon: {
    fontSize: 20,
  },
  success: {
    color: 'var(--BrandBlue--default)',
    backgroundColor: 'var(--UltimateGreen--pale)',
  },
  error: {
    color: 'var(--BrandBlue--default)',
    backgroundColor: 'var(--Pink--default)',
  },
  info: {
    color: 'var(--BrandBlue--default)',
    backgroundColor: 'var(--ActionBlue--pale)',
  },
  warning: {
    color: 'var(--BrandBlue--default)',
    backgroundColor: 'var(--Pink--pale)',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

export type MessaType = 'success' | 'warning' | 'error' | 'info'; // | 'alert';

interface IProps extends WithStyles<typeof styles> {
  className: string;
  message: string;
  variant: MessaType;
  onClose: () => void;
}

class Message extends React.Component<IProps> {
  public render(): JSX.Element {
    const { classes, message, onClose, variant, className } = this.props;

    return (
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    );
  }
}

export default withStyles(styles)(Message);
