import React, { useRef } from 'react';
import { Tooltip, makeStyles, TooltipProps, Fade } from '@material-ui/core';
import { noop, getId } from '../utils';
import { css } from 'emotion';
import { SPACING } from '../mike-shared-styles/mikeSharedTheme';

export interface IMikeTooltipProps extends TooltipProps {
  /**
   * How to position the tooltip in relation to its target/anchor.
   * Structure is `{ top?: number, left?: number }`.
   */
  mikePosition?: IMikeTooltipPosition;
  /**
   * After what duration (ms) of hovering do you want to hide the tooltip.
   * Default is false. If true, duration is 1500ms.
   */
  mikeHoverHideDelay?: number | boolean;
  /**
   * Whether to hide the tooltip when you click its target/anchor.
   * Default is true.
   */
  mikeHideOnClick?: boolean;
}
export interface IMikeTooltipPosition {
  /**
   * How many pixels from the top to displace the tooltip.
   * Negative values are possible.
   */
  top?: number;
  /**
   * How many pixels from the left to displace the tooltip.
   * Negative values are possible.
   */
  left?: number;
}

// Place arrow and tooltip different if prop mikePosition is defined:
const useStyles = makeStyles({
  popper: (props: IMikeTooltipProps) => ({
    marginTop: props.mikePosition ? props.mikePosition.top : 0,
  }),
  popperArrow: (props: IMikeTooltipProps) => {
    const left =
      props.mikePosition && typeof props.mikePosition.left !== 'undefined'
        ? props.mikePosition.left
        : undefined;
    return {
      '&[x-placement*="bottom"] span[class*="MuiTooltip-arrow"]': {
        '&::before': {
          marginLeft: left,
        },
        '&::after': {
          marginLeft: left ? left + 1 : undefined,
        },
      },
    };
  },
});

// So far not successful mimicking the grow/shrink animation used by MUI:
const hideStyle = css`
  /* transition: visibility 0s, opacity 0.2s linear; */
  /* opacity: 0; */
  visibility: hidden;
`;
// Hiding overflow on the MUI tooltip container makes the arrows go away, which is why this is required:
const titleStyle = css`
  overflow: hidden;
  max-height: ${4.5 * SPACING}px;
`;

/**
 * @name MikeTooltip
 * @summary Tooltips display informative text when users hover over, focus on, or tap an element.
 *
 * MikeTooltip fixes some problems with MUI Tooltip:
 *
 * 1. Adjust position with prop mikePosition members `top` and `left`.
 * 2. Avoid forwardRef errors by wrapping a span.
 * 3. Provide options to hide-on-click and hide-on-delay-during-hover;
 *    control those through new props.
 *
 * NB: The span wrapper causes issues from time to time with layout
 * and with positioning of the tooltip.
 * You will have to make various fixes e.g. in your styling or by
 * wrapping an element around the tooltip. You can target the span
 * by its CSS class "MikeTooltip--span".
 *
 * NB: Only for short text; don't use more than 2 lines
 * of text, it will be cut off.
 */
export const MikeTooltip = (props: IMikeTooltipProps) => {
  const {
    children,
    mikePosition,
    mikeHoverHideDelay = false,
    mikeHideOnClick = true,
    title = "",
    ...rest
  } = props;
  const classes = useStyles(props);
  let theDelay;
  if (mikeHoverHideDelay) {
    theDelay = typeof mikeHoverHideDelay === 'number' ? mikeHoverHideDelay : 1500;
  }

  const idRef = useRef(props.id || getId());
  // Hide the tooltip (remove causes crash):
  const hideTooltip = () => {
    const el = document.getElementById(idRef.current);
    el ? (el.className += ` ${hideStyle}`) : noop();
  };
  // If mikeHoverHideDelay is truthy, hide tooltip a while after opening it,
  // - but don't if it's controlled by the prop 'open':
  const onOpen = () => {
    if (typeof props.open === 'boolean' || !theDelay) {
      return;
    }
    setTimeout(hideTooltip, theDelay);
  };
  // Hide on click - if mikeHideOnClick is truthy:
  const onClick = () => {
    mikeHideOnClick ? hideTooltip() : noop();
  };
  const theTitle = title ? <div className={titleStyle}>{title}</div> : '';
  return (
    <Tooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 1 }}
      classes={classes}
      id={idRef.current}
      onOpen={onOpen}
      title={theTitle}
      {...rest}
    >
      <span onMouseDown={onClick} className="MikeTooltip--span">
        {children}
      </span>
    </Tooltip>
  );
};

export default MikeTooltip;
