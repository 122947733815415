import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ReactComponent as ArrowRight } from 'icons/ArrowRight';
import { IMikeTheme } from '../mike-shared-styles/mikeSharedTheme';
import { ILinkConfig } from './ILinkConfig';

interface IProps {
  /**
   * Configuration.
   */
  config: ILinkConfig;
}

const useStyles = makeStyles((theme: IMikeTheme) =>
  createStyles({
    link: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      color: theme.palette.primary.main,
    },
    linkText: {
      paddingLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
    },
  })
);

/**
 * @name MikeSupportMenuLinkItem
 * @summary A generic support link. Meant to be placed in the mike support menu container.
 */
export const MikeSupportMenuLinkItem = (props: IProps) => {
  const classes = useStyles();
  const { config = {} as ILinkConfig } = props;

  return (
    <Link
      rel="noopener"
      href={config.href}
      target={config.target ? config.target : ''}
      className={classes.link}
      underline="none"
    >
      {config.icon ? config.icon : <ArrowRight />}
      {config.label && <Typography className={classes.linkText}>{config.label}</Typography>}
    </Link>
  );
};

export default MikeSupportMenuLinkItem;
