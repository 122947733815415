import React from 'react';
import { ILinkConfig } from './ILinkConfig';
import { ReactComponent as Documentation } from 'icons/Documentation';
import { MikeSupportMenuLinkItem } from './MikeSupportMenuLinkItem';

interface IProps {
  /**
   * Configuration.
   */
  config: ILinkConfig;
}

/**
 * @name MikeSupportMenuDocumentationItem
 * @summary A generic documentation link that users can press to open the documentation. Meant to be placed in the mike support menu container.
 */
export const MikeSupportMenuDocumentationItem = (props: IProps) => {
  const { config } = props;
  const documentationConfig: ILinkConfig = {
    href: config.href,
    icon: config.icon ? config.icon : <Documentation />,
    label: config.label ? config.label : 'Documentation',
    target: config.target ? config.target : '',
  };

  return <MikeSupportMenuLinkItem config={documentationConfig} />;
};

export default MikeSupportMenuDocumentationItem;
