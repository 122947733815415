import React from 'react';
import { withStyles, WithStyles, Typography, Link } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Forward from '@material-ui/icons/Forward';
import MailOutline from '@material-ui/icons/MailOutline';
import { IMikeTheme } from '../mike-shared-styles/mikeSharedTheme';
import MikeSvgIcon from '../mike-icon/MikeSvgIcon';
import MikeTooltip, { IMikeTooltipPosition } from '../mike-tooltip/MikeTooltip';

export interface ISelectLink {
  name: string;
  link: string;
  newTab: boolean;
}

export interface ISupportLinks {
  links: Array<ISelectLink>;
}

interface IProps extends WithStyles<typeof style> {
  links?: ISupportLinks;
  to: string;
  cc?: string;
  subject?: string;
  body?: string;
  toolTipArrow?: boolean;
  tooltipTitle?: string;
  tooltipPosition?: IMikeTooltipPosition;
}
const style = (theme: IMikeTheme) => ({
  drawerContainer: {
    width: '32em',
    padding: 20,
  },
  link: {
    display: 'flex',
  },
  menuItem: {
    width: '100%',
    backgroundColor: 'white',
    outline: 'none',
  },
  supportButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    minHeight: 60,
    '&:hover': {
      backgroundColor: theme.palette.text.disabled,
    },
    borderRight: '1px solid ' + theme.palette.text.disabled,
    marginTop: '-4px',
  },
});

/**
 * @name MikeSupportButton
 * @summary A generic support button that users can press to send a message to a human. Meant to be placed in the topbar.
 */
export const MikeSupportButton = (props: IProps) => {
  const {
    classes,
    links,
    to,
    cc,
    subject,
    body,
    toolTipArrow = true,
    tooltipTitle = 'Send a request to DHI',
    tooltipPosition,
  } = props;
  const ttPos = { top: 7, left: undefined, ...tooltipPosition };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openLink = (link: string, newTab: boolean) => () => {
    newTab ? window.open(link, '_blank') : window.open(link);
    // tslint:disable-next-line:no-console
    // console.log('event', event);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.target);
  };

  const createMailto = () => {
    let mailto = `mailto:${to}?`;
    mailto += cc ? `cc=${cc}&` : '';
    mailto += subject ? `subject=${encodeURIComponent(subject)}&` : '';
    mailto += body ? `body=${encodeURIComponent(body)}&` : '';
    return mailto;
  };

  const handleClose = React.useCallback(() => setAnchorEl(null), []);

  const SupportMenu: any = () => {
    return open ? (
      <div>
        <Menu
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '300px',
            },
          }}
        >
          {links && links.links
            ? links.links.map((link: ISelectLink) => (
                <div
                  className={classes.menuItem}
                  key={link.name}
                  onClick={openLink(link.link, link.newTab)}
                >
                  <MenuItem>
                    <ListItemIcon>
                      <Forward />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography>{link.name}</Typography>
                    </ListItemText>
                  </MenuItem>
                </div>
              ))
            : ''}

          <MenuItem className={classes.menuItem}>
            <Link href={createMailto()} className={classes.link} underline="none">
              <ListItemIcon>
                <MailOutline />
              </ListItemIcon>
              <ListItemText>
                <Typography>Contact support</Typography>
              </ListItemText>
            </Link>
          </MenuItem>
        </Menu>
      </div>
    ) : null;
  };

  return (
    <>
      <ButtonBase className={classes.supportButton} onClick={handleClick}>
        <MikeTooltip title={tooltipTitle} arrow={toolTipArrow} mikePosition={ttPos}>
          <MikeSvgIcon name={'support'} />
        </MikeTooltip>
      </ButtonBase>
      <SupportMenu />
    </>
  );
};

export default withStyles(style)(MikeSupportButton);
