import React from 'react';

import './mike-card.css';

interface IProps {
  item: ICard;
  type?: 'default' | 'light';
  alternateTitle?: string;
  linkUrl?: string;
  linkText?: string;
  editUrl?: string;
}

interface ICard {
  name?: string;
  description?: string;
  image?: any;
}

// TODO(mapu): rewrite using the MU Card component
/**
 * @name MikeCard
 * @summary Cards contain content and actions about a single subject.
 * @deprecated
 */
export class MikeCard extends React.Component<IProps> {
  public render() {
    const { item, linkUrl, linkText, editUrl, alternateTitle, type = 'default' } = this.props;

    return (
      <div className="mike-card">
        <article
          className={`mike-carditem  mike-carditem--${type}  ${
            !item.description ? 'mike-carditem--empty' : ''
          }`}
        >
          {type === 'light' && this.renderLight(item, linkUrl, linkText)}
          {type !== 'light' && this.renderDefault(item, linkUrl, editUrl, alternateTitle)}
          {this.props.children}
        </article>
      </div>
    );
  }

  private renderLight(item: any, linkUrl: string | undefined, linkText: string | undefined) {
    return (
      <>
        <figure>{item.image || this.getDefaultImage()}</figure>
        <h3 title={item.name || ''}>{item.name || '-'}</h3>
        <p title={item.description}>{item.description || ''}</p>
        <a className="mike-carditem__link" href={linkUrl ? linkUrl : '#'}>
          {linkText || ''}
        </a>
      </>
    );
  }

  private renderDefault(
    item: any,
    linkUrl: string | undefined,
    editUrl: string | undefined,
    alternateTitle: string | undefined
  ) {
    return (
      <>
        <a className="mike-carditem__link" href={linkUrl ? linkUrl : '#'}>
          {item.image && (
            <figure
              className="mike-carditem__image"
              style={{ backgroundImage: `url(${item.image})` }}
            />
          )}
          {!item.image && <figure>{this.getDefaultImage()}</figure>}
        </a>

        <footer className="mike-carditem__footer">
          <h4 title={item.name || '-'}>
            <a href={linkUrl ? linkUrl : '#'}>{item.name || '-'}</a>
            {editUrl && (
              <a className="mike-carditem__edit" href={editUrl}>
                {this.getEditIcon()}
              </a>
            )}
          </h4>

          <p>
            <b>{alternateTitle}</b>
          </p>

          <p title={item.description}>{item.description || '-'}</p>
        </footer>
      </>
    );
  }

  // FIXME(mapu): move hardcoded SVGs into dedicated files

  private getDefaultImage() {
    return (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.68 10.15L21.63 -5.34058e-07L1.33514e-07 10.15H15.68Z"
          transform="translate(12.8101 20.16)"
          fill="#DCF2FD"
          stroke="#0B4566"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.45 7.56L1.82 17.71L-1.33514e-07 0L23.45 7.56Z"
          transform="translate(10.99 12.6001)"
          fill="#BBE5F9"
          stroke="#0B4566"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M17.78 -2.67029e-07L5.34058e-07 3.78L17.78 23.66V-2.67029e-07Z"
          transform="translate(28.49 26.53)"
          fill="#DCF2FD"
          stroke="#0B4566"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.65 1.33514e-07L-1.33514e-07 0.49L23.45 8.05L13.65 1.33514e-07Z"
          transform="translate(10.99 12.1101)"
          fill="#DCF2FD"
          stroke="#0B4566"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0 14.07L21.49 -5.34058e-07H5.81L0 14.07Z"
          transform="translate(7 30.3101)"
          fill="#BBE5F9"
          stroke="#0B4566"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M5.34058e-07 -5.34058e-07L2.31 14.56L17.78 19.88L5.34058e-07 -5.34058e-07Z"
          transform="translate(28.49 30.3101)"
          fill="#BBE5F9"
          stroke="#0B4566"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M5.34058e-07 13.86L15.12 0L11.83 20.23L5.34058e-07 13.86Z"
          transform="translate(34.4399 6.30005)"
          fill="#DCF2FD"
          stroke="#0B4566"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0 14.07L23.8 14.56L21.49 -5.34058e-07L0 14.07Z"
          transform="translate(7 30.3101)"
          fill="#DCF2FD"
          stroke="#0B4566"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.34058e-07 5.81L9.8 13.86L24.92 0L5.34058e-07 5.81Z"
          transform="translate(24.64 6.30005)"
          fill="#BBE5F9"
          stroke="#0B4566"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M5.95 -5.34058e-07L5.34058e-07 10.15L17.78 6.37L5.95 -5.34058e-07Z"
          transform="translate(28.49 20.16)"
          fill="#BBE5F9"
          stroke="#0B4566"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  private getEditIcon() {
    return (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="#0B4566"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M22.06 16.02L22.98 16.94L13.92 26H13V25.08L22.06 16.02ZM25.66 10C25.41 10 25.15 10.1 24.96 10.29L23.13 12.12L26.88 15.87L28.71 14.04C29.1 13.65 29.1 13.02 28.71 12.63L26.37 10.29C26.17 10.09 25.92 10 25.66 10ZM22.06 13.19L11 24.25V28H14.75L25.81 16.94L22.06 13.19Z" />
      </svg>
    );
  }
}

export default MikeCard;
