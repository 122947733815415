import React from 'react';

import './MikeResourcePool.css';

export interface IResource {
  label?: string;
  total: number;
  used: number;
  title?: string;
  renewalDate?: string;
}

interface IProps {
  resources: Array<IResource>;
}

/**
 * @name MikeResourcePool
 * @summary Shows resource pool usage.
 */
export class MikeResourcePool extends React.Component<IProps, {}> {
  private renderBar(resource: IResource) {
    const used = (resource.used * 300) / resource.total;
    return (
      <div className="c-Resource-Pool-Bar">
        <div className="c-Resource-Pool-Bar-Used" style={{ width: used }}>
          {this.renderUsed(used, resource)}
        </div>
        <div className="c-Resource-Pool-Bar-Total" style={{ width: 300 - used }} />
      </div>
    );
  }

  private renderResources() {
    const { resources } = this.props;
    return (
      resources &&
      resources.map((resource: IResource, index: number) => {
        return (
          <div key={index} style={{ marginTop: 25 }}>
            {this.renderTitle(resource)}
            {this.renderBar(resource)}
            {this.renderTotal(resource)}
          </div>
        );
      })
    );
  }

  private renderTitle(resource: IResource) {
    return (
      <span className="c-Resource-Pool-Title">
        <span className="c-Resource-Pool-Title-Label">
          <b>{resource.label}</b>
        </span>
        <span className="c-Resource-Pool-Title-Date">
          Renewal date: {resource && resource.renewalDate}
        </span>
      </span>
    );
  }

  private renderTotal(resource: IResource) {
    const used = (resource.used * 300) / resource.total;
    const usedPerc = (100 * used) / 300;
    return usedPerc < 10 ? (
      <div>
        <span>{resource.used}</span>
        <span className="c-Resource-Pool-Total">{resource.total}</span>
      </div>
    ) : (
      <div className="c-Resource-Pool-Total">{resource.total}</div>
    );
  }

  public renderUsed(used: number, resource: IResource) {
    const usedPerc = (100 * used) / 300;
    return usedPerc > 10 ? (
      <span className="c-Resource-Pool-Used-Data">{resource.used}</span>
    ) : null;
  }

  public render() {
    return <div className="c-Resource-Pool">{this.renderResources()}</div>;
  }
}

export default MikeResourcePool;
