import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { ReactComponent as Checkmark } from 'icons/Checkmark';
import { ReactComponent as Alert } from 'icons/Alert';
import { IMikeTheme } from '../../mike-shared-styles';
import MikeSpinner from '../../mike-spinner/MikeSpinner';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import MikeTooltip from '../../mike-tooltip';

// Style copied from MikeTopbarButton ('@mike/mike-shared-frontend/es/mike-topbar-button/mike-topbar-button')
// TODO: Import style from there or use MikeTopbarButton when supported.
const useStyles = makeStyles((theme: IMikeTheme) => {
  let ultimate = theme.palette.ultimate as PaletteColor; // Possibly undefined
  if (!ultimate) {
    ultimate = theme.palette.primary;
  }
  return createStyles({
    button: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 10px',
      minHeight: 60,
      backgroundColor: theme.palette.text.disabled,
      marginTop: '-4px',
      width: '60px',
      cursor: 'pointer',
    },
    innerDiv: {
      margin: 'auto',
    },
    spinnersCheckDiv: {
      height: '24px',
      width: '24px',
      borderRadius: '12px',
      backgroundColor: ultimate.main,
      margin: 'auto',
    },
    spinnersCheckSvg: {
      '& path': {
        fill: '#fff',
        transform: 'translate(-8px, -8px)',
      },
    },
    spinnersAlertDiv: {
      height: '24px',
      width: '24px',
      borderRadius: '12px',
      backgroundColor: theme.palette.error.main,
      margin: 'auto',
    },
    spinnersAlertSvg: {
      '& path': {
        fill: '#fff',
        transform: 'translate(-8px, -8px)',
      },
      '& path:first-of-type': {
        fill: 'transparent',
        stroke: '#fff',
        strokeWidth: '2px',
        strokeJoin: 'miter',
      },
    },
  });
});

function SpinnersCheckMark() {
  const classes = useStyles({});
  return (
    <div className={classes.spinnersCheckDiv}>
      <Checkmark
        className={classes.spinnersCheckSvg}
        width={undefined} // overrules the default 40 value
        height={undefined} // overrules the default 40 value
        viewBox="-6 -6 35 35"
      />
    </div>
  );
}

function SpinnersAlert() {
  const classes = useStyles({});
  return (
    <div className={classes.spinnersAlertDiv}>
      <Alert
        className={classes.spinnersAlertSvg}
        width={undefined} // overrules the default 40 value
        height={undefined} // overrules the default 40 value
        viewBox="-6.5 -5.5 37 37"
      />
    </div>
  );
}

export type SpinnersButtonIcon = 'spinner' | 'alert' | 'checkmark';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  onClick?: () => void;
  icon?: SpinnersButtonIcon;
  tooltipTitles?: IButtonTooltips;
}
export interface IButtonTooltips {
  spinner?: string;
  alert?: string;
  checkmark?: string;
}

function SpinnersButton(props: IProps) {
  const { onClick, className, icon = 'spinner', tooltipTitles } = props;
  const ttTitles: IButtonTooltips = {
    spinner: 'Tasks in progress',
    alert: 'Error in one or more tasks',
    checkmark: 'All tasks done',
    ...tooltipTitles,
  };
  const ttTitle = ttTitles[icon] as string;
  const classes = useStyles({});
  return (
    <div className={`${classes.button} ${className}`} onClick={onClick}>
      <div className={`${classes.innerDiv} ${className}`}>
        <MikeTooltip title={ttTitle !== undefined ? ttTitle : 'Tasks in progress'} arrow mikePosition={{ top: 17, left: -8 }}>
          <>
            {icon === 'checkmark' && <SpinnersCheckMark />}
            {icon === 'alert' && <SpinnersAlert />}
            {icon === 'spinner' && <MikeSpinner size="xs" />}
          </>
        </MikeTooltip>
      </div>
    </div>
  );
}

export default SpinnersButton;
